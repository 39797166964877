import React, { useState } from "react";
import classes from "./ImageSlider.module.css";
import ImageMagnifier from "../../Pages/ProductDetail/ImageMagnifier";

import { useDispatch, useSelector } from "react-redux";
import {
  PostWishlistHandler,
  RemoveWishlistHandler,
  getWishlistHandler,
} from "../../Store/Product/ProductActions";
import { useNavigate } from "react-router-dom";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
// import noImg from "../../Assets/Images/noImage.png";
import noImg from "../../Assets/Images/noImg.png"

//svg path
import { ReactComponent as NotFavouriteSvg } from "../../Assets/SvgComponents/NotFavouriteSvg.svg";
import { ReactComponent as FavouriteSvg } from "../../Assets/SvgComponents/FavouriteSvg.svg";
import { authActions } from "../../Store/Auth/AuthSlice";

const ImageSlider = ({ product }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const wishlist = useSelector((state) => state.product?.wishlist);
  const authToken = localStorage.getItem("authToken");
  const Profile = useSelector((state) => state.auth.Profile);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const findFav = wishlist?.WishlistProductId?.find(
    (wish) => wish === product?.PKID
  );

  const addToWishlist = (WishlistProductId) => {
    const data = {
      WishlistProductId: WishlistProductId,
      User_Number: Profile?.MobileNumber,
      appKeyToken: verfiyToken,
    };
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(PostWishlistHandler(authToken, data)).then((result) => {
            if (result.status === "success") {
              dispatch(getWishlistHandler(authToken,{appKeyToken:verfiyToken}, Profile?.MobileNumber));
            } else {
              navigate("/verification");
              dispatch(authActions.setFromPage("cart"));
            }
          });
        }
      });
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("cart"));
    }
  };

  const removeWishlist = (id) => {
    dispatch(
      RemoveWishlistHandler(authToken, Profile?.MobileNumber, id, {
        appKeyToken: verfiyToken,
      })
    ).then((result) => {
      if (result.status === "success") {
        dispatch(getWishlistHandler(authToken,{appKeyToken:verfiyToken}, Profile?.MobileNumber));
      } else {
        navigate("/verification");
        dispatch(authActions.setFromPage("cart"));
      }
    });
  };

  return (
    <>
      <div
        className={` ${classes.ImageHolder} d-flex position-relative `}
      >
        {/* <div className={`${classes.BestSeller}`}>BESTSELLER</div> */}

        <div className={`${classes.categoryImage}`}>
          {product?.Images?.length > 0 && product?.Images ? (
            <ImageMagnifier
            // <img
              variant="top"
              src={product?.Images[selectedImageIndex]}
              alt="imageNotAvailable"
              className="img-fluid"
               magnifierHeight={300}
               magnifieWidth={300}
               zoomLevel={2}
          
            />
          ) : (
            
            <img
              variant="top"
              src={noImg}
              alt="imageNotAvailable"
              className="img-fluid"
            />
          )}
        </div>

        <div className={`${classes.ProductOption} d-flex flex-column gap-2`}>
          {findFav ? (
            <div
              onClick={() => removeWishlist(product?.PKID)}
              className={`${classes.IconCircle}`}
            >
              <FavouriteSvg />
            </div>
          ) : (
            <div
              onClick={() => addToWishlist(product?.PKID)}
              className={`${classes.IconCircle}`}
            >
              <NotFavouriteSvg />
            </div>
          )}
        </div>
      </div>

      <div className={`${classes.multipleImages} row`}>
        <div className={`${classes.productThumbnails}`}>
          {product?.Images?.map((image, index) => (
            <div
              key={index}
              className={`${classes.productThumbnail} ${
                selectedImageIndex === index ? classes.activeThumbnail : ""
              }`}
              onClick={() => handleThumbnailClick(index)}
            >
              <img src={image} alt={`Product thumbnail ${index}`} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
