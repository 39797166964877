import { CustomerInstance } from "../../Router/AxiosInstance";
import { cartActions } from "./CartSlice";



export const AddCartHandler = (verifyToken,authToken, productDetail, num) => {
  return async (dispatch) => {
    // console.log("----Add---asli wala CartHandler-----");
    let result = {
      status: "",
      msg: "",
    };
    const requestData = {
      cartData: [productDetail],

        appKeyToken:verifyToken
   
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/createCartList/${num}`,
      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: requestData,  // Include the payload here
    };

    try {
      const response = await CustomerInstance(config);
      if (response.data.status === "success") {
        result.status = "success";
        // console.log(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }

    // The following part seems redundant, as you've already made the request above
    // If you still need it, you can modify accordingly
    // const { data } = await CustomerInstance.post(
    //   `/customer/createCartList/${num}`,
    //   requestData,
    //   {
    //     headers: {
    //       Authorization: "Bearer " + verifyToken,
    //     },
    //   }
    // );

    // console.log("--data---", data);

    return result;
  };
};

export const AddLocalCartHandler = (verifyToken, authToken,productDetail, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };
    
    // Assuming you want to include productDetail in the request payload
    const requestData = {
      cartData: productDetail,
      appKeyToken:verifyToken
    };

    const { data } = await CustomerInstance.post(
      `/customer/createCartList/${id}`,
      requestData,
      {
        headers: {
          Authorization: "Bearer " + authToken,
        },
      }
    );

    if (data.status === "success") {
      dispatch(cartActions.setLocalCartList([]));

    }

    // The following part seems redundant, as you've already made the request above
    // If you still need it, you can modify accordingly
    // let config = {
    //   method: "post",
    //   maxBodyLength: Infinity,
    //   url: `http://localhost:1234/customer/createCartList/${id}`,
    //   headers: {
    //     Authorization: "Bearer " + verifyToken,
    //   },
    // };

    // await CustomerInstance(config, requestData)
    //   .then(function (response) {
    //     if (response.data.status === "success") {
    //       result.status = "success";
    //       localStorage.removeItem("cartData");
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // return result;

    // Simplified version without using the redundant config part
    // This assumes you only need to make one POST request
    result.status = data.status;
    return result;
  };
};


export const RemoveCartHandler = (verfiyToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/SubtractFromCart`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getCartHandler = (verfiyToken,authToken, user) => {
  const data = {
    "userId": user,
    "appKeyToken": verfiyToken
  }
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/getCartList/store`,

      headers: {
        Authorization: "Bearer " + authToken,
      },
      data:data
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success" && response.data.data) {
          result.status = "success";
          // console.log(JSON.stringify(response.data.data));
          dispatch(cartActions.setCartList(response.data.data.items));
          dispatch(
            cartActions.calculateTotalPriceHandler(response.data.data.bill)
          );
        } else{
          dispatch(cartActions.setCartList([]));
          dispatch(
            cartActions.calculateTotalPriceHandler("")
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const deleteCartHandler = (verfiyToken, user, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/customer/remove/${user}/${id}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const emptyCartHandler = (verfiyToken, user) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/customer/remove/${user}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};
