import { addressActions } from "./AddressSlice";
import { CustomerInstance } from "../../Router/AxiosInstance";

export const AddressHandler = (verfiyToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/addDeliveryAddress`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const DeleteAddressHandler = (verfiyToken, num) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/customer/DeleteAddress/${num}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const GetAddressHandler = (verfiyToken, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/getDeliveryAddress/${id}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
          dispatch(addressActions.setAddressHandler(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const GetAddressByIdHandler = (verfiyToken, num) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/deliveryAddress/${num}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
          dispatch(addressActions.setAddressEditHandler(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const UpdateAddressByIdHandler = (verfiyToken, num, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `/customer/updatedeliveryAddress/${num}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
          dispatch(addressActions.setAddressEditHandler(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const UpdateSelectedAddressHandler = (verfiyToken,id,customerId) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `/customer/updateDefault/${customerId}/${id}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },

    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
       
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};
