import React, { useEffect } from "react";
import classes from "./Profile.module.css";
import Header from "../../UI/Header/Header";
import { useNavigate,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LogOutHandle,
  LoggedInHandler,
  getProfileHandle,
} from "../../Store/Auth/AuthActions";
import MainHeader from "../Home/MainHeader";
import user from "../../Assets/Images/user.png";
import MainFooter from "../../UI/Footer/MainFooter";
import toast from "react-hot-toast";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const Profile = () => {
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const Profile = useSelector((state) => state.auth.Profile);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const authToken = localStorage.getItem("authToken");

  const LogOut = () => {
    dispatch(LogOutHandle(authToken)).then((result) => {
      if (result.status === "success") {
        navigate("/");
        toast("Logged Out Successfully", {
          style: {
            border: "1px solid #006d5b",
            padding: "8px 14px",
            background: "#006d5b",
            color: "#fff",
          }
        })
      }
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getProfileHandle(verfiyToken, data.User_Numberid));
        }
      });
    }
    else{
      navigate("/verification");

    }
  }, []);

  // console.log(Profile);

  return (
    <>
      <div className={`container-fluid ${classes.profilePage}`}>
        <div className={`${classes.NavigationHeader}`}>
          <MainHeader />
        </div>

        <div className={`${classes.ProfileSection}  container-fluid `}>
          <Header onClick={() => navigate(`/${id}/home`)} heading={"My Account"} />

          <div className={`${classes.ProfileWrapper}   `}>
            <div className={`${classes.ProfileNotifySet}   `}>
              <div className={`${classes.ProfileInfo} row`}>
                <div
                  className={`${classes.ProfileImage} col-3  position-relative pt-1`}
                >
                  <img src={user} alt="category" className="img-fluid" />
                </div>
                <div className={`${classes.ProfileContent} col-9`}>
                  <div className="mb-1">
                    <i className="bi bi-person-circle"></i>
                    <span className="ms-2">{Profile?.Username}</span>
                  </div>
                  <div className="mb-1">
                    <i className="bi bi-telephone"></i>
                    <span className="ms-2">+91{Profile?.MobileNumber}</span>
                  </div>
                  <div>
                    <i className="bi bi-envelope"></i>
                    {/* <GeominiLocSvg /> */}
                    <span className="ms-2">{Profile?.Email}</span>
                  </div>
                </div>
                <div
                  className={`${classes.profileEditSvgSet} `}
                  onClick={() => navigate(`/${id}/editprofile`)}
                >
                  <i className="bi bi-pencil"></i>
                  {/* <EditSvg />{" "} */}
                </div>
              </div>
            </div>

            <div className={`${classes.profileList}`}>
              <div className="row mt-3 d-flex gap-2">
                <div
                  className={`${classes.menu}   `}
                  onClick={() => navigate(`/${id}/myorder`)}
                >
                  <div className="d-flex gap-4">
                    <div className={`${classes.forwardIconSet} `}>
                      <i className="bi bi-archive"></i>
                      {/* <BagSvg /> */}
                    </div>
                    <div className="d-flex flex-column">
                      <div className={`${classes.menuContent}`}>
                        View orders
                      </div>
                      <div className={`${classes.menubottomContent}`}>
                        View your complete order list
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <i className={` bi bi-chevron-right`}></i>
                  </div>
                </div>
                {/* <div className={`${classes.menu}   `}>
                  <div className="d-flex gap-4">
                    <div className={`${classes.forwardIconSet} `}>
                      <i className="bi bi-clock-history"></i>
                    
                    </div>
                    <div className="d-flex flex-column">
                      <div className={`${classes.menuContent}`}>
                        My Transactions
                      </div>
                      <div className={`${classes.menubottomContent}`}>
                        See your all transactions
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <i className={` bi bi-chevron-right`}></i>
                  </div>
                </div> */}
                <div className={`${classes.menu}   `}
                 onClick={() => navigate(`/${id}/ProductDemo`)}
                 >
                  <div className="d-flex gap-4">
                    <div className={`${classes.forwardIconSet} `}>
                      <i className="bi bi-percent"></i>
                      {/* <OfferSvg /> */}
                    </div>
                    <div className="d-flex flex-column">
                      <div className={`${classes.menuContent}`}>
                        View Offers
                      </div>
                      <div className={`${classes.menubottomContent}`}>
                        View all discount offers
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <i className={` bi bi-chevron-right`}></i>
                  </div>
                </div>
                {/* <div className={`${classes.menu}   `}>
                  <div className="d-flex gap-4">
                    <div className={`${classes.forwardIconSet} `}>
                      <i className="bi bi-wallet"></i>
                     
                    </div>
                    <div className="d-flex flex-column">
                      <div className={`${classes.menuContent}`}>Wallet</div>
                      <div className={`${classes.menubottomContent}`}>
                        View your wallet
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <i className={` bi bi-chevron-right`}></i>
                  </div>
                </div> */}
                <div
                  className={`${classes.menu}   `}
                  onClick={() => navigate(`/${id}/ManageAddress`)}
                >
                  <div className="d-flex gap-4">
                    <div className={`${classes.forwardIconSet} `}>
                      <i className="bi bi-truck"></i>
                      {/* <TruckSvg /> */}
                    </div>
                    <div className="d-flex flex-column">
                      <div className={`${classes.menuContent}`}>
                        Manage Addresses
                      </div>
                      <div className={`${classes.menubottomContent}`}>
                        View and Manage your all addresses
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <i className={` bi bi-chevron-right`}></i>
                  </div>
                </div>
              </div>
            </div>

            <button className={`${classes.submitBtn} mt-4`} onClick={LogOut}>
              Logout{" "}
            </button>
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default Profile;
