import { useNavigate } from "react-router-dom";
import classes from "./Notification.module.css";
import React from "react";
import Header from "../../UI/Header/Header";

//img svg path
import Product1 from "../../Assets/Images/Product1.png";
import Watch from "../../Assets/Images/Watch.png";
import {ReactComponent as BagSvg} from "../../Assets/SvgComponents/BagSvg.svg";
import {ReactComponent as NotifyCircleSvg} from "../../Assets/SvgComponents/NotifyCircleSvg.svg";
import {ReactComponent as OfferSvg} from "../../Assets/SvgComponents/OfferSvg.svg";


const Notification = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <Header
        heading={"Notification"}
        onClick={() => navigate("/home")}
        jsxheading={<h6 className="mt-1">Clear All</h6>}
      />

      <div className="row mt-5 mb-2">
        <div className="col-12 col-sm-12 col-xs-12 col-lg-6 col-md-12">
          <div className="d-block text-center  ">
            <div className={`${classes.notificationList} `}>
              <div className="d-flex gap-2">
                <div>
                  <OfferSvg />
                </div>
                <div className={` ${classes.content} mx-2`}>
                  <h6 className={`${classes.headline}`}>
                    Beautiful Bellies from $5
                  </h6>
                  <p className={`${classes.notifycontent}`}>
                    Catwalk, Carltron London & more popular brands on deals.
                    Grab now!
                  </p>
                  <div>
                    <span className={`${classes.notifytime}`}>
                      12 march 2023
                    </span>{" "}
                    <NotifyCircleSvg />
                    <span className={`${classes.notifytime}`}>
                      {" "}
                      12:19 am
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className={`${classes.NotifyImage}  position-relative pt-1`}>
                <img
                  src={Product1}
                  alt="product"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-xs-12 col-lg-6 col-md-12">
          <div className="d-block text-center  ">
            <div className={`${classes.notificationList} `}>
              <div className="d-flex gap-2">
                <div>
                  <BagSvg />
                </div>
                <div className={` ${classes.content} mx-2`}>
                  <h6 className={`${classes.headline}`}>Order Delivered !</h6>
                  <p className={`${classes.notifycontent}`}>
                    Your neomart order containing noise smart watch... has been
                    delivered
                  </p>
                  <div>
                    <span className={`${classes.notifytime}`}>
                      12 march 2023
                    </span>{" "}
                    <NotifyCircleSvg />
                    <span className={`${classes.notifytime}`}>
                      {" "}
                      12:19 am
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className={`${classes.NotifyImage}  position-relative pt-1`}>
                <img
                  src={Watch}
                  alt="product"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-xs-12 col-lg-6 col-md-12">
          <div className="d-block text-center  ">
            <div className={`${classes.notificationList} `}>
              <div className="d-flex gap-2">
                <div>
                  <OfferSvg />
                </div>
                <div className={` ${classes.content} mx-2`}>
                  <h6 className={`${classes.headline}`}>
                    Beautiful Bellies from $5
                  </h6>
                  <p className={`${classes.notifycontent}`}>
                    Catwalk, Carltron London & more popular brands on deals.
                    Grab now!
                  </p>
                  <div>
                    <span className={`${classes.notifytime}`}>
                      12 march 2023
                    </span>{" "}
                    <NotifyCircleSvg />
                    <span className={`${classes.notifytime}`}>
                      {" "}
                      12:19 am
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className={`${classes.NotifyImage}  position-relative pt-1`}>
                <img
                  src={Product1}
                  alt="product"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-xs-12 col-lg-6 col-md-12">
          <div className="d-block text-center  ">
            <div className={`${classes.notificationList} `}>
              <div className="d-flex gap-2">
                <div>
                  <BagSvg />
                </div>
                <div className={` ${classes.content} mx-2`}>
                  <h6 className={`${classes.headline}`}>Order Delivered !</h6>
                  <p className={`${classes.notifycontent}`}>
                    Your neomart order containing noise smart watch... has been
                    delivered
                  </p>
                  <div>
                    <span className={`${classes.notifytime}`}>
                      12 march 2023
                    </span>{" "}
                    <NotifyCircleSvg />
                    <span className={`${classes.notifytime}`}>
                      {" "}
                      12:19 am
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className={`${classes.NotifyImage}  position-relative pt-1`}>
                <img
                  src={Watch}
                  alt="product"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-xs-12 col-lg-6 col-md-12">
          <div className="d-block text-center  ">
            <div className={`${classes.notificationList} `}>
              <div className="d-flex gap-2">
                <div>
                  <BagSvg />
                </div>
                <div className={` ${classes.content} mx-2`}>
                  <h6 className={`${classes.headline}`}>Order Delivered !</h6>
                  <p className={`${classes.notifycontent}`}>
                    Your neomart order containing noise smart watch... has been
                    delivered
                  </p>
                  <div>
                    <span className={`${classes.notifytime}`}>
                      12 march 2023
                    </span>{" "}
                    <NotifyCircleSvg />
                    <span className={`${classes.notifytime}`}>
                      {" "}
                      12:19 am
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className={`${classes.NotifyImage}  position-relative pt-1`}>
                <img
                  src={Watch}
                  alt="product"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-xs-12 col-lg-6 col-md-12">
          <div className="d-block text-center  ">
            <div className={`${classes.notificationList} `}>
              <div className="d-flex gap-2">
                <div>
                  <BagSvg />
                </div>
                <div className={` ${classes.content} mx-2`}>
                  <h6 className={`${classes.headline}`}>Order Delivered !</h6>
                  <p className={`${classes.notifycontent}`}>
                    Your neomart order containing noise smart watch... has been
                    delivered
                  </p>
                  <div>
                    <span className={`${classes.notifytime}`}>
                      12 march 2023
                    </span>{" "}
                    <NotifyCircleSvg />
                    <span className={`${classes.notifytime}`}>
                      {" "}
                      12:19 am
                    </span>{" "}
                  </div>
                </div>
              </div>

              <div className={`${classes.NotifyImage}  position-relative pt-1`}>
                <img
                  src={Watch}
                  alt="product"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
