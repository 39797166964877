import React from "react";
import "./PageLoader.css";

function PageLoader() {
  return(
  <>
<div class="page-loader">Loading...</div>
</>)
}

export default PageLoader;
