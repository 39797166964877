import React, { useEffect, useState } from "react";
import classes from "./Footer.module.css";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../Store/Cart/CartSlice";
import { ReactComponent as CircleSvg } from "../../Assets/SvgComponents/CircleSvg.svg";

const Footer = (props) => {
  const dispatch = useDispatch();

  const authToken = localStorage.getItem("authToken");

  const cartData = useSelector((state) => state.cart.cartData);
  const cartList = useSelector((state) => state.cart.cartList);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const cartPrice = useSelector((state) => state.cart.cartPrice);

  const calculateTotalPrice = (cartData) => {
    const total = cartData.reduce((acc, item) => {
      return acc + item.Product_Price * item.Product_Quantity;
    }, 0) || 0;
    return parseFloat(total.toFixed(2));
  };

  useEffect(() => {
    if (authToken) {
      const newTotalPrice = calculateTotalPrice(cartList);
      dispatch(cartActions.calculateTotalPriceHandler(newTotalPrice));
    }
  }, [cartList, authToken, dispatch]);

  useEffect(() => {
    dispatch(cartActions.calculateTotalLocalPriceHandler(cartData));
  }, []);

  return (
    <div className={`${classes.footer} fixed-bottom`}>
      <div className="d-flex gap-2">
        <p>{authToken ? cartList?.length : cartData?.length} Item</p>{" "}
        <span>
          <CircleSvg />
        </span>
        <p>{authToken ? totalPrice : cartPrice}</p>
      </div>
      <div onClick={props.onClick}>
        <p>
          {/* Proceed{" "} */}
          Next
          <span>
            <i className={`bi bi-chevron-right`}></i>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
