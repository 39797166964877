import React, { useState } from "react";
import Header from "../../UI/Header/Header";
import { useNavigate,useLocation,useParams } from "react-router-dom";
import classes from "./Order.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getOrderByIdHandler,
  getOrderListHandler,
  getStoreApprovalHandler,
} from "../../Store/Order/OrderActions";
import {
  LoggedInHandler,
  getCustomerByMobile,
} from "../../Store/Auth/AuthActions";
import MainFooter from "../../UI/Footer/MainFooter";
import MainHeader from "../Home/MainHeader";

import { ReactComponent as SearchSvg } from "../../Assets/SvgComponents/SearchSvg.svg";
import { ReactComponent as CheckSvg } from "../../Assets/SvgComponents/CheckSvg.svg";
import { ReactComponent as WrongSvg } from "../../Assets/SvgComponents/Cross.svg";
import emptyOrder from "../../Assets/Images/emptyOrder.gif";
import { orderActions } from "../../Store/Order/OrderSlice";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";


const MyOrder = () => {
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const orderList = useSelector((state) => state.order.orderList);
  // const orderList = [];

  const authToken = localStorage.getItem("authToken");

  // console.log("orderList-----------",orderList)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {id} = useParams();

  const [orderstatus, setOrderStatus] = useState([]);

  const viewHandler = (order) => {
    // console.log(order)
    if (order?.OrderFulfillment?.CurrentProcessingStatus == "Cancelled") {
      return;
    }
    const id = order?._id;
    dispatch(getOrderByIdHandler(verfiyToken, id)).then((result) => {
      if (result.status === "success") {
        dispatch(getStoreApprovalHandler(verfiyToken)).then((result) => {
          if (result.data.CustomerApproval === true) {
            const lastStatus =
              result.data.OrderSt[result.data.OrderSt?.length - 1]
                .OrderStatusname;

            const checkStatus =
              order?.CustomerDetail?.OrderFulfillment
                ?.CurrentProcessingStatus;
            if (checkStatus == lastStatus) {
              navigate(`/OrderSummary/${id}`);
             
            } else {
              navigate(`/${id}/Order`);
            }  
          }
        });
      }
    });
  };

  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };
  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(orderActions.setOrderListHandler([]));
          dispatch(getCustomerByMobile(verfiyToken, result.data.mobile)).then(
            (result) => {
              console.log(result.data.data);
              if (result.status === "success") {
                dispatch(
                  getOrderListHandler(verfiyToken, authToken, result.data)
                );
              }
            }
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);
  return (
    <>
      <div className={`container-fluid ${classes.MyOrderPage}`}>
        <div className={`${classes.OrderNavigation}`}>
          <MainHeader />
        </div>

        <div className={`${classes.MyOrderWrapper}`}>
          <Header heading={"My Orders"} onClick= {handleHeaderClick} />
          <div className="row">
            {/* <div className={`${classes.background} col-12`}> */}
            <div
              className={`col-12 ${
                orderList.length === 0 ? "" : classes.background
              }`}
            >
              {/* <div className={`${classes.searchContainer}  mt-4`}>
                <span className={`${classes.searchSet}`}>
                  <SearchSvg />
                </span>
                <input
                  type="search"
                  className={`${classes.inputSearch} form-control `}
                  id="exampleInputEmail1"
                  aria-label="Search"
                  placeholder="Search your orders"
                />
              </div> */}
              <div className={`${classes.orderContainer} mt-3`}>
                {orderList?.length === 0 ? (
                  <div className={`${classes.noOrders}`}>
                    <img src={emptyOrder} alt="No orders" loop autoplay />?{" "}
                    <p>No orders found.</p>
                  </div>
                ) : (
                  orderList?.map((order, index) => {
                    return (
                      <div
                        className={`${classes.orderBox}`}
                        key={index}
                        onClick={() => viewHandler(order)}
                      >
                        <span className={`${classes.orderBox1}`}>
                          {order?.OrderFulfillment?.CurrentProcessingStatus ==
                          "Cancelled" ? (
                            <WrongSvg />
                          ) : (
                            <CheckSvg />
                          )}
                        </span>
                        <div className={`${classes.orderBox2}`}>
                          {order?.OrderFulfillment?.CurrentProcessingStatus ==
                            "Cancelled" ||
                          order?.OrderFulfillment?.CurrentProcessingStatus ==
                            "Complete" ? (
                            ""
                          ) : (
                            <h6 className={`${classes.pricing}`}>
                              Delivering in 7-8 days
                            </h6>
                          )}
                          <h6>Rs {order?.CustomerDetail?.total_amount}</h6>
                          <p>Order Id : {order?.CustomerDetail?._id} </p>
                          <p>
                            {order?.CustomerDetail?.BillingAddress?.Street1},{" "}
                            {order?.CustomerDetail?.BillingAddress?.City},{" "}
                            {order?.CustomerDetail?.BillingAddress?.State}
                          </p>
                          <p>
                            Order Status :{" "}
                            {
                              order?.CustomerDetail?.OrderFulfillment
                                ?.CurrentProcessingStatus
                            }{" "}
                          </p>

                          <p>
                            Order Date :
                            {new Date(
                              order?.CustomerDetail?.OrderDate
                            ).toLocaleDateString("en-GB")}{" "}
                          </p>

                          <button
                            className={
                              order?.CustomerDetail?.OrderFulfillment
                                ?.CurrentProcessingStatus == "Cancelled"
                                ? `${classes.repeatBtn}  btn btn-danger`
                                : `${classes.repeatBtn} `
                            }
                          >
                            {
                              order?.CustomerDetail?.OrderFulfillment
                                ?.CurrentProcessingStatus
                            }
                          </button>
                        </div>
                        {order?.CustomerDetail?.OrderFulfillment
                          ?.CurrentProcessingStatus != "Cancelled" && (
                          <span className={`${classes.orderBox3}`}>
                            <i className="bi bi-chevron-right"></i>
                          </span>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default MyOrder;
