import { createSlice } from "@reduxjs/toolkit";

const storeSlice = createSlice({
  name: "store",
  initialState: {
    stores: [],
    storeById:{},
    adminStore:{},
    storeId: null,
  },
  reducers: {
    setStoreDetailHandler(state, action) {
      state.stores = action.payload;
    },
    setStoreHandler(state, action) {
      state.storeById = action.payload;
    },
    setAdminStoreHandler(state, action) {
      state.adminStore = action.payload;
    },
    setStoreId(state, action) {
      state.storeId = action.payload;
    },
  },
});

export const storeActions = storeSlice.actions;

export default storeSlice;
