import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderApproval: {},
    orderList: [],
    orderById: {},
    currentStep: 2,
    orderTrackStatus: {},
    orderTrackingList: [],
    PromoDetail: {},
    returnedProducts: [],
  },
  reducers: {
    setCustomerOrderApproval(state, action) {
      state.orderApproval = action.payload;
    },
    setOrderListHandler(state, action) {
      state.orderList = action.payload;
    },
    setOrderByIdHandler(state, action) {
      state.orderById = action.payload;
    },
    setCurrentStepHandler(state, action) {
      state.currentStep = action.payload;
    },
    setStatusHandler(state, action) {
      state.orderTrackStatus = action.payload;
    },
    setOrderStatusListHandler(state, action) {
      state.orderTrackingList = action.payload;
    },
    setPromoDetail(state, action) {
      state.PromoDetail = action.payload;
    },
    setReturnedProducts: (state, action) => {
      state.returnedProducts = action.payload;
    },
  },
});

export const orderActions = orderSlice.actions;

export default orderSlice;
