import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./AddToCart.module.css";
import { cartActions } from "../../Store/Cart/CartSlice";
import {
  AddCartHandler,
  RemoveCartHandler,
  getCartHandler,
} from "../../Store/Cart/CartActions";

const AddToCart = (props) => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.cartData);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const Profile = useSelector((state) => state.auth.Profile);

  const product = props.product;

  const authToken = localStorage.getItem("authToken");

  const productIncrement = (product) => {
    const data = {
      Product_Id: product.Product_Id,
      Product_name: product.Product_name,
      FKProdID: product.PKID,
      // FKLotID: 10000001,
      Product_Quantity: 1,
      Product_detail: product.Product_detail,
      Product_Price: product.Product_Price,
      // ProductStore_Id: product.ProductStore_Id,
      User_Numberid: Profile?.MobileNumber,
    };

    if (authToken) {
      dispatch(AddCartHandler(verfiyToken,authToken, data, data.User_Numberid)).then(
        (result) => {
          if (result.status === "success") {
            dispatch(getCartHandler(verfiyToken,authToken, data.User_Numberid));
          }
        }
      );
    } else {
      dispatch(cartActions.productIncrementHandler(product));
      dispatch(cartActions.calculateTotalLocalPriceHandler(product));
    }
  };

  const productDecrement = (product) => {
    const data = {
      Product_Id: product.Product_Id,
      Product_Quantity: 1,
      FKProdID: product.PKID,
      // ProductStore_Id: product.ProductStore_Id,
      User_Numberid: Profile?.MobileNumber,
      appKeyToken: verfiyToken
    };

    if (authToken) {
      dispatch(RemoveCartHandler(authToken, data)).then((result) => {
        if (result.status === "success") {
          dispatch(getCartHandler(verfiyToken,authToken, data.User_Numberid));
        }
      });
    } else {
      if (product.Product_Quantity < 2) {
        dispatch(cartActions.removeProductHandler(product));
      }
      if (product.Product_Quantity >= 1) {
        dispatch(cartActions.productDecrementHandler(product));
        dispatch(cartActions.calculateTotalLocalPriceHandler(product));
      }
      // if (cartData?.length < 2 ) {
      //   dispatch(cartActions.setLocalCartList([]));
      //   dispatch(cartActions.calculateTotalLocalPriceHandler(product));

      // }
    }
  };

  return (
    <>
      <div onClick={() => productDecrement(product)}>
        <span>
          <i className="bi bi-dash"></i>
        </span>
      </div>

      <span>{product.Product_Quantity}</span>

      <div onClick={() => productIncrement(product)}>
        <span>
          <i className="bi bi-plus"></i>
        </span>
      </div>
    </>
  );
};

export default AddToCart;
