const { createSlice } = require("@reduxjs/toolkit");

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    HomeMain: {
      setState:"search",
      brandName:"",
    }
    
  },
  reducers: {
    setHomeSearch(state,action){
      const name = action.payload.name
      const value = action.payload.value
      state.HomeMain[name] = value
    },   
  }
})

export const uiActions = uiSlice.actions;

export default uiSlice;

