import React from "react";
import classes from "./Popup.module.css";
import defaultImage from "../../Assets/Images/defaultImage.png"
import Cart from "../../Pages/Cart/Cart";

const PopUp = ({ title, content, onClose, order , specialClass,wishlist,deletebutton}) => {
  // console.log(order)
  return (
    <div className={ order ?`${classes.popup}  `:`${classes.popup}`}>
      <div className={ order ?`${classes.content} ${classes.Content} `:`${classes.content} ${classes.specialClass}`}>
        <div className={classes.popupHeading}>
          <h2>{title}</h2>
          <h5 className={`${classes.popupClose} m-0 pointer `} onClick={onClose}><i class="bi bi-x-lg"></i></h5>
        </div>
        
    
        {/* {order ? (
          content
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )} */}
        <div className={classes.popupContent}>
        {content ? (
          order ? (
            content
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          )
        ) : (
        <div className={classes.popupNoDataImg}><img src={defaultImage} alt="Default" /></div>  
        )}

        {
          deletebutton ? (
        
        <div className={classes.closeButtonBox}>
          <button onClick={onClose} className={classes.closeButton}>
            Delete
          </button>
          {
            
            <button onClick={wishlist} className={classes.closeButton}>
            Add to wishlist
          </button>
          }
        </div>
          ) : ""

       
        }

      
       
        </div>
        
        
        {/* <div className={classes.closeButtonBox}>
          <button onClick={onClose} className={classes.closeButton}>
            Close
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default PopUp;
