import React, { useEffect, useState } from "react";
import classes from "./Footer.module.css";
import swilmart from "../../Assets/Images/whiteSwilmart.png";
import truck from "../../Assets/Images/Truckkk.png";
import Return from "../../Assets/Images/Returnmnn.png";
import { useDispatch, useSelector } from "react-redux";
import { getAdminStoreHandle, getStoreByIdHandle } from "../../Store/StoreDetail/StoreActions";
import PopUp from "../PopUp/PopUp";
import { useNavigate } from "react-router-dom";

const MainFooter = () => {
  const navigate = useNavigate();
  const adminStore = useSelector((state) => state.store.adminStore);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsCondition, setShowTermsCondition] = useState(false);
  const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
  const storeId = useSelector((state) => state.store.storeId);
  const storeById = useSelector((state) => state.store.storeById);

  const authToken = localStorage.getItem("authToken");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminStoreHandle(storeById?.StoreId));
  }, [dispatch, storeById]);

  // const toggleDescription = () => {
  //   setShowFullDescription(!showFullDescription);
  // };
  // const togglePrivacyPolicy = () => {
  //   setShowPrivacyPolicy(!showPrivacyPolicy);
  // };

  const handleNavigate = () => {
    navigate(`/${storeId}/Details`, {
      state: {
        title: "Privacy Policy",
        content: "Your privacy is our priority...",
      },
    });
  };

  // const toggleTermsCondition = () => {
  //   setShowTermsCondition(!showTermsCondition);
  // };

  // const toggleDescriptionPopup = () => {
  //   setShowDescriptionPopup(!showDescriptionPopup);
  // };

  useEffect(() => {
    dispatch(getStoreByIdHandle(storeId))
  }, [storeId])

  return (
    <>
      {/* {showPrivacyPolicy && (
        <PopUp
          title="Privacy Policy"
          content={adminStore?.PrivacyPolicy}
          onClose={togglePrivacyPolicy}
        />
      )}

      {showTermsCondition && (
        <PopUp
          title="Terms and Conditions"
          content={adminStore?.TermsConditions}
          onClose={toggleTermsCondition}
        />
      )}

      {showDescriptionPopup && (
        <PopUp
          title="About Us"
          content={adminStore?.AboutUs}
          onClose={toggleDescriptionPopup}
        />
      )} */}

      <div className={`${classes.mainFooter} `}>
        <div className={`row mb-4`}>
          <div
            className={`${classes.mainFooterColumn} col-xl-3 col-lg-3 col-md-4 d-flex flex-column`}
          >
            <img src={swilmart} className={`${classes.logoImg}`} />
            <p className="mt-3">From click to doorstep, seamless.</p>
            <ul className={`${classes.LinkBox}`}>
              <li
                onClick={() =>
                  navigate(`/${storeId}/details`, {
                    state: {
                      title: "PRIVACY POLICY",
                      content: adminStore?.PrivacyPolicy,
                    },
                  })
                }
              //  onClick={togglePrivacyPolicy}
              >
                Privacy Policy
              </li>

              <li
                //  onClick={toggleTermsCondition}

                onClick={() =>
                  navigate(`/${storeId}/details`, {
                    state: {
                      title: "TERMS AND CONDITION",
                      content: adminStore?.TermsConditions,
                    },
                  })
                }
              >Terms and Condition</li>
            </ul>
          </div>
          <div
            className={`${classes.mainFooterColumn} col-xl-2 col-lg-3 col-md-4  d-flex flex-column`}
          >
            <h5 className=" mb-3">INFORMATION</h5>
            <ul className={`${classes.LinkBox} mt-4`}>
              <li>
                <a href="/">{adminStore?.address}</a>
              </li>
              <li
                onClick={() =>
                  navigate(`/${storeId}/details`, {
                    state: {
                      title: "ABOUT US",
                      content: adminStore?.AboutUs,
                    },
                  })
                }
              //  onClick={toggleDescriptionPopup}
              > About Us</li>
              <li>
                <a href="/">{adminStore?.Website}</a>
              </li>
            </ul>
          </div>
          <div
            className={`${classes.mainFooterColumn} col-xl-2 col-lg-3 col-md-4 d-flex flex-column `}
          >
            <h5 className=" mb-3">HELP</h5>
            <ul className={`${classes.LinkBox} mt-4`}>
              <li>
                <a href={authToken ? `/${storeId}/profile` : `/verification`}>
                  My Account{" "}
                </a>
              </li>
              <li>
                <a href={authToken ? `/${storeId}/manageAddress` : `/verification`}>
                  Easy Address Management
                </a>
              </li>
              <li>
                <a href={`/${storeId}/cart`}>Cart</a>
              </li>
              <li>
                <a href={authToken ? `/${storeId}/myOrder` : `/verification`}>
                  Order Status
                </a>
              </li>
            </ul>
          </div>
          <div
            className={`${classes.mainFooterColumn} col-xl-2 col-lg-3 col-md-4 d-flex flex-column`}
          >
            <h5 className=" mb-3">SOCIAL MEDIA</h5>

            <div className="d-flex gap-3 mt-3">
              <a target="blank" href={adminStore?.InstagramURL}>
                <div className={`${classes.smIcon}`}>
                  <i className="bi bi-instagram"></i>
                </div>
              </a>
              <a target="blank" href={adminStore?.TwitterURL}>
                <div className={`${classes.smIcon}`}>
                  <i className="bi bi-twitter"></i>
                </div>
              </a>
              <a target="blank" href="https://www.youtube.com/">
                <div className={`${classes.smIcon}`}>
                  <i className="bi bi-youtube"></i>
                </div>
              </a>
            </div>
            <ul className={`${classes.LinkBox} mt-3`}>
              <li>
                <a href="/">
                  <span>
                    <i className="bi bi-telephone-fill me-2"></i>
                  </span>
                  +91{" "}
                  {adminStore?.Phone
                    ? adminStore?.Phone
                    : adminStore?.SupportNumber}{" "}
                </a>
              </li>
              <li>
                <a href="/">
                  <span className="d-flex">
                    <i className="bi bi-envelope-fill me-2"></i>
                    <p>{adminStore?.Email}</p>
                  </span>
                </a>
              </li>
            </ul>
          </div>

          <div
            className={`${classes.sideRow} ${classes.mainFooterColumn} col-xl-3 col-lg-6 col-md-6 d-flex flex-column gap-4`}
          >
            <div className="d-flex gap-2 align-items-center ">
              <img src={truck} />
              <div className="d-flex flex-column">
                <h5 className=" m-0">FREE SHIPPING</h5>
                <p className="m-0">All over India</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              <img src={Return} />
              <div className="d-flex flex-column">
                <h5 className=" m-0">EASY RETURNS</h5>
                <p className="m-0">Easy Returns within 14 days of purchase</p>
              </div>
            </div>
          </div>
        </div>

        <div className={`${classes.copyText}`}>
          © Swilmart 2024 | All Rights Reserved
        </div>
      </div>
    </>
  );
};

export default MainFooter;
