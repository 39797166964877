import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Header.module.css";
import { ReactComponent as BackArrowSvg } from "../../Assets/SvgComponents/BackArrowSvg.svg";
import swilLogo from "../../Assets/Images/swil-logo.png"

const Header = (props) => {
  const navigate = useNavigate();
  // const jsxheading = '<h6 className="mt-1" >{props.rightText}</h6>'
  return (
    <nav className={` navbar navbar-light  ${classes.headerFix}    `}>
      <div
        className={
          props.classInfo
            ? `${classes.noborder} ${classes.header}   d-flex justify-content-between container-fluid`
            : `${classes.header}   d-flex justify-content-between container-fluid`
        }
      >
        <div
          className={`${classes.headerName}   d-flex align-items-center `}
          onClick={props.onClick}
        >
          {/* <BackArrowSvg /> */}
        <span className={`${classes.headerBackIcon}`}>  <i class="bi bi-arrow-left-short"></i></span>
          <img className={` ${classes.headerLogo}`} alt='logo' src={swilLogo}/>
          <h6 className="mt-1">{props.heading}</h6>
        </div>
        {props.jsxheading}
      </div>
      {props.headerAdditional}
    </nav>
  );
};

export default Header;
