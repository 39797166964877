import React, { useEffect, useState } from "react";
import classes from "./Address.module.css";
import Header from "../../UI/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { addressActions } from "../../Store/Address/AddressSlice";
import {
  DeleteAddressHandler,
  GetAddressByIdHandler,
  GetAddressHandler,
  UpdateSelectedAddressHandler,
} from "../../Store/Address/AddressActions";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
import { ReactComponent as EditSvg } from "../../Assets/SvgComponents/EditSvg.svg";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import noAddressImage from "../../Assets/Images/noAddressImage.gif";

//img path
import { ReactComponent as HomeSvg } from "../../Assets/SvgComponents/HomeSvg.svg";
import { ReactComponent as WorkSvg } from "../../Assets/SvgComponents/WorkSvg.svg";
import toast from "react-hot-toast";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const ManageAddress = ({ order }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [delAddress, setDelAddress] = useState("");

    const authToken = localStorage.getItem("authToken");

  const AddressKeeper = useSelector((state) => state.address.AddressKeeper);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const AddNewAddress = () => {
    dispatch(addressActions.setAddressFormatter());
    navigate(`/${id}/address`);
  };

  const editAddress = (address) => {
    dispatch(LoggedInHandler(authToken)).then((result) => {
      if (result.status === "success") {
        dispatch(GetAddressByIdHandler(authToken, address._id));
        navigate(`/${id}/address`);

      }
    });
  };

    const deleteAddress = (address) => {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const customerId = result.data.id;
          dispatch(DeleteAddressHandler(authToken, address._id)).then(
            (result) => {
              if (result.status === "success") {
                dispatch(GetAddressHandler(authToken, customerId));
                toast("Address Deleted", {
                  style: {
                    border: "1px solid #006d5b",
                    padding: "8px 14px",
                    background: "#006d5b",
                    color: "#fff",
                  }
                })
              }
            }
          );
        }
      });
    };

  const selectAddress = (address) => {
    dispatch(LoggedInHandler(authToken)).then((result) => {
      if (result.status === "success") {
        const customerId = result.data.id;
        dispatch(
          UpdateSelectedAddressHandler(authToken, address._id, customerId)
        ).then((result) => {
          if (result.status === "success") {
            dispatch(GetAddressHandler(authToken, customerId));
          }
        });
      }
    });
  };

  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(GetAddressHandler(authToken, result.data.id));
        }
      })
    } else {
      navigate("/verification");

    }
  }, [dispatch, authToken]);

    return (
      <>
        <div
          className={
            order ? `container-fluid ` : `container-fluid ${classes.addressPage}`
          }
        >
          {order ? (
            ""
          ) : (
            <div className={`${classes.NavigationHeader}`}>
              <MainHeader />
            </div>
          )}

        <div
          className={order ? "container-fluid p-0" : "container-fluid mt-5 p-0"}
        >
          <div className={`${classes.addressWrapper}`}>
            {order ? (
              ""
            ) : (
              <Header
                heading={"Manage Address"}
                onClick={handleHeaderClick}
                jsxheading={
                  <button
                    className={`${classes.addBtn}`}
                    onClick={AddNewAddress}
                  >
                    + Add New
                  </button>
                }
              />
            )}
            <div className={order ? "d-flex flex-column" : "row gap-3 px-3"}>
              {AddressKeeper.length === 0 ? (
                <div className={classes.noAddressImageContainer}>
                  <div className={classes.noAddressImageMessage}>
                    <img
                      src={noAddressImage}
                      alt="No Address"
                      className={classes.noAddressImage}
                    />
                    <p>No Address Added</p>
                  </div>
                </div>
              ) : (
                AddressKeeper?.map((address, index) => {
                  return (
                    <div
                      className={
                        address.default === true
                          ? order
                            ? ` ${classes.addressBox} ${classes.widthSet}  ${classes.highlightedAddress} W-100`
                            : ` ${classes.addressBox}   ${classes.highlightedAddress} `
                          : order
                            ? ` W-100 ${classes.addressBox} ${classes.widthSet}`
                            : ` ${classes.addressBox} `
                      }
                      key={index}
                      onClick={() => selectAddress(address)}
                    >
                      <div className={`${classes.defaultIcon}`}>
                        <i className="bi bi-check2-circle"></i>
                      </div>
                      <div className="d-block  ">
                        <div className={`${classes.addressDetails}`}>
                          <p className={`${classes.name}`}>{address.name}</p>
                          <p className={`${classes.addressContent}`}>
                            {address.addressLine1}
                          </p>
                          <p className={`${classes.addressContent}`}>
                            {address.addressLine2}
                          </p>
                          <p className={`${classes.addressContent}`}>
                            {address.city} - {address.pincode}
                          </p>
                          <p className={`${classes.addressContent}`}>
                            {address.state}
                          </p>
                          <p className={`${classes.addressContent}`}>
                            Mobile : +91{address.mobileNumber}
                          </p>
                          <div className={`${classes.addressType} d-flex `}>
                            <div className={`${classes.addressContentType}`}>
                              {address.type === "Home" ? (
                                <HomeSvg />
                              ) : (
                                <WorkSvg />
                              )}{" "}
                              <span className="ms-2">
                                {address.type !== "Home" &&
                                  address.type !== "Work"
                                  ? "Others"
                                  : address.type}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${classes.addressChange} d-flex`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            className={`${classes.addressEditIcon}`}
                            onClick={() => editAddress(address)}
                          >
                            <i class="bi bi-pencil-square"></i>{" "}
                            <p className={`${classes.addressEdit}`}>EDIT</p>
                          </div>
                          <div
                            className={`${classes.addressDeleteIcon}`}
                            // onClick={() => deleteAddress(address)}
                            data-bs-toggle="modal"
                            onClick={() => setDelAddress(address)}
                            data-bs-target="#exampleModal"
                          >
                            <i className="bi bi-trash3"></i>{" "}
                            <p className={`${classes.addressEdit}`}>DELETE</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className={`${classes.addressConfirmPopup} modal-dialog modal-dialog-centered`}

        >
          <div className="modal-content" style={{ borderRadius: "0px" }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Confirmation for Address Removal
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className={`${classes.addressConfirmMsg} modal-body`}>
              Are you sure you want to delete this address?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary ConModal"
                onClick={() => deleteAddress(delAddress)}
                data-bs-dismiss="modal"
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-secondary CanModal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

        <div className={`${classes.FooterContainer}`}>
          {order ? "" : <MainFooter />}
        </div>
      </>
    );
  };

  export default ManageAddress;
