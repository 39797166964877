import React, { useEffect, useState } from "react";
import classes from "./ProductDetail.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PostWishlistHandler,
  RemoveWishlistHandler,
  getProductByIdHandler,
  getVariantIdHandler,
  getProductHandler,
  getReviewHandler,
  getWishlistHandler,
} from "../../Store/Product/ProductActions";
import { cartActions } from "../../Store/Cart/CartSlice";
import AddToCart from "../../UI/AddToCart/AddToCart";
import "../../UI/global.css";
import { AddCartHandler, getCartHandler } from "../../Store/Cart/CartActions";
import { GetAddressHandler } from "../../Store/Address/AddressActions";
import {
  LoggedInHandler,
  getCustomerByMobile,
} from "../../Store/Auth/AuthActions";
import {
  AddApprovalHandler,
  getCustomerApprovalHandler,
  getOrderListHandler,
  getStoreApprovalHandler,
} from "../../Store/Order/OrderActions";
import ImageSlider from "../../UI/ImageSlider/ImageSlider";
import productDetailImg from "../../Assets/Images/productDetailImg.png";
import superfastdevlivery from "../../Assets/Images/superfastdevlivery.png";
import bestPrices from "../../Assets/Images/bestPrices.png";
import Varitties from "../../Assets/Images/Varitties.png";

import noImg from "../../Assets/Images/noImage.png";
import { ReactComponent as NotFavouriteSvg } from "../../Assets/SvgComponents/NotFavouriteSvg.svg";
import { ReactComponent as FavouriteSvg } from "../../Assets/SvgComponents/FavouriteSvg.svg";
import { ReactComponent as CartSvg } from "../../Assets/SvgComponents/CartSvg.svg";
import { ReactComponent as GeoLocSvg } from "../../Assets/SvgComponents/GeoLocSvg.svg";
import { ReactComponent as DotSvg } from "../../Assets/SvgComponents/DotSvg.svg";
import { ReactComponent as InStock } from "../../Assets/SvgComponents/InStock.svg";
import Review from "../Review/Review";
import MainFooter from "../../UI/Footer/MainFooter";
import MainHeader from "../Home/MainHeader";
import { authActions } from "../../Store/Auth/AuthSlice";
import ImageMagnifier from "./ImageMagnifier.js";
import { storeActions } from "../../Store/StoreDetail/StoreSlice.js";
import { getStore } from "../../Store/StoreDetail/StoreActions.js";

const ProductDetail = () => {
  const { productId } = useParams();
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authToken = localStorage.getItem("authToken");
  const cartList = useSelector((state) => state.cart.cartList);
  const product = useSelector((state) => state.product?.product);
  const ReviewList = useSelector((state) => state.product?.ReviewList);
  const AddressKeeper = useSelector((state) => state.address.AddressKeeper);
  const productList = useSelector((state) => state.product?.productList);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const query = useSelector((state) => state.product?.query);
  const wishlist = useSelector((state) => state.product?.wishlist);
  const Profile = useSelector((state) => state.auth.Profile);
  const storeById = useSelector((state) => state.store.storeById);
  const cartData = useSelector((state) => state.cart.cartData);
  const orderList = useSelector((state) => state.order.orderList);
  const [carouselItems, setCarouselItems] = useState(4);
  const IsAllProduct = localStorage.getItem("IsAllProduct");

  // const [showMoreOffer, setShowMoreOffer] = useState(false);

  useEffect(() => {
    const updateCarouselItems = () => {
      if (window.innerWidth < 500) {
        setCarouselItems(1.5);
      } else if (window.innerWidth < 624) {
        setCarouselItems(2);
      } else if (window.innerWidth < 768) {
        setCarouselItems(2.5);
      } else if (window.innerWidth < 892) {
        setCarouselItems(3);
      } else if (window.innerWidth < 992) {
        setCarouselItems(3.5);
      } else if (window.innerWidth < 1116) {
        setCarouselItems(4);
      } else if (window.innerWidth < 1200) {
        setCarouselItems(4.5);
      } else {
        setCarouselItems(6);
      }
    };

    updateCarouselItems();

    window.addEventListener("resize", updateCarouselItems);

    return () => {
      window.removeEventListener("resize", updateCarouselItems);
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && !verfiyToken) {
      dispatch(getStore(id));
    }
  }, [id]);

  const withinPromotionTime = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const fromTimeParts = fromTime && fromTime.split(":");
    const toTimeParts = toTime && toTime.split(":");

    const fromHours = fromTimeParts && parseInt(fromTimeParts[0], 10);
    const fromMinutes = fromTimeParts && parseInt(fromTimeParts[1], 10);

    const toHours = toTimeParts && parseInt(toTimeParts[0], 10);
    const toMinutes = toTimeParts && parseInt(toTimeParts[1], 10);

    if (
      toHours > fromHours ||
      (toHours === fromHours && toMinutes >= fromMinutes)
    ) {
      return (
        (currentHours > fromHours ||
          (currentHours === fromHours && currentMinutes >= fromMinutes)) &&
        (currentHours < toHours ||
          (currentHours === toHours && currentMinutes <= toMinutes))
      );
    } else {
      return (
        currentHours > fromHours ||
        (currentHours === fromHours && currentMinutes >= fromMinutes) ||
        currentHours < toHours ||
        (currentHours === toHours && currentMinutes <= toMinutes)
      );
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  const defaultAddress = AddressKeeper?.filter(
    (address) => address.default === true
  )[0];

  const addCart = (product) => {
    //  if(hasValidVariants){
    //     if (variantDetails && variantDetails.length > 0 && !selectedSize) {
    //       setErrorMessage("Please select a size");
    //       return;
    //     }
    //   }
    const data = {
      Product_Id: product?._id,
      Product_name: product?.NameToDisplay,
      Product_Quantity: 1,
      FKProdID: product?.PKID,
      Product_detail: product?.Description,
      Product_Price: product?.SaleRate,
      User_Numberid: Profile?.MobileNumber,
      Product_Image: product?.Images?.length > 0 ? product?.Images[0] : null,
    };

    if (authToken) {
      dispatch(
        AddCartHandler(verfiyToken, authToken, data, data.User_Numberid)
      ).then((result) => {
        if (result.status === "success") {
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
        }
      });
    } else {
      dispatch(
        cartActions.pushProToCartListHandler({
          Product_Id: product._id,
          FKProdID: product.PKID,
          // FKLotID: 10000001,
          Product_name: product.NameToDisplay,
          Product_Quantity: 1,
          Product_detail: product.Description,
          Product_Price: product.SaleRate,

          User_Numberid: Profile?.MobileNumber,

          // product: product,
        })
      );
      dispatch(cartActions.calculateTotalLocalPriceHandler(product));
    }
  };

  const ProductDataHandler = (product) => {
    const modifiedName = product?.NameToDisplay.replace(/ /g, "-")
      .replace(/'/g, "")
      .replace(/:/g, "-")
      .replace(/\//g, "-");
    const encodedName = encodeURIComponent(modifiedName);
    dispatch(getProductByIdHandler(storeById?.StoreId, product?._id));
    navigate(`/${id}/productdetail/${encodedName}/${product?._id}`);
  };

  const [variantDetails, setVarientDetails] = useState();
  const [selectedSize, setSelectedSize] = useState(null);
  const [totalStockBySize, setTotalStockBySize] = useState({});
  // console.log(errorMessage);

  useEffect(() => {
    if (productId && product && verfiyToken) {
      console.log(productId);
      dispatch(getVariantIdHandler(storeById?.StoreId,id, product?.PKID))
        .then((data) => {
          setVarientDetails(data.data.VariantDetail);
        })
        .catch((err) => {
          console.log("Error fetching variant details");
        });
    }
  }, [dispatch, verfiyToken, productId, product?.PKID]);

  const hasValidVariants =
    variantDetails &&
    variantDetails.some(
      (variant) => variant.Batch || variant.Color // Check for valid fields
    );

  // console.log(hasValidVariants)

  useEffect(() => {
    if (hasValidVariants && variantDetails && variantDetails.length > 0) {
      const stockMap = variantDetails.reduce((acc, variant) => {
        const { Batch, CurStock } = variant;
        acc[Batch] = (acc[Batch] || 0) + CurStock;
        return acc;
      }, {});

      setTotalStockBySize(stockMap);
    } else {
      setTotalStockBySize({});
    }
  }, [variantDetails]);

  const handleSizeSelection = (size) => {
    if (totalStockBySize[size] === 0) {
      setErrorMessage("Selected size is out of stock");
    } else {
      setSelectedSize(size);
      setErrorMessage("");
    }
  };

  // console.log("variantDetails--------", variantDetails);
  const uniqueColors = Array.from(
    new Set(variantDetails?.map((variant) => variant.Color))
  );
  const uniqueSizes = Array.from(
    new Set(variantDetails?.map((variant) => variant.Batch))
  );
  // console.log("uniqueColors---", uniqueColors);
  // console.log("uniqueSizes---", uniqueSizes);

  const addToWishlist = (WishlistProductId) => {
    const data = {
      WishlistProductId: WishlistProductId,
      User_Number: Profile?.MobileNumber,
      appKeyToken: verfiyToken,
    };
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(PostWishlistHandler(authToken, data)).then((result) => {
            if (result.status === "success") {
              dispatch(
                getWishlistHandler(
                  authToken,
                  { appKeyToken: verfiyToken },
                  Profile?.MobileNumber
                )
              );
            } else {
              navigate("/verification");
              dispatch(authActions.setFromPage("cart"));
            }
          });
        }
      });
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("cart"));
    }
  };

  const removeWishlist = (id) => {
    dispatch(
      RemoveWishlistHandler(authToken, Profile?.MobileNumber, id, {
        appKeyToken: verfiyToken,
      })
    ).then((result) => {
      if (result.status === "success") {
        dispatch(
          getWishlistHandler(
            authToken,
            { appKeyToken: verfiyToken },
            Profile?.MobileNumber
          )
        );
      } else {
        navigate("/verification");
        dispatch(authActions.setFromPage("cart"));
      }
    });
  };

  const findProd = authToken
    ? cartList?.length > 0 &&
      cartList.find((cartprod) => cartprod?.Product_Id === product?._id)
    : cartData?.length > 0 &&
      cartData.find((cartprod) => cartprod?.Product_Id === product?._id);

  const placeOrder = (product) => {
    if (variantDetails && variantDetails.length > 0 && !selectedSize) {
      setErrorMessage("Please select a size");
      return;
    }
    if (authToken) {
      if (AddressKeeper?.length > 0) {
        dispatch(getStoreApprovalHandler(verfiyToken)).then((result) => {
          if (result.data.CustomerApproval === true) {
            navigate(`/${id}/orderSteps/${product?._id}`);
          } else {
            dispatch(
              getCustomerApprovalHandler(
                verfiyToken,
                storeById?.LocationId,
                defaultAddress.mobileNumber
              )
            ).then((result) => {
              if (result.status === "success") {
                if (result.data === true) {
                  navigate(`/${id}/orderSteps/${product?._id}`);
                } else {
                  alert(
                    "You are not authorised yet. kindly wait for the approval!"
                  );
                  navigate(`/${id}/home`);
                }
              } else {
                const data = {
                  customerName: defaultAddress?.name,
                  Mobile: defaultAddress?.mobileNumber,
                  LocationId: storeById?.LocationId,
                  StoreId: storeById?.StoreId,
                  addressLine1: defaultAddress?.addressLine1,
                  addressLine2: defaultAddress?.addressLine2,
                  city: defaultAddress?.city,
                  landmark: defaultAddress?.landmark,
                  pincode: defaultAddress?.pincode,
                  state: defaultAddress?.state,
                  products: {
                    Product_Id: product?._id,
                    Product_name: product?.NameToDisplay,
                    Product_Price: product?.SaleRate,
                  },
                };
                dispatch(AddApprovalHandler(verfiyToken, data)).then(
                  (result) => {
                    if (result.status === "success") {
                      alert(
                        "Request sent successfully. Kindly wait for the approval!"
                      );
                      // navigate("/home");
                    } else {
                      alert("You have already requested your approval request");
                      navigate(`/${id}/home`);
                    }
                  }
                );
              }
            });
          }
        });
      } else {
        navigate(`/${id}/Address`);
      }
    } else {
      navigate("/verification");
      // alert("log in first")
      dispatch(authActions.setFromPage("cart"));
    }
  };
  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    let tempQuery = structuredClone(query);
    tempQuery.IsAllProduct = IsAllProduct;
    dispatch(getProductHandler(storeById?.StoreId, tempQuery));
  }, [dispatch, query, verfiyToken, cartList]);

  useEffect(() => {
    if (productId && verfiyToken) {
      dispatch(getProductByIdHandler(storeById?.StoreId, productId));
      dispatch(getReviewHandler(storeById?.StoreId, productId));
    }
  }, [dispatch, storeById, productId]);

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
          dispatch(
            getWishlistHandler(
              authToken,
              { appKeyToken: verfiyToken },
              result.data.mobile
            )
          );
          dispatch(GetAddressHandler(authToken, result.data.id));
          dispatch(getCustomerByMobile(verfiyToken, result.data.mobile)).then(
            (result) => {
              if (result.status === "success") {
                dispatch(
                  getOrderListHandler(verfiyToken, authToken, result.data)
                );
              }
            }
          );
        }
      });
    }
  }, [dispatch, verfiyToken, authToken]);


  const productExistsInOrderList = orderList?.some((order) => {
    return order?.ProdDetail?.some(
      (productDetail) => productDetail?.Product_Id === product?._id
    );
  });



  return (
    <>
      <div className={`${classes.ProductDetailBGClr}`}>
        <div className={`${classes.ProductDetailHeader}`}>
          <MainHeader />
        </div>

        <div className={`${classes.productCardView}`}>
          <div className="container-fluid p-0">
            <nav className={` navbar navbar-light  ${classes.headerFix}    `}>
              <div
                className={` ${classes.header} d-flex justify-content-between container-fluid`}
              >
                <div
                  className={`${classes.ProductHeaderIconBox} ${classes.ProductBackIcon} `}
                  onClick= {handleHeaderClick}
                >
                  <span>
                    <i className="bi bi-arrow-left"></i>
                  </span>
                  <h6>{`${product?.NameToDisplay}`}</h6>
                </div>

                <div className={` ${classes.rightSideIcons}  gap-3`}>
                  <div
                    className={`${classes.ProductHeaderIconBox} `}
                    onClick={() => {
                      navigator.share({
                        title: "MDN",
                        text: "Learn web development on MDN!",
                        url: pathname,
                      });
                    }}
                  >
                    <i class="bi bi-share"></i>
                  </div>
                  <div
                    className={`${classes.ProductHeaderIconBox} ${classes.ProductHeaderIconBoxCart}`}
                    onClick={() => navigate(`/${id}/cart`)}
                  >
                    <i class="bi bi-cart4" style={{ fontSize: "24px" }}></i>
                    <div className={`${classes.ProductHeaderIconBoxNumber} `}>
                      {authToken ? cartList?.length : cartData?.length}
                    </div>
                  </div>
                </div>
              </div>
            </nav>

            <div className={`${classes.productDetailPage}`}>
              <div className={`${classes.productBreadCrumbs}`}>
                <p className={`${classes.productBreads}`}>
                  <span onClick={() => navigate(`/${id}/home`)}> Home</span>/
                  {product?.ERPBrand && (
                    <>
                      <span>{product?.ERPBrand}</span>
                      <span>/</span>
                    </>
                  )}
                  <span className={`${classes.productBreadMain}`}>
                    {product?.NameToDisplay}
                  </span>
                </p>
              </div>

              <div className={`${classes.productDetailMain}`}>
                <div className={`${classes.ProductMain} row`}>
                  <ImageSlider product={product} />
                </div>
                <div className={`${classes.ProductDetail} `}>
                  <div className={`${classes.ProductDetailLeft} `}>
                    <div className={`${classes.ProductInfo} `}>
                      <div className={`${classes.productNameToDIsplay}`}>
                        <h1>{product?.NameToDisplay}</h1>
                      </div>
                      <div className={`${classes.productErpCategory}`}>
                        <h1>{product?.ERPCategory}</h1>
                      </div>
                      <div className={`${classes.rating}`}>
                        <p className={`${classes.ratingAmount}`}>
                          {product?.AverageProductRating}
                        </p>
                        {Array.from({ length: 5 }, (_, index) => {
                          return index <
                            Math.round(product?.AverageProductRating) ? (
                            <i key={index} className="bi bi-star-fill pe-1"></i>
                          ) : (
                            <i key={index} className="bi bi-star pe-1"></i>
                          );
                        })}
                      </div>{" "}
                      {/* <div className={`${classes.InStock} `}>
                        <span>
                          <i className="bi bi-check"></i>
                        </span>
                        <span>In Stock</span>
                      </div> */}
                      <div className={`${classes.amount} mb-0`}>
                        {/* <div className={`${classes.rating} pb-1  `}>
                          {Array.from({ length: 5 }, (_, index) => {
                            return index <
                              Math.round(product?.AverageProductRating) ? (
                              <i
                                key={index}
                                className="bi bi-star-fill pe-1"
                              ></i>
                            ) : (
                              <i key={index} className="bi bi-star pe-1"></i>
                            );
                          })}
                        </div>{" "} */}
                        <span className={`${classes.priceProduct} mt-0`}>
                          {" "}
                          ₹{product?.SaleRate}{" "}
                        </span>
                        {product?.appliedPromotions?.length > 0 &&
                          product?.appliedPromotionOnProductCategoryVise
                            ?.length === 0 &&
                          product?.appliedPromotionOnProductBrandVise
                            ?.length === 0 &&
                          withinPromotionTime(
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionFromTime,
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionToTime
                          ) &&
                          (!product?.appliedPromotions[
                            product?.appliedPromotions.length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <span className={`${classes.productOff}`}>
                              {" "}
                              <span className="me-1 ms-2 ">
                                {" "}
                                <i className="bi bi-dot"></i>
                              </span>
                              ({" "}
                              {
                                product?.appliedPromotions[
                                  product?.appliedPromotions?.length - 1
                                ]?.ProductPromotionPercentageRate
                              }
                              % off)
                            </span>
                          )}
                        {product?.appliedPromotions?.length > 0 &&
                          (product?.appliedPromotionOnProductCategoryVise
                            ?.length > 0 ||
                            product?.appliedPromotionOnProductBrandVise
                              ?.length > 0) &&
                          withinPromotionTime(
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionFromTime,
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionToTime
                          ) &&
                          (!product?.appliedPromotions[
                            product?.appliedPromotions.length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <span>
                              <span className={`${classes.productOff} ms-2`}>
                                <span className="me-1 ms-1 ">
                                  {" "}
                                  <i className="bi bi-dot"></i>
                                </span>
                                ({" "}
                                {
                                  product?.appliedPromotions[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionPercentageRate
                                }
                                % off)
                              </span>
                            </span>
                          )}
                        {product?.appliedPromotions?.length === 0 &&
                          product?.appliedPromotionOnProductCategoryVise
                            ?.length > 0 &&
                          product?.appliedPromotionOnProductBrandVise?.length >
                            0 &&
                          withinPromotionTime(
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionFromTime,
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionToTime
                          ) &&
                          (!product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <span className={`${classes.productOff}`}>
                              {" "}
                              <span className="me-1 ms-2 ">
                                {" "}
                                <i className="bi bi-dot"></i>
                              </span>
                              (
                              {
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductPromotionCategoryPercentageRate
                              }
                              % off)
                            </span>
                          )}
                        {product?.appliedPromotionOnProductCategoryVise
                          ?.length > 0 &&
                          product?.appliedPromotions?.length === 0 &&
                          product?.appliedPromotionOnProductBrandVise
                            ?.length === 0 &&
                          withinPromotionTime(
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionFromTime,
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionToTime
                          ) &&
                          (!product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <span className={`${classes.productOff}`}>
                              {" "}
                              <span className="me-1 ms-2 ">
                                {" "}
                                <i className="bi bi-dot"></i>
                              </span>
                              (
                              {
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductPromotionCategoryPercentageRate
                              }
                              % off)
                            </span>
                          )}
                        {product?.appliedPromotionOnProductBrandVise?.length >
                          0 &&
                          product?.appliedPromotionOnProductCategoryVise
                            ?.length === 0 &&
                          product?.appliedPromotions?.length === 0 &&
                          withinPromotionTime(
                            product?.appliedPromotionOnProductBrandVise[
                              product?.appliedPromotionOnProductBrandVise
                                .length - 1
                            ]?.ProductBrandPromotionFromTime,
                            product?.appliedPromotionOnProductBrandVise[
                              product?.appliedPromotionOnProductBrandVise
                                .length - 1
                            ]?.ProductBrandPromotionToTime
                          ) &&
                          (!product?.appliedPromotionOnProductBrandVise[
                            product?.appliedPromotionOnProductBrandVise.length -
                              1
                          ]?.FKLocationID ||
                            product?.appliedPromotionOnProductBrandVise[
                              product?.appliedPromotionOnProductBrandVise
                                .length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <span className={`${classes.productOff}`}>
                              {" "}
                              <span className="me-1 ms-2 ">
                                {" "}
                                <i className="bi bi-dot"></i>
                              </span>
                              ({" "}
                              {
                                product?.appliedPromotionOnProductBrandVise[
                                  product?.appliedPromotionOnProductBrandVise
                                    .length - 1
                                ]?.ProductPromotionBrandPercentageRate
                              }
                              % off)
                            </span>
                          )}
                      </div>
                    </div>
                    <div className={`${classes.productDescription}`}>
                      {/* <h4>Details :</h4>
                      <div className={`${classes.productDescriptionList}`}>
                        <p>
                          <span>Category : </span>
                          <span> {product?.ERPCategory}</span>
                        </p>
                        </div>*/}
                      <div>
                        <p>
                          <span>Brand : </span>
                          <span> {product?.ERPBrand}</span>
                        </p>
                      </div>
                      {hasValidVariants ? (
                        //  {variantDetails && variantDetails.length > 0 ? (

                        <div className={classes.VariantSection}>
                          {/* {uniqueColors.length > 0 && (
                            <div>
                              <h4 className={`${classes.selectVariant}`} >Select Color:</h4>
                              <div className={`${classes.selectVariantColor}`}>
                                {uniqueColors.map((color, index) => (
                                  // <button>
                                  <div key={index}>{color}</div>

                                  // </button>

                                ))}
                              </div>
                            </div>
                          )} */}

                          {uniqueSizes.length > 0 && (
                            <div className={`${classes.selectVariantSection}`}>
                              <p className={`${classes.selectVariant}`}>
                                Choose a size:
                              </p>

                              <div className={`${classes.selectVariantSize}`}>
                                {Object.entries(totalStockBySize).map(
                                  ([size, stock]) => (
                                    <button
                                      key={size}
                                      className={`${classes.sizeButton} ${
                                        selectedSize === size
                                          ? classes.selectedSize
                                          : ""
                                      } ${
                                        stock === 0 ? classes.outOfStock : ""
                                      }`}
                                      onClick={() => handleSizeSelection(size)}
                                      disabled={stock === 0}
                                    >
                                      {size}{" "}
                                      {/* {stock === 0 ? "(Out of Stock)" : ""} */}
                                    </button>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          {" "}
                          <h4>Details :</h4>
                          <div className={`${classes.productDescriptionList}`}>
                            <p>
                              <span>Category : </span>
                              <span> {product?.ERPCategory}</span>
                            </p>
                          </div>
                          <div>
                            <p>
                              <span>Brand Name : </span>
                              <span> {product?.ERPBrand}</span>
                            </p>
                          </div>
                          <div>
                            <p>
                              <span>Strength : </span>
                              <span> {product?.ERPStrength}</span>
                            </p>
                          </div>
                          <div>
                            <p>
                              <span>Marketing Group : </span>
                              <span> {product?.ERPGroupName}</span>
                            </p>
                          </div>
                          <div>
                            <p>
                              <span>Manufacture : </span>
                              <span> {product?.MfgGroupName}</span>
                            </p>
                          </div>
                        </>
                      )}

                      {errorMessage && (
                        <p className={`${classes.sizeErrorMsg}`}>
                          {errorMessage}
                        </p>
                      )}

                      {/* <div className={`${classes.categoryName}   pt-2`}>
                        <img src={superfastdevlivery} alt="pic1" />
                        <img src={bestPrices} alt="pic1" />
                        <img src={Varitties} alt="pic1" />
                      </div> */}

                      {/* <div className={`${classes.rating} pb-1 pt-1 `}>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill px-1"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-half px-1"></i>
                        <i className="bi bi-star"></i>
                        <span className='ms-2'>3,872 ratings & 117 reviews </span>
                    </div> */}
                    </div>

                    <div className={`${classes.footer} `}>
                      {findProd ? (
                        <button
                          className={`${classes.cartBtn} d-flex justify-content-around`}
                        >
                          <AddToCart product={findProd} />
                        </button>
                      ) : (
                        <button
                          className={`${classes.cartBtn}`}
                          onClick={() => addCart(product)}
                        >
                          <span>
                            ADD TO CART <i className="bi bi-cart"></i>
                          </span>
                        </button>
                      )}
                      <button
                        className={`${classes.buyBtn}`}
                        onClick={() => placeOrder(product)}
                      >
                        BUY NOW
                      </button>
                    </div>
                    <div className={`${classes.DeliveryDeatilsImage}`}>
                      <img src={productDetailImg} alt="delivery-details" />
                    </div>
                  </div>

                  {/* <div className={`${classes.StoreDetails}`}>
                    <div className={`${classes.StoreNameWithLogo}`}>
                      <img src={storeById?.Logo} />
                      <div>
                        <h4>{storeById?.StoreName}</h4>
                        <span>{storeById?.LocationName}</span>
                      </div>
                    </div>
                    <ul className={`${classes.StoreDetailPoints}`}>
                      <li>
                        <span>
                          <i className="bi bi-shield-check"></i>
                        </span>
                        <span>Verified Seller</span>
                      </li>
                      <li>
                        <span>
                          <i className="bi bi-globe"></i>
                        </span>
                        <span>Fast Delivery</span>
                      </li>
                      <li>
                        <span>
                          <i className="bi bi-headset"></i>
                        </span>
                        <span>24/7 Customer Support</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* add to cart  */}

          <div
            className={`${classes.delivery} justify-content-between`}
            onClick={() => navigate(`/${id}/manageaddress`)}
          >
            {AddressKeeper?.filter((address) => address.default === true)?.map(
              (address, index) => {
                return (
                  <React.Fragment key={index}>
                    {" "}
                    <div className="d-flex flex-column ms-2">
                      <div className={`${classes.addressContent}`}>
                        <GeoLocSvg />
                        <span className="ms-2">Delivering to </span>
                        <span className={`${classes.addressRightContent}`}>
                          {" "}
                          : {address.name.split(" ")[0]}, {address.addressLine1}{" "}
                        </span>
                      </div>
                      <div className={`${classes.CartAddressContent}`}>
                        Delivers in 7-10 days
                      </div>
                    </div>
                    <div className="mt-2 ">
                      <i className={` bi bi-chevron-right`}></i>
                    </div>
                  </React.Fragment>
                );
              }
            )}
          </div>

          <div className={`${classes.ProductDetailContent}`}>
            <div className={`${classes.ProductDescriptionRecom}`}>
              {product?.Description ? (
                <div className={`${classes.productDetailTheory}`}>
                  <h6 className={`${classes.productDetailText}`}>
                    Product Description{" "}
                  </h6>

                  <div
                    className=" BlogProductText"
                    dangerouslySetInnerHTML={{ __html: product?.Description }}
                  />
                </div>
              ) : (
                <div className={`${classes.productDetailTheory}`}>
                  <h6 className={`${classes.productDetailText}`}>
                    Product Description{" "}
                  </h6>
                  <p>A Swilmart Product</p>
                </div>
              )}

              <div className={`${classes.YouMayLikeProducts}`}>
                <h6 className={`${classes.YouMayLikeProductsHeading}`}>
                  You may also like
                </h6>

                {productList?.slice(0, 4).map((product, index) => {
                  const findFav = wishlist?.WishlistProductId?.find(
                    (wish) => wish === product?.PKID
                  );
                  return (
                    <div className="d-block text-center" key={index}>
                      <div className={`${classes.recommendProduct} pt-1`}>
                        <div className={`${classes.favourite} `}>
                          {findFav ? (
                            <div onClick={() => removeWishlist(product?.PKID)}>
                              <FavouriteSvg />
                            </div>
                          ) : (
                            <div onClick={() => addToWishlist(product?.PKID)}>
                              <NotFavouriteSvg />
                            </div>
                          )}
                        </div>

                        <div
                          className={`${classes.RecommendProduct}  position-relative pt-1`}
                          onClick={() => ProductDataHandler(product)}
                        >
                          {product?.Images?.length > 0 && product?.Images ? (
                            <img
                              variant="top"
                              src={product?.Images[0]}
                              alt="imageNotAvailable"
                              // className="img-fluid"
                            />
                          ) : (
                            <img
                              variant="top"
                              src={noImg}
                              alt="imageNotAvailable"
                              className="img-fluid"
                            />
                          )}
                        </div>

                        <div
                          className={`${classes.ProductLikeName} ${classes.productDetailName}pt-2`}
                        >
                          <h6
                            className="text-capitalize mb-0"
                            onClick={() => ProductDataHandler(product)}
                          >
                            {product?.Product}{" "}
                          </h6>

                          <div className="d-flex gap-2">
                            <p className={`${classes.RecommendAmount} mb-0`}>
                              ₹{product?.SaleRate}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`d-flex flex-column mt-2`}>
              <div className={`${classes.productReview}`}>
                {ReviewList?.customerReviews?.length > 0 && (
                  <h6 className={`${classes.productDetailText}`}>Reviews</h6>
                )}
                {productExistsInOrderList && (
                  <button
                    className={`${classes.reviewBtn} `}
                    onClick={() => navigate(`/${id}/SubmitReview/${productId}`)}
                  >
                    Review Product
                  </button>
                )}
              </div>
              {ReviewList?.customerReviews?.length > 0 && (
                <Review
                  ReviewList={ReviewList?.customerReviews?.slice(0, 3)}
                  id={productId}
                />
              )}
              {ReviewList?.customerReviews?.length > 3 && (
                <h6
                  className={`${classes.productDetailText} ${classes.seeMoreText}  mt-2`}
                  onClick={() => navigate(`/${id}/Review`)}
                >
                  See More
                </h6>
              )}
            </div>

            <div className={`${classes.similarProducts}`}>
              <div
                className={`${classes.similarProductsText} d-flex justify-content-between`}
              >
                <h6 className={`${classes.productDetailText}`}>
                  Similar Products
                </h6>
              </div>
              <div className={`${classes.similarProductsMain} `}>
                {productList?.map((product, index) => {
                  const findProd = authToken
                    ? cartList?.length > 0 &&
                      cartList.find(
                        (cartprod) => cartprod?.Product_Id === product?._id
                      )
                    : cartData?.length > 0 &&
                      cartData.find(
                        (cartprod) => cartprod?.Product_Id === product?._id
                      );

                  const findFav = wishlist?.WishlistProductId?.find(
                    (wish) => wish === product?.PKID
                  );
                  return (
                    <div className="d-block text-center" key={index}>
                      <div className={`${classes.productDetailList} `}>
                        <div className={`${classes.favourite} `}>
                          {findFav ? (
                            <div onClick={() => removeWishlist(product?.PKID)}>
                              <FavouriteSvg />
                            </div>
                          ) : (
                            <div onClick={() => addToWishlist(product?.PKID)}>
                              <NotFavouriteSvg />
                            </div>
                          )}
                        </div>
                        {product?.appliedPromotions?.length > 0 &&
                          product?.appliedPromotionOnProductCategoryVise
                            ?.length === 0 &&
                          product?.appliedPromotionOnProductBrandVise
                            ?.length === 0 &&
                          withinPromotionTime(
                            product?.appliedPromotions?.[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionFromTime,
                            product?.appliedPromotions?.[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionToTime
                          ) &&
                          (!product?.appliedPromotions[
                            product?.appliedPromotions.length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <div className={`${classes.promotion}`}>
                              <div className={`${classes.promotionPrice}`}>
                                {
                                  product?.appliedPromotions[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionPercentageRate
                                }
                                % off
                              </div>
                            </div>
                          )}

                        {product?.appliedPromotions?.length > 0 &&
                          (product?.appliedPromotionOnProductCategoryVise
                            ?.length > 0 ||
                            product?.appliedPromotionOnProductBrandVise
                              ?.length > 0) &&
                          withinPromotionTime(
                            product?.appliedPromotions?.[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionFromTime,
                            product?.appliedPromotions?.[
                              product?.appliedPromotions.length - 1
                            ]?.ProductPromotionToTime
                          ) &&
                          (!product?.appliedPromotions[
                            product?.appliedPromotions.length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <div className={`${classes.promotion}`}>
                              <div className={`${classes.promotionPrice}`}>
                                {
                                  product?.appliedPromotions[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionPercentageRate
                                }
                                % off
                              </div>
                            </div>
                          )}

                        {product?.appliedPromotions?.length === 0 &&
                          product?.appliedPromotionOnProductCategoryVise
                            ?.length > 0 &&
                          product?.appliedPromotionOnProductBrandVise?.length >
                            0 &&
                          withinPromotionTime(
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionFromTime,
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionToTime
                          ) &&
                          (!product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <div className={`${classes.promotion}`}>
                              <div className={`${classes.promotionPrice}`}>
                                {
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductPromotionCategoryPercentageRate
                                }
                                % off
                              </div>
                            </div>
                          )}

                        {product?.appliedPromotionOnProductCategoryVise
                          ?.length > 0 &&
                          product?.appliedPromotions?.length === 0 &&
                          product?.appliedPromotionOnProductBrandVise
                            ?.length === 0 &&
                          withinPromotionTime(
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionFromTime,
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.ProductCategoryPromotionToTime
                          ) &&
                          (!product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.FKLocationID ||
                            product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <div className={`${classes.promotion}`}>
                              <div className={`${classes.promotionPrice}`}>
                                {
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductPromotionCategoryPercentageRate
                                }
                                % off
                              </div>
                            </div>
                          )}

                        {product?.appliedPromotionOnProductBrandVise?.length >
                          0 &&
                          product?.appliedPromotionOnProductCategoryVise
                            ?.length === 0 &&
                          product?.appliedPromotions?.length === 0 &&
                          withinPromotionTime(
                            product?.appliedPromotionOnProductBrandVise[
                              product?.appliedPromotionOnProductBrandVise
                                .length - 1
                            ]?.ProductBrandPromotionFromTime,
                            product?.appliedPromotionOnProductBrandVise[
                              product?.appliedPromotionOnProductBrandVise
                                .length - 1
                            ]?.ProductBrandPromotionToTime
                          ) &&
                          (!product?.appliedPromotionOnProductBrandVise[
                            product?.appliedPromotionOnProductBrandVise.length -
                              1
                          ]?.FKLocationID ||
                            product?.appliedPromotionOnProductBrandVise[
                              product?.appliedPromotionOnProductBrandVise
                                .length - 1
                            ]?.FKLocationID === storeById?.LocationId) && (
                            <div className={`${classes.promotion}`}>
                              <div className={`${classes.promotionPrice}`}>
                                {
                                  product?.appliedPromotionOnProductBrandVise[
                                    product?.appliedPromotionOnProductBrandVise
                                      .length - 1
                                  ]?.ProductPromotionBrandPercentageRate
                                }
                                % off
                              </div>
                            </div>
                          )}

                        <div
                          className={`${classes.categoryImage}  position-relative`}
                          onClick={() => ProductDataHandler(product)}
                        >
                          {product?.Images?.length > 0 && product?.Images ? (
                            <img
                              variant="top"
                              src={product?.Images[0]}
                              alt="imageNotAvailable"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              variant="top"
                              src={noImg}
                              alt="imageNotAvailable"
                              className="img-fluid"
                            />
                          )}
                        </div>

                        <div
                          className={`${classes.YouMayLikeProductName}  pt-2`}
                        >
                          <h6
                            className={`${classes.YouMayLikeProductBrand}  text-capitalize `}
                            onClick={() => ProductDataHandler(product)}
                          >
                            {product?.ERPBrand}{" "}
                          </h6>

                          <h6
                            className={`${classes.YouMayLikeProduct}  text-capitalize mb-0`}
                            onClick={() => ProductDataHandler(product)}
                          >
                            {product?.Product}{" "}
                          </h6>
                          <div className={`${classes.rating} pb-1  `}>
                            {Array.from({ length: 5 }, (_, index) => {
                              return index <
                                Math.round(product?.AverageProductRating) ? (
                                <i
                                  key={index}
                                  className="bi bi-star-fill pe-1"
                                ></i>
                              ) : (
                                <i key={index} className="bi bi-star pe-1"></i>
                              );
                            })}
                          </div>
                          <p className={`${classes.amount} mb-0`}>
                            ₹{product?.SaleRate}
                          </p>
                          {/* <p>
                              <span className={`${classes.previousAmount} `}>
                                <del>₹{product?.SaleRate * 2}</del>
                              </span>
                            </p> */}

                          {/* <div className={`${classes.rating} pb-1  `}>
                      <i className="bi bi-star-fill"></i>
                      <i className="bi bi-star-fill px-1"></i>
                      <i className="bi bi-star-fill"></i>
                      <i className="bi bi-star-half px-1"></i>
                      <i className="bi bi-star"></i>
                    </div> */}
                          <div>
                            {findProd ? (
                              <button className={`${classes.itemSet}`}>
                                <AddToCart product={findProd} />
                              </button>
                            ) : (
                              <button
                                className={`${classes.cartBtnDetail}`}
                                onClick={() => addCart(product)}
                              >
                                <CartSvg /> ADD
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* {cartList.length > 0 ? <Footer onClick={() => navigate("/cart")} /> : ""} */}
      </div>
      <MainFooter />
    </>
  );
};

export default ProductDetail;
