import React, { useEffect, useState } from "react";
import classes from "./Address.module.css";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import Header from "../../UI/Header/Header";
import { addressActions } from "../../Store/Address/AddressSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  AddressHandler,
  UpdateAddressByIdHandler,
} from "../../Store/Address/AddressActions";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

//img path
import { ReactComponent as HomeSvg } from "../../Assets/SvgComponents/HomeSvg.svg";
import { ReactComponent as WorkSvg } from "../../Assets/SvgComponents/WorkSvg.svg";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import toast from "react-hot-toast";

const Address = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {id}  = useParams();

  const Address = useSelector((state) => state.address.Address);
  const authToken = localStorage.getItem("authToken");
  const [text, setText] = useState("");
  const [showOtherType, setShowOtherType] = useState(false);
  const [otherType, setOtherType] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if ( 
      Address?.type !== "Home" &&
      Address?.type !== "Work" &&
      Address?.type !== ""
    ) {
      setShowOtherType(true);
      setOtherType(Address.type);
    }
  }, [Address.type]);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const setValueHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    dispatch(
      addressActions.setAddressFieldHandler({ name: name, value: value })
    );
  };

  const setTypeHandler = (type, value) => {
    if (value === "Others") {
      setShowOtherType(true);
    } else {
      setShowOtherType(false);
    }
    dispatch(
      addressActions.setAddressFieldHandler({ name: type, value: value })
    );
  };

  if (showOtherType) {
    dispatch(
      addressActions.setAddressFieldHandler({ name: "type", value: otherType })
    );
  }

  const validateFields = () => {
    const errors = {};
    if (!Address.name) errors.name = "Full name is required.";
    if (!Address.addressLine1)
      errors.addressLine1 = "Address line 1 is required.";
    if (!Address.addressLine2)
      errors.addressLine2 = "Address line 2 is required.";
    if (!Address.mobileNumber)
      errors.mobileNumber = "Mobile number is required.";
    if (!Address.pincode) errors.pincode = "Pincode is required.";
    if (!Address.city) errors.city = "City is required.";
    if (!Address.state) errors.state = "State is required.";
    if (!Address.type || (Address.type === "Others" && !otherType)) {
      errors.type = "Address type is required.";
    }
    return errors;
  };

  const saveHandler = () => {
    // if (
    //   !Address.name ||
    //   !Address.addressLine1 ||
    //   !Address.addressLine2 ||
    //   !Address.mobileNumber ||
    //   !Address.pincode ||
    //   !Address.city ||
    //   !Address.state ||
    //   !Address.type ||
    //   (Address.type === "Others" && !otherType)
    // ) {
    //   setText("Please fill out all required fields.");
    //   return;
    // }
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    dispatch(LoggedInHandler(authToken)).then((result) => {
      if (result.status === "success") {
        const AddressData = {
          Custom_User_id: result.data.id,
          UserAddress: [Address],
        };

        if (Address._id) {
          dispatch(
            UpdateAddressByIdHandler(authToken, Address._id, Address)
          ).then((result) => {
            if (result.status === "success") {
              navigate(-1);
            }
          });
        } else {
          dispatch(AddressHandler(authToken, AddressData)).then((result) => {
            if (result.status === "success") {
              navigate(-1);

              toast("Address Saved", {
                style: {
                  border: "1px solid #006d5b",
                  padding: "8px 14px",
                  background: "#006d5b",
                  color: "#fff",
                },
              });
            }
          });
        }
      }
    });
  };

  const handleCheckboxChange = (e) => {
    var name = e.target.name;
    var value = e.target.checked;

    dispatch(
      addressActions.setAddressFieldHandler({
        name: name,
        value: !Address.default,
      })
    );
  };


  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  useEffect(()=>{
    if(authToken){
      return ;
    } else{
      navigate("/verification");

    }
  })

  return (
    <>
      <div className={`${classes.NavigationHeader}`}>
        <MainHeader />
      </div>

      <div className={`${classes.addressFormWrapper} container`}>
        <Header
          heading={Address._id ? "Edit Address" : "Add New Address"}
          onClick={handleHeaderClick}
        />
        <div className={`${classes.addressForm} mx-auto ${classes.proHeight}`}>
          <div className="mb-2  position-relative">
            <label className="form-label ">
              FULL NAME <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className={`form-control ${classes.AddressInput}`}
              name="name"
              value={Address.name ?? ""}
              placeholder="Enter your Full name"
              onChange={(e) => setValueHandler(e)}
            />
            {validationErrors.name && (
              <div className="text-danger small">{validationErrors.name}</div>
            )}
          </div>
          <div className="mb-2 position-relative">
            <label className="form-label">
              ADDRESS LINE 1<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className={`form-control ${classes.AddressInput}`}
              name="addressLine1"
              value={Address.addressLine1 ?? ""}
              placeholder="Address Line 1"
              onChange={(e) => setValueHandler(e)}
            />
            {validationErrors.addressLine1 && (
              <div className="text-danger small">
                {validationErrors.addressLine1}
              </div>
            )}
          </div>
          <div className="mb-2 position-relative">
            <label className="form-label">
              ADDRESS LINE 2<span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className={`form-control ${classes.AddressInput}`}
              name="addressLine2"
              value={Address.addressLine2 ?? ""}
              placeholder="Address Line 2"
              onChange={(e) => setValueHandler(e)}
            />
            {validationErrors.addressLine2 && (
              <div className="text-danger small">
                {validationErrors.addressLine2}
              </div>
            )}
          </div>
          <div className="d-flex gap-2">
            <div className="mb-2 w-100 position-relative">
              <label className="form-label">
                MOBILE NUMBER<span className="text-danger"> *</span>
              </label>
              <input
                type="number"
                className={`form-control ${classes.AddressInput}`}
                name="mobileNumber"
                value={Address.mobileNumber ?? ""}
                placeholder="Enter mobile number"
                onChange={(e) => setValueHandler(e)}
                onKeyPress={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                  if (
                    Address.mobileNumber.length >= 10 &&
                    e.key !== "Backspace"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              {validationErrors.mobileNumber && (
                <div className="text-danger small">
                  {validationErrors.mobileNumber}
                </div>
              )}
            </div>
            <div className="mb-2 w-100 position-relative">
              <label className="form-label">
                PINCODE<span className="text-danger"> *</span>
              </label>
              <input
                type="num"
                className={`form-control ${classes.AddressInput}`}
                name="pincode"
                value={Address.pincode ?? ""}
                placeholder="Enter your pincode"
                onChange={(e) => setValueHandler(e)}
                onKeyPress={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                  if (Address.pincode.length >= 6 && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
              {validationErrors.pincode && (
                <div className="text-danger small">
                  {validationErrors.pincode}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="mb-2 w-100 position-relative">
              <label className="form-label">
                CITY<span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className={`form-control ${classes.AddressInput}`}
                name="city"
                value={Address.city ?? ""}
                placeholder="Enter your city"
                onChange={(e) => setValueHandler(e)}
              />
              {validationErrors.city && (
                <div className="text-danger small">{validationErrors.city}</div>
              )}
            </div>
            <div className="mb-2 w-100 position-relative">
              <label className="form-label">
                STATE<span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className={`form-control ${classes.AddressInput}`}
                name="state"
                value={Address.state ?? ""}
                placeholder="Enter your state"
                onChange={(e) => setValueHandler(e)}
              />
              {validationErrors.state && (
                <div className="text-danger small">
                  {validationErrors.state}
                </div>
              )}
            </div>
          </div>
          <div className="mb-2 position-relative">
            <label className="form-label">LANDMARK (OPTIONAL)</label>
            <input
              type="text"
              className={`form-control ${classes.AddressInput}`}
              name="landmark"
              value={Address.landmark ?? ""}
              placeholder="Enter your landmark"
              onChange={(e) => setValueHandler(e)}
            />
            {validationErrors.landmark && (
              <div className="text-danger small">
                {validationErrors.landmark}
              </div>
            )}
          </div>
          <div className="mb-2 position-relative">
            <label className="form-label">
              TYPE OF ADDRESS<span className="text-danger"> *</span>
            </label>
            <div className={`d-flex gap-3 ${classes.AddressTypeList}`}>
              <div
                className={
                  Address.type === "Home"
                    ? ` ${classes.AddressType} ${classes.typeBorder}`
                    : `${classes.AddressType} `
                }
                onClick={() => setTypeHandler("type", "Home")}
              >
                <HomeSvg /> <span className="ms-2">Home</span>
              </div>
              <div
                className={
                  Address.type === "Work"
                    ? ` ${classes.AddressType} ${classes.typeBorder}`
                    : `${classes.AddressType} `
                }
                onClick={() => setTypeHandler("type", "Work")}
              >
                <WorkSvg /> <span className="ms-2">Work</span>
              </div>
              <div
                className={
                  Address?.type === otherType && showOtherType === true
                    ? ` ${classes.AddressType} ${classes.typeBorder}`
                    : `${classes.AddressType} `
                }
                onClick={() => setTypeHandler("type", "Others")}
              >
                <WorkSvg /> <span className="ms-2">Others</span>
              </div>

              {showOtherType && (
                <div className="mt-2 position-relative">
                  <input
                    type="text"
                    className={`form-control ${classes.AddressInput}`}
                    placeholder="Specify the type"
                    value={Address?.type ?? otherType}
                    onChange={(e) => {
                      // console.log("Input changed:", e.target.value);
                      setOtherType(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            {validationErrors.type && (
              <div className="text-danger small">{validationErrors.type}</div>
            )}
          </div>
          <div className="mb-2 position-relative">
            <label>
              <input
                name="default"
                type="checkbox"
                checked={Address.default ?? ""}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <span className="ms-1"> Make this default address</span>
            </label>
          </div>
          {text && <h5 className="text-danger">{text}</h5>}

          <div
            className={` ${classes.saveBtnBottom} d-flex flex-column justify-content-end gap-3 align-items-center container`}
          >
            <button className={`${classes.saveBtn} mt-3`} onClick={saveHandler}>
              SAVE ADDRESS
            </button>
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default Address;
