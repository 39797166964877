import React, { useEffect } from "react";
import classes from "./Order.module.css";
import { useNavigate,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";



const OrderSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/${id}/home`);
    }, 4500);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);
  return (
    <div
      className={`${classes.OrderBackground} container-fluid`}
      onClick={() => navigate(`/${id}/home`)}
    >
      <div className={`${classes.OrderContent}`}>
        <div className="d-flex align-items-center p-0 m-0">
          <i className="bi bi-check-circle"></i>
        </div>
        <h6>Thank You!</h6>
        <p >You will receive a text on your registered mobile number regarding your order confirmation.  </p>
      </div>
    </div>
  );
};

export default OrderSuccess;
