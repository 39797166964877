import React from "react";
import classes from "./Order.module.css";
import { useNavigate,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import { useEffect } from "react";

const OrderSuccessInfo = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  return (
    <div className={` container-fluid`}>
      <div
        className={`${classes.OrderBackground} ${classes.OrderSuccessBackground} row`}
      >
        <div className={`${classes.OrderContentInfo}`}>
          <div
            className={`${classes.OrderCrossBox}`}
            onClick={() => navigate(`/${id}/home`)}
          >
            <i className="bi bi-x-circle-fill"></i>
          </div>
          <div className="d-flex align-items-center p-0 m-0">
            <i className="bi bi-check-circle"></i>
          </div>
          <div className="d-flex flex-column align-items-center">
            <h6>Order Placed</h6>
            <p>Total Price ₹110 </p>
          </div>
          <div className={`${classes.OrderCrossBox} ${classes.underLineText}`}>
            view details
          </div>
      
        </div>
      </div>
      <div className="row">
        <div className={`${classes.orderShipping} `}>
          <h6>Shipping Details</h6>
          <p>Change</p>
        </div>
        <div className={`${classes.AddressText}`}>
          Magneto <br /> Action Area II, <br />
          Newtown <br />
          KOLKATA - 70001 <br /> mobile : +91 9876543216
        </div>
        <div className={`${classes.DeliveryBox}`}>
          <i className="bi bi-truck"></i>
          <h6>Estimated delivery -</h6>
          <p>25 June 2023 </p>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessInfo;
