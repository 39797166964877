import React, { useState } from "react";
import classes from "./Wishlist.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
import Product from "../Home/Product";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWishlistHandler } from "../../Store/Product/ProductActions";
import { productActions } from "../../Store/Product/ProductSlice";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import { authActions } from "../../Store/Auth/AuthSlice";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const Wishlist = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const cartList = useSelector((state) => state.cart.cartList);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const Profile = useSelector((state) => state.auth.Profile);
  const authToken = localStorage.getItem("authToken");

  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      dispatch(
        getWishlistHandler(
          authToken,
          { appKeyToken: verfiyToken },
          Profile?.MobileNumber
        )
      );
      dispatch(productActions.setWishHandler(true));
    } else {
      navigate(`/verification`);
      dispatch(authActions.setFromPage("home"));
    }
  }, [dispatch, verfiyToken]);

  const backToHome = () => {
    dispatch(productActions.setWishHandler(false));
    navigate(`/${id}/home`);
  };

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  return (
    <>
      <div className={`container-fluid ${classes.wishlistPage}`}>
        <div className={`${classes.NavigationWishlist}`}>
          <MainHeader />
        </div>

        <div className={`${classes.WishlistBody}`}>
          <Header heading={"My Wishlist"} onClick={backToHome} />

          <div className={`${classes.wishContent}  `}>
            <Product />
          </div>
        </div>
        {cartList?.length > 0 ? (
          <Footer onClick={() => navigate(`/${id}/cart`)} />
        ) : (
          ""
        )}
      </div>
      <MainFooter />
    </>
  );
};

export default Wishlist;
