import React, { useEffect, useState } from "react";
import classes from "./Review.module.css";
import star2 from "../../Assets/Images/Star2.png";
import { ReactComponent as Dot } from "../../Assets/SvgComponents/DotSvg.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  PostLikeHandler,
  getReviewHandler,
} from "../../Store/Product/ProductActions";
import { getStoreByIdHandle } from "../../Store/StoreDetail/StoreActions";

const Review = ({ ReviewList, id }) => {
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const storeById = useSelector((state) => state.store.storeById);
  const storeId = useSelector((state) => state.store.storeId);


  const dispatch = useDispatch();


  const [likedReviews, setLikedReviews] = useState([]);
  const [dislikedReviews, setDislikedReviews] = useState([]);

  const isLiked = (review) => likedReviews.includes(review?._id);
  const isDisliked = (review) => dislikedReviews.includes(review?._id);

  const formatDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const likeHandler = (review) => {
    const isAlreadyLiked = review?.likes >= 1;

    const data = {
      productID: id,
      reviewID: review?._id,
      like: isAlreadyLiked ? 0 : 1,
    };

    dispatch(PostLikeHandler(storeById?.StoreId, data)).then((result) => {
      if (result.status === "success") {
        setLikedReviews((prevLikedReviews) => {
          if (isAlreadyLiked) {
            return prevLikedReviews.filter((id) => id !== review?._id);
          } else {
            return [...prevLikedReviews, review?._id];
          }
        });


        if (isDisliked(review)) {
          setDislikedReviews((prevDislikedReviews) =>
            prevDislikedReviews.filter((id) => id !== review?._id)
          );
        }

        dispatch(getReviewHandler(storeById?.StoreId, id));
      }
    });
  };

  const dislikeHandler = (review) => {
    const isAlreadyDisliked = review?.dislikes >= 1;

    const data = {
      productID: id,
      reviewID: review?._id,
      dislike: isAlreadyDisliked ? 0 : 1,
    };

    dispatch(PostLikeHandler(storeById?.StoreId, data)).then((result) => {
      if (result.status === "success") {
        if (isAlreadyDisliked) {
          setDislikedReviews((prevDislikedReviews) =>
            prevDislikedReviews.filter((id) => id !== review?._id)
          );
        } else {
          setDislikedReviews((prevDislikedReviews) => [...prevDislikedReviews, review?._id]);

          if (isLiked(review)) {
            setLikedReviews((prevLikedReviews) =>
              prevLikedReviews.filter((id) => id !== review?._id)
            );
          }
        }
        dispatch(getReviewHandler(storeById?.StoreId, id));
      }
    });
  };

  const approvedReviews = ReviewList?.filter((review) => review.approved === true);

  useEffect(() => {
    dispatch(getStoreByIdHandle(storeId))
  }, [storeId])


  return (
    <div className={`${classes.ReviewList}`}>
      {approvedReviews?.map((review, index) => {
        const formattedDate = formatDDMMYYYY(review.createdDate);
        return (
          <div className={`${classes.ReviewBox}`} key={index}>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="m-0">{review?.title}</h5>
              <div className="d-flex ">
                {[...Array(review.ratings)].map((_, starIndex) => (
                  <img key={starIndex} src={star2} className="ms-1" alt={`star-${starIndex + 1}`} />
                ))}
              </div>
            </div>
            <div className={`${classes.customer} `}>
              <h6 className="mb-0 me-2">{review?.customerName}</h6>
              <i className="bi bi-check-circle-fill "></i>{" "}
              <p className="m-0">Verified Purchase</p>
            </div>
            {review?.images.length > 0 && (
              <div className={`${classes.RevImage} mt-2`}>
                {review?.images?.map((img, imgIndex) => {
                  return <img src={img} key={imgIndex} alt={`review-image-${imgIndex}`} />;
                })}
              </div>
            )}
            <p className="m-0 mt-2">{review?.comment}</p>
            <div
              className={`${classes.likeBox} d-flex gap-1 align-items-center mt-2`}
            >
              <div
                onClick={() => likeHandler(review)}
              // style={{
              //   color: review?.likes===1 ? "#0d6efdf0" : "black",

              // }}
              >
                {review?.likes === 1 ? <i className="bi bi-hand-thumbs-up-fill"></i> : <i className="bi bi-hand-thumbs-up"></i>}

              </div>
              <span className="me-2">{review?.likes}</span>
              <div
                onClick={() => dislikeHandler(review)}
              // style={{
              //   color: review?.dislikes===1 ? "#dc3545db" : "black",

              // }}
              >
                {review?.dislikes === 1 ? <i className="bi bi-hand-thumbs-down-fill"></i> : <i className="bi bi-hand-thumbs-down"></i>}

              </div>{" "}
              <span className="me-1">{review?.dislikes}</span>
              <Dot /> <span className="ms-1">{formattedDate}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Review;
