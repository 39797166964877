import React, { useState, useEffect } from 'react';

function ImageMagnifier({
  src,
  width,
  height,
  magnifierHeight = 100,
  magnifieWidth = 100,
  zoomLevel = 1.5
}) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const shouldShowMagnifier = windowWidth >= 767 && showMagnifier;

  const magnifierStyles = {
    display: shouldShowMagnifier ? 'block' : 'none',
    position: 'absolute',
    pointerEvents: 'none',
    height: `${magnifierHeight}px`,
    width: `${magnifieWidth}px`,
    top: `${y - magnifierHeight / 2}px`,
    left: `${x - magnifieWidth / 2}px`,
    opacity: '1',
    border: '1px solid lightgray',
    backgroundColor: 'white',
    backgroundImage: `url('${src}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
    backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
    backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
    transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out'
  };

  return (
    <div
      style={{
        position: 'relative',
        height: height,
        width: width
      }}
    >
      <img
        src={src}
        style={{ height: height, width: width }}
        onMouseEnter={(e) => {
          if (windowWidth >= 767) {
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
          }
        }}
        onMouseMove={(e) => {
          if (windowWidth >= 767) {
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();
          const x = e.pageX - left - window.scrollX;
          const y = e.pageY - top - window.scrollY;
          setXY([x, y]);
          }
        }}
        onMouseLeave={() => {
          if (windowWidth >= 767) {
          setShowMagnifier(false);
          }
        }}
        alt={'img'}
      />

      <div style={magnifierStyles}></div>
    </div>
  );
}

export default ImageMagnifier;
