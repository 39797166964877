import React, { useEffect, useState } from "react";
import Header from "../../UI/Header/Header";
import classes from "./Review.module.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PostReviewHandler,
  getProductByIdHandler,
} from "../../Store/Product/ProductActions";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";



// img path
import noImg from "../../Assets/Images/noImage.png";
import star1 from "../../Assets/Images/Star1.png";
import star2 from "../../Assets/Images/Star2.png";
import camera from "../../Assets/Images/Camera.png";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import { getStoreByIdHandle } from "../../Store/StoreDetail/StoreActions";

const SubmitReview = () => {
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const product = useSelector((state) => state.product?.product);
  const Customer = useSelector((state) => state.auth.Customer);
  const storeById = useSelector((state) => state.store.storeById);


  const [review, setReview] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedRating, setSelectedRating] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  useEffect(() => {
    if (productId && verfiyToken) {
      dispatch(getProductByIdHandler(storeById?.StoreId, productId));
    }
  }, [dispatch, verfiyToken, productId]);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const setReviewHandler = (e) => {
    const name = e.target.name;
    if (name === "title") {
      setTitle(e.target.value);
    }
    if (name === "review") {
      setReview(e.target.value);

    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const imageUrl = reader.result;
      setUploadedImages((prevImages) => [...prevImages, imageUrl]);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = (index) => {
    setUploadedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const submitHandler = () => {
    if (selectedRating === 0 || review.trim() === "") {

      setText("Please provide both rating and review.");
      return;
    }

    dispatch(
      PostReviewHandler(storeById?.StoreId, {
        productID: product?._id,
        productName: product?.NameToDisplay,
        productImage: product?.Images?.length > 0 ? product?.Images[0] : null,
        customerID: Customer?._id,
        customerName: Customer?.Username,
        customerEmail: Customer?.Email,
        ratings: selectedRating,
        comment: review,
        images: uploadedImages,
        approved: true,
        title: title,
      })
    ).then((result) => {
      if (result.status === "success") {
        const modifiedName = product.NameToDisplay.replace(/ /g, "-")
          .replace(/'/g, "")
          .replace(/:/g, "-")
          .replace(/\//g, "-");
        const encodedName = encodeURIComponent(modifiedName);
        dispatch(getProductByIdHandler(storeById?.StoreId, product._id));
        navigate(`/${id}/productdetail/${encodedName}/${product._id}`);
      }
    });
  };

  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };


  const handleCameraUpload = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          video.srcObject = stream;
          video.play();

          video.onloadedmetadata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageUrl = canvas.toDataURL("image/png");
            setUploadedImages((prevImages) => [...prevImages, imageUrl]);
            stream.getTracks().forEach((track) => track.stop());
            document.body.appendChild(video);
            document.body.appendChild(canvas);
            document.body.removeChild(video);
            document.body.removeChild(canvas);
          };
        })
        .catch((error) => {
          console.error("Error accessing the camera:", error);
        });
    } else {
      console.error("getUserMedia is not supported on this browser");
    }
  };

  useEffect(() => {
    dispatch(getStoreByIdHandle(id))
  }, [id])

  return (
    <>
      <div className={`container-fluid ${classes.SubmitReviewPage}`}>

        <div className={`${classes.SubmitReviewNavigation}`}>
          <MainHeader />
        </div>



        <div className={`${classes.SubmitReviewWrapper}`}>

          <Header heading={"Write Review"} onClick={handleHeaderClick} />
          <div className="container-fluid">
            <div className="row">
              <div className={`col-xl-6 col-lg-6 col-md-12 col-sm-12  ${classes.Product}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <h6 className="m-0">{product?.NameToDisplay}</h6>
                    <p className="m-0">₹{product?.SaleRate}.00</p>
                    <p className="m-0">
                      {[1, 2, 3, 4, 5].map((index) => (
                        <img
                          key={index}
                          src={index <= selectedRating ? star2 : star1}
                          className={`me-1 ${classes.UploadStar}`}
                          onClick={() => setSelectedRating(index)}
                        />
                      ))}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    {product?.Images?.length > 0 && product?.Images ? (
                      <img
                        variant="top"
                        src={product?.Images[0]}
                        alt="imageNotAvailable"
                        className={`${classes.Image}`}
                      />
                    ) : (
                      <img
                        variant="top"
                        src={noImg}
                        alt="imageNotAvailable"
                        className={`${classes.Image}`}
                      />
                    )}
                  </div>
                </div>
                <div className="mb-3 mt-4  position-relative">
                  <input
                    type="text"
                    className={`form-control ${classes.AddressInput}`}
                    name="title"
                    placeholder="Enter title"
                    onChange={(e) => setReviewHandler(e)}
                  />
                </div>
                <textarea
                  className={`col-12   ${classes.walletInput}`}
                  onChange={(e) => setReviewHandler(e)}
                  rows="6"
                  name="review"
                  placeholder="Write a review ..."
                />
                {uploadedImages?.length < 3 && (
                  <div className="d-flex align-items-center mt-3 mb-1 gap-2">
                    <label className={`${classes.Camera}`} htmlFor="imageInput">
                      <img src={camera} />
                    </label>
                    <h5
                      className={`m-0 ${classes.CameraText}`}
                      htmlFor="imageInput"
                    >
                      Upload photo
                    </h5>
                    <label
                      className={`${classes.Camera} ms-3`}
                      onClick={handleCameraUpload}
                    >
                      <img src={camera} />
                    </label>
                    <h5
                      className={`m-0 ${classes.CameraText}`}
                      onClick={handleCameraUpload}
                    >
                      Capture
                    </h5>
                    <input
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      capture="environment"
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                    />
                  </div>
                )}
                {uploadedImages?.length === 3 && (
                  <p className="m-0 text-danger">*You can upload max of 3 images</p>
                )}
                {uploadedImages?.length > 0 && (
                  <div className={`${classes.ImageBox} mt-3`}>
                    {uploadedImages.map((image, index) => (
                      <div key={index} className={`${classes.ImageReview} `}>
                        <img src={image} alt={`Uploaded ${index + 1}`} />
                        <div className={`${classes.DeleteBox}`}>
                          <i
                            className="bi bi-trash"
                            onClick={() => handleImageRemove(index)}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className={`${classes.Text} mt-3`}>
                  <h6 className="m-0">
                    Upload photos related to the product like unboxing,
                    installation, product usage etc.{" "}
                  </h6>
                </div>
                {text && <h6 className="m-0 mb-1 text-danger">{text}</h6>}
                <button className={`${classes.submitBtn} mt-4`} onClick={submitHandler}>
                  Submit Review
                </button>
              </div>
            </div>
          </div>

          {/* {text && <h6 className="m-0 mb-1 text-danger">{text}</h6> }
      <button className={`${classes.submitBtn} m-0`} onClick={submitHandler}>
        Submit Review
      </button> */}
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default SubmitReview;
