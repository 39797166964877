import React from "react";
import Header from "../../UI/Header/Header";
import classes from "./Review.module.css";
import { useNavigate,useLocation,useParams } from "react-router-dom";
import star2 from "../../Assets/Images/Star2.png";
import Review from "./Review";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import { useEffect } from "react";

const ReviewMain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ReviewList = useSelector((state) => state.product?.ReviewList);
  const AllReview = useSelector((state) => state.product?.AllReview);
  const {id} = useParams()
  const dispatch = useDispatch();

  const totalRatings = AllReview ? AllReview.totalRatings : 0;

  const fiveStarPercentage =
    totalRatings > 0 ? (AllReview?.ratingsCount["5"] / totalRatings) * 100 : 0;
  const fourStarPercentage =
    totalRatings > 0 ? (AllReview?.ratingsCount["4"] / totalRatings) * 100 : 0;
  const threeStarPercentage =
    totalRatings > 0 ? (AllReview?.ratingsCount["3"] / totalRatings) * 100 : 0;
  const twoStarPercentage =
    totalRatings > 0 ? (AllReview?.ratingsCount["2"] / totalRatings) * 100 : 0;
  const oneStarPercentage =
    totalRatings > 0 ? (AllReview?.ratingsCount["1"] / totalRatings) * 100 : 0;

  // console.log(ReviewList?.customerReviews)

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={`${classes.ReviewListBg}  `}>
      <Header heading={"All Reviews"} onClick= {handleHeaderClick} />
      <div className="container-fluid">
        <div className={`row mt-5`}>
          <div className={`${classes.reviewMain} col-12 `}>
            <div className="d-flex justify-content-around w-100">
              <div className={`${classes.reviewSmallCol} `}>
                <div className="d-flex ">
                  <img src={star2} className="me-1" alt="star" />
                  <img src={star2} className="me-1" alt="star" />
                  <img src={star2} className="me-1" alt="star" />
                  <img src={star2} className="me-1" alt="star" />
                  <img src={star2} className="me-1" alt="star" />
                </div>
                <div>{AllReview?.totalRatings} ratings and  {" "}
                    {AllReview?.totalReviews}{" "} reviews</div>
              </div>
              <div className={`d-flex flex-column gap-1  ${classes.sideCol} `}>
              <div className={`${classes.reviewStars}`}>
                  5 star
                  <progress value={fiveStarPercentage} max="100">
                    {`${fiveStarPercentage.toFixed(2)}%`}
                  </progress>
                  {AllReview?.ratingsCount?.["5"]}
                </div>
                <div className={`${classes.reviewStars}`}>
                  4 star
                  <progress value={fourStarPercentage} max="100">
                    {`${fourStarPercentage.toFixed(2)}%`}
                  </progress>
                  {AllReview?.ratingsCount?.["4"]}
                </div>
                <div className={`${classes.reviewStars}`}>
                  3 star
                  <progress value={threeStarPercentage} max="100">
                    {`${threeStarPercentage.toFixed(2)}%`}
                  </progress>
                  {AllReview?.ratingsCount?.["3"]}
                </div>
                <div className={`${classes.reviewStars}`}>
                  2 star
                  <progress value={twoStarPercentage} max="100">
                    {`${twoStarPercentage.toFixed(2)}%`}
                  </progress>
                  {AllReview?.ratingsCount?.["2"]}
                </div>
                <div className={`${classes.reviewStars} ms-1`}>
                  1 star
                  <progress value={oneStarPercentage} max="100">
                    {`${oneStarPercentage.toFixed(2)}%`}
                  </progress>
                  {AllReview?.ratingsCount?.["1"]}
                </div>
              </div>
            </div>
            <div className={`${classes.ReviewLtImage} mt-4`}>
              {ReviewList?.customerReviews?.map((review, index) => {
                return (
                  review?.images.length > 0 &&
                  review?.images?.map((img, index) => {
                    return <img src={img} key={index} />;
                  })
                );
              })}
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
              <h5 className="m-0">Sort By</h5>
              <div className="dropdown">
                <button
                  className={` btn btn-secondary dropdown-toggle ${classes.sortBtn} `}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Default
                </button>
                <ul className="dropdown-menu">
                  <li>High Rating</li>
                  <li>Low Rating</li>
                  <li>latest</li>
                  <li>old</li>
                </ul>
              </div>
            </div> */}
            {ReviewList?.customerReviews?.length > 0 && (
              <Review ReviewList={ReviewList?.customerReviews}  />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewMain;
