import React from "react";
import classes from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../UI/UISlice";
import { productActions } from "../../Store/Product/ProductSlice";

//img path
import noImg from "../../Assets/Images/noImage.png";

const Brand = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const brandList = useSelector((state) => state.brand.brandList);
  const query = useSelector((state) => state.product.query);
  const productList = useSelector((state) => state.product.productList);
  const storeById = useSelector((state) => state.store.storeById);
  const storeId = useSelector((state) => state.store.storeId);
  const IsAllProduct = localStorage.getItem("IsAllProduct");

  const searchProduct = (Brand) => {
    navigate(`/${storeId}/searchproduct/${Brand._id}`);
    dispatch(uiActions.setHomeSearch({ name: "setState", value: "brand" }));
    let tempQuery = structuredClone(query);
    tempQuery.IsAllProduct = IsAllProduct;
    tempQuery.Brand = Brand._id;
    dispatch(productActions.setQueryHandler(tempQuery));
    dispatch(uiActions.setHomeSearch({ name: "brandName", value: Brand.name }));
  };

  const withinPromotionTime = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const fromTimeParts = fromTime && fromTime.split(":");
    const toTimeParts = toTime && toTime.split(":");
    
    const fromHours = fromTimeParts && parseInt(fromTimeParts[0], 10);
    const fromMinutes = fromTimeParts && parseInt(fromTimeParts[1], 10);
    
    const toHours = toTimeParts && parseInt(toTimeParts[0], 10);
    const toMinutes = toTimeParts && parseInt(toTimeParts[1], 10);


    if (toHours > fromHours || (toHours === fromHours && toMinutes >= fromMinutes)) {
        return (currentHours > fromHours || (currentHours === fromHours && currentMinutes >= fromMinutes))
            && (currentHours < toHours || (currentHours === toHours && currentMinutes <= toMinutes));
    } else {
        return (currentHours > fromHours || (currentHours === fromHours && currentMinutes >= fromMinutes))
            || (currentHours < toHours || (currentHours === toHours && currentMinutes <= toMinutes));
    }


};

  const sortedProductList = [...productList].sort((a, b) => {
    const aHasPromotion =
      a.appliedPromotions?.length > 0 ||
      a.appliedPromotionOnProductCategoryVise?.length > 0 ||
      a.appliedPromotionOnProductBrandVise?.length > 0;
    const bHasPromotion =
      b.appliedPromotions?.length > 0 ||
      b.appliedPromotionOnProductCategoryVise?.length > 0 ||
      b.appliedPromotionOnProductBrandVise?.length > 0;

    if (aHasPromotion && bHasPromotion) {
      const aPromotionRate =
        a.appliedPromotions[a?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        a.appliedPromotionOnProductCategoryVise[
          a?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        a.appliedPromotionOnProductBrandVise[
          a?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      const bPromotionRate =
        b.appliedPromotions[b?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        b.appliedPromotionOnProductCategoryVise[
          b?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        b.appliedPromotionOnProductBrandVise[
          b?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      return bPromotionRate - aPromotionRate;
    }

    return bHasPromotion - aHasPromotion;
  });

  const productsWithPromotion = sortedProductList.filter(
    (product) =>
      (product.appliedPromotions?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionFromTime,
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionToTime
        ) &&
        (!product?.appliedPromotions[product?.appliedPromotions.length - 1]
          ?.FKLocationID ||
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductCategoryVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionFromTime,
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductCategoryVise[
          product?.appliedPromotionOnProductCategoryVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductBrandVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionFromTime,
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductBrandVise[
          product?.appliedPromotionOnProductBrandVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.FKLocationID === storeById?.LocationId))
  );

  const getProductMaxPromotion = (products) => {
    if (products.length === 0) {
      return 0;
    }

    return products.reduce((maxPromotion, product) => {
      const appliedPromotion =
        product.appliedPromotions.length > 0
          ? product.appliedPromotions[product.appliedPromotions.length - 1]
              ?.ProductPromotionPercentageRate || 0
          : 0;

      const categoryPromotion =
        product.appliedPromotionOnProductCategoryVise.length > 0
          ? product.appliedPromotionOnProductCategoryVise[
              product.appliedPromotionOnProductCategoryVise.length - 1
            ]?.ProductPromotionCategoryPercentageRate || 0
          : 0;

      const brandPromotion =
        product.appliedPromotionOnProductBrandVise.length > 0
          ? product.appliedPromotionOnProductBrandVise[
              product.appliedPromotionOnProductBrandVise.length - 1
            ]?.ProductPromotionBrandPercentageRate || 0
          : 0;

      const productPromotion = appliedPromotion > 0 && categoryPromotion > 0
        ? appliedPromotion
        : Math.max(appliedPromotion, categoryPromotion, brandPromotion);

      return Math.max(maxPromotion, productPromotion);
    }, 0);
  };

  return (
    <div className={`${classes.brandWrapper}  container-fluid `}>
      {brandList?.length > 0 ? (
        <>
          <div className="row">
            <div className="col-sm-12 col-xs-12 ">
              <div className={`${classes.headline}  overflow-hidden `}>
                <h6 className="pb-1 position-relative d-inline-block">
                  Brand
                </h6>
              </div>
            </div>
          </div>
          <div className={`${classes.BrandSlide}`}>
            {brandList?.map((brand, index) => {
              const categoryProducts = productsWithPromotion?.filter((product) =>
                product.Brand?.includes(brand._id)
              );
              const maxPromotion = getProductMaxPromotion(categoryProducts);
              return (
                <div
                  className={`${classes.BrandCardMain}`}
                  onClick={() => searchProduct(brand)}
                  key={index}
                >
                  <div className={`${classes.brandCard}  d-block text-center `}>
                    {brand?.image ? (
                      <img
                        variant="top"
                        src={brand?.image}
                        alt="imageNotAvailable"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        variant="top"
                        src={noImg}
                        alt="imageNotAvailable"
                        className="img-fluid"
                      />
                    )}

                    {maxPromotion > 0 && (
                      <div className={`${classes.discountBanner}`}>
                        <p className={`${classes.discountText}`}>
                          Up to {maxPromotion}% off
                        </p>
                      </div>
                    )}
                  </div>
                  <p className={`${classes.disText} m-0`}>{brand?.name}</p>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Brand;
