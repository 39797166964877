import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./Home.module.css";
import { productActions } from "../../Store/Product/ProductSlice";
import { uiActions } from "../UI/UISlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ProfileSvg } from "../../Assets/SvgComponents/ProfileSvg.svg";
import { ReactComponent as SearchSvg } from "../../Assets/SvgComponents/SearchSvg.svg";
import { ReactComponent as NotificationSvg } from "../../Assets/SvgComponents/NotificationSvg.svg";
import { ReactComponent as WishlistSvg } from "../../Assets/SvgComponents/WishlistSvg.svg";
import Swilmart from "../../Assets/Images/swilmart.png";
import { cartActions } from "../../Store/Cart/CartSlice";
import { authActions } from "../../Store/Auth/AuthSlice";

const MainHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showItem, setShowItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const query = useSelector((state) => state.product.query);
  const AddressKeeper = useSelector((state) => state.address.AddressKeeper);
  const authToken = localStorage.getItem("authToken");
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const cartPrice = useSelector((state) => state.cart.cartPrice);
  const cartList = useSelector((state) => state.cart.cartList);
  const cartData = useSelector((state) => state.cart.cartData);
  const searchRef = useRef();

  const storeId = useSelector((state) => state.store.storeId);

  // Sticky Menu Area
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const searchProduct = (e) => {
    navigate(`/${storeId}/searchproduct`);
    dispatch(uiActions.setHomeSearch({ name: "setState", value: "search" }));
    let tempQuery = structuredClone(query);
    tempQuery.search = e.target.value;
    dispatch(productActions.setQueryHandler(tempQuery));
  };

  const loggedInHandler = () => {
    if (authToken) {
      navigate(`/${storeId}/profile`);
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("home"));
    }
  };

  const goToWishlist = () => {
    navigate(`/${storeId}/Wishlist`);
  };

  const goToAddress = () => {
    navigate("/verification");
    dispatch(authActions.setFromPage("home"));
  };

  const calculateTotalPrice = (cartData) => {
    const total =
      cartData?.length > 0 &&
      cartData?.reduce((acc, item) => {
        return acc + item.Product_Price * item.Product_Quantity;
      }, 0)|| 0;
      return parseFloat(total.toFixed(2));
  };

  const backToHome = () => {
    const tempQuery = structuredClone(query);
    tempQuery.categories = "";
    tempQuery.Brand = "";
    tempQuery.search = "";
    dispatch(productActions.setQueryHandler(tempQuery));
    navigate(`/${storeId}/home`);
  };

  useEffect(() => {
    if (authToken) {
      const newTotalPrice = calculateTotalPrice(cartList);
      dispatch(cartActions.calculateTotalPriceHandler(newTotalPrice));
    }
  }, [cartList, authToken, dispatch]);

  useEffect(() => {
    dispatch(cartActions.calculateTotalLocalPriceHandler(cartData));
  }, []);

  // useEffect(() => {
  //   if (searchRef.current) {
  //     searchRef.current.focus();
  //   }
  // }, []);

  useEffect(() => {
    if (searchRef.current && query.search !== "") {
      searchRef.current.focus();
    }
  }, [query.search]);

  return (
    <section id="header " className={`${classes.NavigationBar}`}>
      <nav
        className={`${classes.navbarPosition} navbar navbar-light fixed-top ${
          isSticky ? classes["is-sticky"] : ""
        }`}
      >
        <div className={`${classes.navbarContentSet} container-fluid `}>
          <div className={`${classes.logoMart}`} onClick={backToHome}>
            <img src={Swilmart} alt="Logo" className="swilmart-image" />
          </div>
          <div className={`${classes.logoWithAdress}`}>
            {authToken ? (
              <>
                {AddressKeeper?.length > 0 ? (
                  <>
                    {AddressKeeper?.filter(
                      (address) => address.default === true
                    )?.map((address, index) => {
                      return (
                        <div
                          className={`d-flex ${classes.adressHome}`}
                          key={index}
                          onClick={() => navigate(`/${storeId}/manageaddress`)}
                        >
                          <div>
                            {address.type === "Home" ? (
                              <i className="bi bi-house-fill"></i>
                            ) : (
                              <i className="bi bi-briefcase-fill"></i>
                            )}
                          </div>
                          <div>
                            <div className="d-flex gap-2 align-items-center">
                              <h5>
                                {address.type !== "Home" &&
                                address.type !== "Work"
                                  ? "Others"
                                  : address.type}
                              </h5>{" "}
                              <i
                                className={`bi bi-chevron-down ${classes.doIco}`}
                              ></i>
                            </div>
                            <p>{address.addressLine1}...</p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div
                      className={`${classes.adressHome}`}
                      onClick={() => navigate(`/${storeId}/address`)}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <i
                          className="bi bi-geo-alt-fill"
                          style={{ fontSize: "25px", display: "inline" }}
                        ></i>

                        <i
                          className={`bi bi-chevron-down ${classes.doIco}`}
                        ></i>
                      </div>
                      <p>Add your Address here...</p>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className={`${classes.adressHome}`} onClick={goToAddress}>
                <div className="d-flex gap-2 align-items-center">
                  <i
                    className="bi bi-geo-alt-fill"
                    style={{ fontSize: "25px", display: "inline" }}
                  ></i>

                  <i className={`bi bi-chevron-down ${classes.doIco}`}></i>
                </div>
                <p>Add your Address here...</p>
              </div>
            )}
            <form className="d-flex" style={{ paddingTop: "3px" }}>
              <div className="input-group">
                <span
                  className={`${classes.searchField}  input-group-text`}
                  id="basic-addon1"
                >
                  <SearchSvg />
                </span>
                <input
                  type="search"
                  className={`${classes.searchField} ${classes.searchFieldLeft} form-control `}
                  id="exampleInputEmail1"
                  aria-label="Search"
                  placeholder="Search for Products"
                  onChange={searchProduct}
                  value={query.search}
                  ref={searchRef}
                />
              </div>
            </form>
          </div>

          <div className={`${classes.profileSet} `}>
            <div
              className={`${classes.headerCartMain} `}
              onClick={() => navigate(`/${storeId}/cart`)}
            >
              <div
                className={`${classes.ProductHeaderIconBox} ${classes.ProductHeaderIconBoxCart}`}
                onClick={() => navigate(`/${storeId}/cart`)}
              >
                <i className="bi bi-cart-plus-fill"></i>
                <div className={`${classes.ProductHeaderIconBoxNumber} `}>
                  {authToken ? cartList?.length : cartData?.length}
                </div>
              </div>
              <div className={`${classes.headerCartText} `}>
                <span>Shopping Cart</span>
                <span>
                  ₹{authToken ? (totalPrice ? totalPrice : 0) : cartPrice}
                </span>
              </div>
            </div>

            <div className={`${classes.wishlist}`} onClick={goToWishlist}>
              <WishlistSvg />
            </div>
            {/* <div onClick={() => navigate("/notification")}>
              {" "}
              <NotificationSvg />
            </div> */}
            <div
              className={`${classes.profileHeader}`}
              onClick={loggedInHandler}
            >
              {" "}
              <ProfileSvg />
              {!authToken && (
                <div className={`${classes.loginText}`}>
                  <span>Log in</span>
                  <br />
                  <span>My Account</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default MainHeader;
