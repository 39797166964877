import RouteHandler from "./Components/Router/RouteHandler";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, BrowserRouter } from "react-router-dom";
import { cartActions } from "./Components/Store/Cart/CartSlice";
import { useEffect, useRef, useState } from "react";
import { getCartHandler } from "./Components/Store/Cart/CartActions";
import { getProductHandler } from "./Components/Store/Product/ProductActions";
import { Toaster } from "react-hot-toast";
import BackToTop from "./Components/UI/BackToTop/BackToTop";

function App() {
  const dispatch = useDispatch();
  const Profile = useSelector((state) => state.auth.Profile);
  const cartList = useSelector((state) => state.cart.cartList);
  const authToken = localStorage.getItem("authToken");
  const IsAllProduct = localStorage.getItem("IsAllProduct");
  const query = useSelector((state) => state.product.query);
  const storeById = useSelector((state) => state.store.storeById);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const storeId = useSelector((state) => state.store.storeId);

  const [showButton, setShowButton] = useState(false);
  const topRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollUp = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (authToken) {
      const data = {
        User_Numberid: Profile?.MobileNumber,
      };
      if (cartList?.length > 0) {
        dispatch(getCartHandler(verfiyToken, authToken, data?.User_Numberid));
      }
    }
  }, []);

  useEffect(() => {
    if (storeId) {
      let tempQuery = structuredClone(query);
      tempQuery.IsAllProduct = IsAllProduct;
      // console.log(tempQuery);
      
        dispatch(getProductHandler(storeById?.StoreId, tempQuery))
      
    }
  }, [dispatch, query]);

  return (
    <BrowserRouter>
      <div ref={topRef}> </div>
      <RouteHandler />
      <Toaster />

      {showButton && <BackToTop handleScrollUp={handleScrollUp} />}
    </BrowserRouter>
  );
}

export default App;
