import { categoryActions } from "./CategorySlice";
import { NodeInstance, ProductInstance } from "../../Router/AxiosInstance";


export const getCategoryHandler = (id) => {
  return async (dispatch) => {

    let result = {
      status: '',
      msg: ''
    }

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/customer/category?StoreId=${id}`,
      headers: {
       // 'Authorization': 'Bearer ' + verfiyToken 
       "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,
      },
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === 'success') {
          result.status = 'success'
          // console.log(response.data.data)
          dispatch(categoryActions.setCategoryListHandler(response.data.data))
        }
        else{
          dispatch(categoryActions.setCategoryListHandler([]))

        }

      })
      .catch(function (error) {
        console.log(error);
        dispatch(categoryActions.setCategoryListHandler([]))

      });
    return result;
  };
}








