import React, { useEffect, useState } from "react";
import classes from "./Profile.module.css";
import Header from "../../UI/Header/Header";
import { useNavigate,useParams } from "react-router-dom";
import { authActions } from "../../Store/Auth/AuthSlice";
import {
  LoggedInHandler,
  createProfileHandle,
  getCustomerByMobile,
  getProfileHandle,
  updateProfileHandle,
} from "../../Store/Auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import user from "../../Assets/Images/user.png";
import { ReactComponent as EditSvg } from "../../Assets/SvgComponents/EditSvg.svg";
import toast from "react-hot-toast";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const EditProfile = () => {
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);

  const authToken = localStorage.getItem("authToken");

  const Profile = useSelector((state) => state.auth.Profile);
  const fromPage = useSelector((state) => state.auth.fromPage);
  const [emailError, setEmailError] = useState("");

  // console.log(Profile)
  const [mobile, setMobile] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const setValueHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === "Email") {
      // Email validation
      const isValidEmail =
        /^[\w.-]+@(?:[\w-]+\.)+(com|org|net)$/.test(value) ||
        /^\w+(?:\.\w+)?@(?:[\w-]+\.)+something\.org$/.test(value) ||
        /^[\w.-]+@(?:[\w-]+\.)+something\.net$/.test(value);
      setEmailError(
        isValidEmail || value === "" ? "" : "Please enter a valid email address"
      );
    }

    dispatch(authActions.setProfileFieldHandler({ name: name, value: value }));
  };

  const submit = () => {
    if (!emailError) {
      dispatch(updateProfileHandle(verfiyToken, Profile, Profile._id)).then(
        (result) => {
          if (result.status === "success") {
            navigate(`/${id}/home`);
            dispatch(getCustomerByMobile(verfiyToken, Profile?.MobileNumber));
            toast.success("Profile Updated", {
              style: {
                border: "1px solid #006d5b",
                padding: "8px 14px",
                background: "#006d5b",
                color: "#fff",
              },
              iconTheme: {
                primary: "#006d5b",
                secondary: "#fff",
              },
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getProfileHandle(verfiyToken, data.User_Numberid));
          setMobile(result.data.mobile);
        }
      });
    }
  }, [dispatch, authToken]);

  return (
    <div className="container-fluid">
      <Header onClick={() => navigate(`/${id}/profile`)} classInfo={true} />

      <div className={`${classes.categoryImage} position-relative pt-1`}>
        <img src={user} alt="+category" className="img-fluid" />
      </div>
      <div className={`mx-auto  ${classes.EditProfileWrapper}`}>
        <div className="mb-3 mt-4  position-relative">
          <label className="form-label ">FULL NAME</label>
          <input
            type="text"
            className={`form-control ${classes.ProfileInput}`}
            name="Username"
            value={Profile?.Username}
            placeholder="Enter your name"
            onChange={(e) => setValueHandler(e)}
          />
        </div>
        <div className="mb-3 position-relative">
          <label className="form-label">NUMBER</label>
          <input
            type="number"
            className={`form-control ${classes.ProfileInput}`}
            name="Mobile"
            value={mobile || Profile?.MobileNumber}
            disabled={true}
            placeholder="Enter number"
            onChange={(e) => setValueHandler(e)}
          />
        </div>
        <div className="mb-3 position-relative">
          <label className="form-label">EMAIL ID</label>
          <input
            type="email"
            className={`form-control ${classes.ProfileInput}`}
            name="Email"
            value={Profile?.Email}
            placeholder="Enter email"
            onChange={(e) => setValueHandler(e)}
          />
          {emailError && <div className="text-danger">{emailError}</div>}
        </div>

        <button className={`${classes.submitBtn} mt-5`} onClick={submit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
