import React, { useState } from "react";
import classes from "./Profile.module.css";
import { useNavigate, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../Store/Auth/AuthSlice";
import { sendOtpHandle } from "../../Store/Auth/AuthActions";
import toast, { Toaster } from 'react-hot-toast';

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState("");
  const phone = useSelector((state) => state.auth.Phone);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const storeId = useSelector((state) => state.store.storeId);

  const handleChange = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
    dispatch(authActions.setPhoneHandler(value));
  };
  

  const sendOtp = () => {
    const data = {
      mobile: phone
    }
    dispatch(sendOtpHandle(verfiyToken,data)).then((result) => {
      if (result.status === "success") {
        navigate("/verifyOtp")
        toast.success("Otp Sent", {
          style: {
            border: "1px solid #006d5b",
            padding: "8px 14px",
            background: "#006d5b",
            color: "#fff",
          },
          iconTheme: {
            primary: "#006d5b",
            secondary: "#fff",
          },
        });
      }
    });
   
  }


  const isButtonDisable = phoneNumber?.length === 10;

  return (
    <div className="container-fluid">
        
      <div className={`${classes.VerificationPage}`}>
        <div
          className={`${classes.VerificationPageCross}`}
          onClick={() => navigate(`/${storeId}/home`)}
        >
          <span>
          <i className="bi bi-x"></i></span>
        </div>
        <div className={`${classes.VerificationHead}`}>
          <h2>Phone Number Verification</h2>
        </div>
        <div className={`${classes.VerificationBody}`}>
          <div className={`${classes.VerificationBodyText}`}>
            <div>
              <div>Enter your phone number to</div>
              <div>Login/Sign up</div>
            </div>
          </div>
          <div>
            <div className={`${classes.VerificationBodyInput}`}>
              <div className={`${classes.VerificationBodyInputSet}`}>
                <i className="bi bi-phone"></i>
                <div>{"+91 -"}</div>
              </div>
              <input
                // type="tel"
                type="text"
                maxLength="10"
                value={phoneNumber}
                onChange={handleChange}
                
              />
            </div>
            <button
              disabled={!isButtonDisable}
              className={
                isButtonDisable
                  ? `${classes.VerificationBodyInputButton}`
                  : `${classes.VerificationBodyInputButton} ${classes.disableBtn}`
              }
              onClick={sendOtp}
            >
              Next
            </button>
          </div>
          <div className={`${classes.VerificationBodyBottomContent}`}>
            <div>By continuing, you agree to our</div>
            <div className={`${classes.VerificationBodyBottomLink}`}>
              <a
                target="_blank"
                className={`${classes.VerificationBodyBottomLinks}`}
              >
                Terms of service
              </a>
              <a
                target="_blank"
                className={`${classes.VerificationBodyBottomLinks}`}
              >
                Privacy policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
