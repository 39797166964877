import React, { useState } from "react";
import classes from "./Payment.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addressActions } from "../../Store/Address/AddressSlice";
import { GetAddressHandler } from "../../Store/Address/AddressActions";
import { useEffect } from "react";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
import {
  getCustomerApprovalHandler,
  getStoreApprovalHandler,
} from "../../Store/Order/OrderActions";
import { orderActions } from "../../Store/Order/OrderSlice";
import { getCartHandler } from "../../Store/Cart/CartActions";
import noImg from "../../Assets/Images/noImage.png";

const ProductCheckout = ({ product, orderById }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams()

  const [selectedOption, setSelectedOption] = useState("");

  const AddressKeeper = useSelector((state) => state.address.AddressKeeper);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const storeById = useSelector((state) => state.store.storeById);
  const orderApproval = useSelector((state) => state.order.orderApproval);
  const cartList = useSelector((state) => state.cart.cartList);
  const productList = useSelector((state) => state.product.productList);

  const authToken = localStorage.getItem("authToken");

  const defaultAddress = AddressKeeper.filter(
    (address) => address.default === true
  )[0];

  const AddNewAddress = () => {
    dispatch(addressActions.setAddressFormatter());
    navigate(`/${id}/address`);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
          dispatch(GetAddressHandler(authToken, result.data.id));
        }
      });
    }
  }, [dispatch, verfiyToken, authToken]);

  // const orderHandle = () => {
  //   dispatch(getStoreApprovalHandler(verfiyToken)).then((result) => {
  //     if (result.data.CustomerApproval === true) {
  //       dispatch(orderActions.setCurrentStepHandler(3));
  //     } else {
  //       alert("Please wait for the admin approval ");
  //     }
  //   });
  // };

  // useEffect(() => {
  //   dispatch(
  //     getCustomerApprovalHandler(
  //       verfiyToken,
  //       storeById?.LocationId,
  //       defaultAddress?.mobileNumber
  //     )
  //   );
  // }, [dispatch]);

  return (
    <>
      <div className="container-fluid">
        <div className={`${classes.address} ${classes.proHeight} row`}>
          {product ? (
            <div className={`${classes.CancelOrder} col-12 mt-2`}>
              <div className={`${classes.Product} ${classes.productCont}`}>
                <h6> {product.NameToDisplay}</h6>
                <p>
                  Qty : <span className="fw-bold">{product?.Qty}</span>{" "}
                </p>

                <div>
                  <span className={`${classes.Rupee} ms-1`}>₹ </span>
                  <span className={`${classes.Amount}`}>
                    {product.SaleRate}
                  </span>{" "}
                  {/* <del className={`${classes.PreviousAmount} ms-2`}>
                    {product.MRP * 2}
                  </del> */}
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                {product?.Images?.length > 0 ? (
                  <img  
                  src={product?.Images[0]}
                    className={`${classes.OrderImage}`}
                  />
                ) : (
                  <img src={noImg} className={`${classes.OrderImage}`} />
                )}
              </div>
            </div>
          ) : orderById ? (
            orderById?.ProdDetail?.map((order, index) => {
              const correspondingProduct = productList.find(
                (item) => item.PKID === order.FKProdID
              );
              return (
                <div
                  className={`${classes.CancelOrder} col-12 mt-2`}
                  key={index}
                >
                  <div className={`${classes.Product} ${classes.productCont}`}>
                    <h6> {correspondingProduct?.NameToDisplay}</h6>
                    <p>
                      Qty : <span className="fw-bold">{order?.Qty}</span>{" "}
                    </p>

                    <div>
                      <span className={`${classes.Rupee} ms-1`}>₹ </span>
                      <span className={`${classes.Amount}`}>
                        {order.MRP}
                      </span>{" "}
                      {/* <del className={`${classes.PreviousAmount} ms-2`}>
                        {order.MRP * 2}
                      </del> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    {correspondingProduct?.Images?.length > 0 &&
                    correspondingProduct?.Images ? (
                      <img
                        variant="top"
                        src={correspondingProduct?.Images[0]}
                        className={`${classes.OrderImage}`}
                      />
                    ) : (
                      <img src={noImg} className={`${classes.OrderImage}`} />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            cartList?.map((order, index) => {
              const correspondingProduct = productList.find(
                (item) => item.PKID === order.FKProdID
              );
              return (
                <div
                  className={`${classes.CancelOrder} col-12 mt-2`}
                  key={index}
                >
                  <div className={`${classes.Product} ${classes.productCont}`}>
                    <h6> {order.Product_name}</h6>
                    <p>
                      Qty :{" "}
                      <span className="fw-bold">{order?.Product_Quantity}</span>{" "}
                    </p>

                    <div>
                      <span className={`${classes.Rupee} ms-1`}>₹ </span>
                      <span className={`${classes.Amount}`}>
                        {order.Product_Price}
                      </span>{" "}
                      {/* <del className={`${classes.PreviousAmount} ms-2`}>
                        {order.Product_Price * 2}
                      </del> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    {correspondingProduct?.Images?.length > 0 &&
                    correspondingProduct?.Images ? (
                      <img
                        variant="top"
                        src={correspondingProduct?.Images[0]}
                        className={`${classes.OrderImage}`}
                      />
                    ) : (
                      <img src={noImg} className={`${classes.OrderImage}`} />
                    )}
                  </div>
                </div>
              );
            })
          )}

          <div className="col-12 mt-3">
            <div className={`${classes.addressHead} `}>
              <p>Add Your Address Here</p>
              <p
                className={`${classes.addressAddTxt} `}
                onClick={AddNewAddress}
              >
                Add Address{" "}
              </p>
            </div>
            <div className="d-flex flex-column gap-4">
              {AddressKeeper?.filter((address) => address.default === true).map(
                (address, index) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center "
                      key={index}
                    >
                      <div
                        className={`d-flex align-items-center gap-2 ${classes.iconType}`}
                      >
                        {address.type === "Home" ? (
                          <i className="bi bi-house-fill"></i>
                        ) : (
                          <i className="bi bi-briefcase-fill"></i>
                        )}
                        <div
                          className={`${classes.AddMain} d-flex flex-column`}
                        >
                          <p> {address.name}</p>
                          <p> {address.addressLine1}</p>
                          <h6>
                            {address.city}, {address.state}
                          </h6>
                        </div>
                      </div>
                      <button
                        className={`${classes.AddresschangeBtn}`}
                        onClick={() =>
                          dispatch(orderActions.setCurrentStepHandler(1))
                        }
                      >
                        Change
                      </button>
                    </div>
                  );
                }
              )}
            </div>
            <div className={`${classes.delvText} mt-4`}>
              <h6>Delivery</h6>
              <p>Expect Delivery in 5-6 Working Days</p>
            </div>
          </div>
          <div className=" mt-4 p-0">
            <div className={`${classes.addressHead} ${classes.PriceBorder} `}>
              <p>PRICE DETAILS</p>
            </div>
            <div className="container-fluid ">
              <div className={`${classes.PaymentPricing}`}>
                <p>Amount</p>
                {/* <p>₹{orderById?orderById?.total_amount:product?product?.MRP:totalPrice}</p> */}
                <p>
                  ₹
                  {product
                    ? product?.SaleRate
                    : orderById
                    ? orderById?.total_amount
                    : totalPrice}
                </p>
              </div>
              <div className={`${classes.PaymentPricing}`}>
                <p>Delivery Charges</p>
                <p className={`${classes.freeText}`}>FREE</p>
              </div>
            </div>
            <div className={`${classes.PriceBorder}`}></div>
            <div className="container-fluid ">
              <div className={`${classes.PaymentTotal}`}>
                <h6>Total Items</h6>
                <h6>
                  {product
                    ? 1
                    : orderById
                    ? orderById?.ProdDetail?.length
                    : cartList?.length}
                </h6>
              </div>
              <div className={`${classes.PaymentTotal}`}>
                <h6>Total Payable</h6>
                <h6>
                  ₹
                  {product
                    ? product?.SaleRate
                    : orderById
                    ? orderById?.total_amount
                    : totalPrice}
                </h6>
              </div>
            </div>
          </div>
          <div
            className={`${classes.footer} d-flex justify-content-between align-items-center container`}
          >
            <div className="d-flex flex-column mt-3">
              <div className={`${classes.CartPay}`}>
                <span className={`${classes.previousCartAmount} `}>
                  {/* <del>
                    ₹
                    {(product
                      ? product?.SaleRate
                      : orderById
                      ? orderById?.total_amount
                      : totalPrice) * 2}
                  </del> */}
                  To Pay
                </span>
              </div>
              <p className={`${classes.amount} `}>
                ₹
                {product
                  ? product?.SaleRate
                  : orderById
                  ? orderById?.total_amount
                  : totalPrice}
                {/* <span className={`${classes.paymentDetailText} ms-1`}>
                  Details
                </span>{" "} */}
              </p>
            </div>
            <div className="d-flex gap-3">
              <button
                type="button"
                className={`${classes.applyBtn} ${classes.Back} btn btn-primary`}
                onClick={() => dispatch(orderActions.setCurrentStepHandler(1))}
              >
                Back
              </button>
              <button
                type="button"
                className={` ${classes.applyBtn} btn btn-primary`}
                onClick={() => dispatch(orderActions.setCurrentStepHandler(3))}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCheckout;
