import React, { useState,useEffect } from "react";
import Header from "../../UI/Header/Header";
import classes from "./Order.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OrderCancelHandle } from "../../Store/Order/OrderActions";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const CancelOrder = () => {
  const [selectedReason, setSelectedReason] = useState("");
  const [cancelOrderReasonDescription, setCancelOrderReasonDescription] =
    useState("");
  const Customer = useSelector((state) => state.auth.Customer);
  const [msg, setMsg] = useState("");

  const verfiyToken = useSelector((state) => state.auth.verfiyToken);

  const authToken = localStorage.getItem("authToken");

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
  };
  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  const handleDescriptionChange = (event) => {
    setCancelOrderReasonDescription(event.target.value);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const {OrderID} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const CancelOrder = () => {
    if (
      selectedReason === "Other" &&
      cancelOrderReasonDescription.trim() === ""
    ) {
      setMsg("Please enter a description for the selected reason.");
      return;
    }


    const data = {
      CustomerID: Customer?._id,
      OrderID: OrderID,
      cancelOrderReason: selectedReason,
      cancelOrderReasonDescription: cancelOrderReasonDescription,
      appKeyToken: verfiyToken,
    };
  

    dispatch(OrderCancelHandle(authToken, data)).then((result) => {
      navigate("/MyOrder");
    });
  };

  return (
    <>
      <div className={`container-fluid ${classes.CancelOrderPage}`}>
        <div className={`${classes.CancelOrderNavigation}`}>
          <MainHeader />
        </div>

        <div className={`${classes.CancelOrderWrapper}`}>
          <Header heading={"Cancel Order"} onClick={handleHeaderClick} />
          <div className="row">
            <div className="d-flex flex-column gap-3 col-12">
              <div className={`${classes.cancelText}`}>
                <h6>Reason for Cancellation</h6>
              </div>

              <div className="d-flex align-items-center gap-2">
                <input
                  type="radio"
                  name="selectedReasons"
                  value="I want to change address for the order"
                  checked={
                    selectedReason === "I want to change address for the order"
                  }
                  onChange={handleChange}
                />
                <h6 className="mb-0">I want to change address for the order</h6>
              </div>
              <div className="d-flex align-items-center gap-2">
                <input
                  type="radio"
                  name="selectedReasons"
                  value="Price for the product has decreased"
                  checked={
                    selectedReason === "Price for the product has decreased"
                  }
                  onChange={handleChange}
                />
                <h6 className="mb-0">Price for the product has decreased</h6>
              </div>
              <div className="d-flex align-items-center gap-2">
                <input
                  type="radio"
                  name="selectedReasons"
                  value="I have purchased the product elsewhere"
                  checked={
                    selectedReason === "I have purchased the product elsewhere"
                  }
                  onChange={handleChange}
                />
                <h6 className="mb-0">I have purchased the product elsewhere</h6>
              </div>
              <div className="d-flex align-items-center gap-2">
                <input
                  type="radio"
                  name="selectedReasons"
                  value="Other"
                  checked={selectedReason === "Other"}
                  onChange={handleChange}
                />
                <h6 className="mb-0">Other</h6>
              </div>
              <div className="mt-4">
                <textarea
                  className={`${classes.inputComment} form-control`}
                  rows="5"
                  name="description"
                  onChange={handleDescriptionChange}
                  placeholder={
                    selectedReason === "Other"
                      ? "Enter your comment (Required)"
                      : "Enter your comment (optional)"
                  }
                />
              </div>
              <div className={`${classes.cancelOrderButton}`}>
                <button onClick={CancelOrder}>Submit Review</button>
              </div>
              {msg && <p className="text-danger">{msg}</p>}
            </div>
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default CancelOrder;
