import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const productSlice = createSlice({
  name: "product",
  initialState: {
    product: {},
    productList: [],
    query: {
      page: 1,
      limit: "",
      Brand: "",
      categories: "",
      minMRP: 0,
      maxMRP: 99999999999,
      sortBy: "MRPAsc",
      search: "",
      // minStock: 0,
      // maxStock: 999999,
      locationId: "",
      IsAllProduct: null,
    },
    wishlist: [],
    wish: "",
    promoInvoice: [],
    promoProductList:[],
    ReviewList:[],
    AllReview:[],
    unmatchedCategory:""

  },
  reducers: {
    setProductListHandler(state, action) {
      state.productList = action.payload;
    },
    setProductHandler(state, action) {
      state.product = action.payload;
    },
    setQueryHandler(state, action) {
      state.query = action.payload;
    },
    setWishlistHandler(state, action) {
      state.wishlist = action.payload;
    },
    setWishHandler(state, action) {
      state.wish = action.payload;
    },
    setPromoInvoiceHandler(state, action) {
      state.promoInvoice = action.payload;
    },
    setPromoProductHandler(state,action){
      state.promoProductList = action.payload;
    },
    setReviewHandler(state,action){
      state.ReviewList = action.payload
    },
    setAllReviewHandler(state,action){
      state.AllReview = action.payload
    },
    setUnMatchedCat(state,action){
state.unmatchedCategory = action.payload
    }

  },
});

export const productActions = productSlice.actions;

export default productSlice;
