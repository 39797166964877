import React, { useState } from "react";
import "./order.css";
import Payment from "../Payments/Payment";
import ProductCheckout from "../Payments/ProductCheckout";
import ManageAddress from "../Address/ManageAddress";
import { orderActions } from "../../Store/Order/OrderSlice";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getProductByIdHandler } from "../../Store/Product/ProductActions";
import { getOrderByIdHandler } from "../../Store/Order/OrderActions";
import MainFooter from "../../UI/Footer/MainFooter";
import MainHeader from "../Home/MainHeader";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const OrderSteps = () => {
  const currentStep = useSelector((state) => state.order.currentStep);
  const product = useSelector((state) => state.product.product);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const orderById = useSelector((state) => state.order.orderById);
  const productList = useSelector((state) => state.product.productList);
  const AddressKeeper = useSelector((state) => state.address.AddressKeeper);

  const [showProduct, setShowProduct] = useState(false);
  const [text, setText] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { orderId } = useParams();
  const { id } = useParams();

  const handleStepChange = (step) => {
    dispatch(orderActions.setCurrentStepHandler(step));
  };
  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);
  useEffect(() => {
    if (orderId) {
      const correspondingProduct = productList?.find((item) => item._id === orderId);

      if (correspondingProduct) {
        setShowProduct(true);
      } else {
        setShowProduct(false);
      }
    }
  }, [dispatch, verfiyToken, orderId]);

  const continueNext = () => {
    if (AddressKeeper.length > 0) {
      dispatch(orderActions.setCurrentStepHandler(2));
    } else {
      setText("Address is required to order ");
    }
  };

  return (
    <>
      <div className="orderContainer">
        <div className="order-steps-container">
          <div className="progress-container">
            <div className="progress-bar">
              <div
                className="progress-bar-inner"
                style={{ width: (currentStep - 0) * 33.33 + "%" }}
              ></div>
            </div>
            <div className="progress-step" onClick={() => handleStepChange(1)}>
              <span className="progressIcon">
                <i
                  className={`bi ${
                    currentStep >= 1 ? "bi-check-circle-fill" : "bi-circle"
                  }`}
                ></i>
              </span>{" "}
              Address
            </div>
            <div className="progress-step" onClick={() => handleStepChange(2)}>
              <span className="progressIcon">
                {" "}
                <i
                  className={`bi ${
                    currentStep >= 2 ? "bi-check-circle-fill" : "bi-circle"
                  }`}
                ></i>{" "}
              </span>
              Checkout
            </div>
            <div className="progress-step" onClick={() => handleStepChange(3)}>
              <span className="progressIcon">
                {" "}
                <i
                  className={`bi ${
                    currentStep >= 3 ? "bi-check-circle-fill" : "bi-circle"
                  }`}
                ></i>
              </span>{" "}
              Payment
            </div>
          </div>
          {/* {currentStep === 1 ? (
        <div
        className={`footer d-flex justify-content-end gap-3 align-items-center container`}
        >
          {text && <h5 className="text-danger">{text}</h5>}
          <button
            type="button"
            className={`applyBtn Back  btn btn-primary`}
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            type="button"
            className={`applyBtn  btn btn-primary`}
            onClick={continueNext}
          >
            Continue
          </button>
        </div>
      ) : (
        ""
      )} */}

          {currentStep === 1 && (
            <div className="proHeight">
              <ManageAddress order={true} />
            </div>
          )}
          {currentStep === 1 ? (
            <div
              className={`footer d-flex justify-content-end gap-3 align-items-center container`}
            >
              {text && <h5 className="text-danger">{text}</h5>}
              <button
                type="button"
                className={`applyBtn Back  btn btn-primary`}
                onClick={handleHeaderClick}
              >
                Back
              </button>
              <button
                type="button"
                className={`applyBtn  btn btn-primary`}
                onClick={continueNext}
              >
                Continue
              </button>
            </div>
          ) : (
            ""
          )}


          {currentStep === 2 && (
            <ProductCheckout
              product={orderId && showProduct === true ? product : ""}
              orderById={orderId && showProduct === false ? orderById : ""}
            />
          )}
          {currentStep === 3 && (
            <Payment
              product={orderId && showProduct === true ? product : ""}
              orderById={orderId && showProduct === false ? orderById : ""}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OrderSteps;
