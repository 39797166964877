import React, { useEffect } from "react";
import classes from "./Mart.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//img path
import swilLogo from "../../Assets/Images/swilLogo.png";
import swilmart from "../../Assets/Images/swilmart.png";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const Mart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storeActions.setStoreHandler());
  }, []);

  return (
    <div className="container-fluid">
      <div className={`${classes.mart} row`}>
        <div className="col-12">
          <div className={`${classes.martMain}`}>
            <div className={`${classes.swilLogo}`}>
              <img src={swilLogo} />
            </div>
            <div className={`${classes.martText}`}>
              <h2>Let’s start Shopping with E-store </h2>
            </div>
            <div className={`d-flex  mb-5 ${classes.martStoreMain}`}>
              <div className={`${classes.storeBox}`}>
                <img src={swilmart} />
                <div className={`${classes.martStore}`}>
                  <h6>Visit the stores </h6>
                  <p>Step into Swilmart, where a universe of amazing awaits! Discover unique products across our various stores.</p>
                  <button onClick={() => navigate("/storeSelect")}>
                    Visit Store
                  </button>
                </div>
              </div>
              <div className={`${classes.storeBox}`}>
                <img src={swilmart} />
                <div className={`${classes.martStore}`}>
                  <h6>About Swilmart</h6>
                  <p>Step into Swilmart, where a universe of amazing awaits! Discover unique products across our various stores.</p>
                  <button>Visit Site</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mart;
