import { brandActions } from "./BrandSlice";
import { NodeInstance, ProductInstance } from "../../Router/AxiosInstance";


export const getBrandHandler = (id) => {
    return async (dispatch) => {
  
      let result = {
        status: '',
        msg: ''
      }
  
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/customer/brands?StoreId=${id}`,
        headers: {
         // 'Authorization': 'Bearer ' + verfiyToken 
         "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,
         
        },
      };
  
      await ProductInstance(config)
        .then(function (response) {
          if (response.data.status === 'success') {
            result.status = 'success'
            // console.log(response.data.data)
            dispatch(brandActions.setBrandListHandler(response.data.data))
          }
          else{
            dispatch(brandActions.setBrandListHandler([]))

          }
  
        })
        .catch(function (error) {
          console.log(error);
          dispatch(brandActions.setBrandListHandler([]))

        });
      return result;
    };
  }

