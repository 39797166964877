import { React, useEffect, useState } from "react";
import Header from "../../UI/Header/Header";
import classes from "./Order.module.css";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { OrderPromoHandle, getOrderByIdHandler, getOrderInvoiceHandler } from "../../Store/Order/OrderActions";
import noImg from "../../Assets/Images/noImage.png";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const OrderSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const {orderId} = useParams();
  const orderById = useSelector((state) => state.order.orderById);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const productList = useSelector((state) => state.product.productList);
  const Customer = useSelector((state) => state.auth.Customer);
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authToken");
  
  
  useEffect(() => {
    if (orderId) {
      dispatch(getOrderByIdHandler(verfiyToken, orderId));
      dispatch(getOrderInvoiceHandler(orderId))
    }
    const data = {
      CustomerID: Customer?._id,
      OrderID: orderId,
    };
    dispatch(OrderPromoHandle(verfiyToken,data))
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const repeatOrder = () => {
    if (authToken) {
      navigate(`/${id}/orderSteps/${orderId}`);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(orderById?._id);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 8000); 
  };
  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };
  let returnedProducts = useSelector((state) => state.order.returnedProducts);
  // console.log("returnedProducts--------",returnedProducts)
  
  const isProductReturned = (productId) => {

    const returnedProductsForCurrentOrder = returnedProducts.filter(
      (product) => product.OrderID === id
    );
    if(returnedProductsForCurrentOrder.length < 0) return false;

    const alreadyReturnedProducts = returnedProductsForCurrentOrder.filter(
      (product)=> product.Product_Id === productId
    )
    if(alreadyReturnedProducts.length < 0) return false;
    
   if(alreadyReturnedProducts && alreadyReturnedProducts.length > 0)
    {
       return true;
    }
    return false;
  };
  return (
    <>
      <div className={`container-fluid ${classes.OrderSummaryPage}`}>
        <div className={`${classes.OrderSummaryNavigation}`}>
          <MainHeader />
        </div>

        <div className={`${classes.OrderSummaryWrapper}`}>
          <Header heading={"Order Summary"} onClick= {handleHeaderClick} />
          <div className={`${classes.OrderSummaryFlexBox}`}>
            <div className={`${classes.productSection}`}>
              <div className={`${classes.orderSummaryContainer} col-12`}>
                <p className={`${classes.deliveredText}`}>
                  Delivered at 10:53 AM on 11 August, 2024
                </p>
                <div className="d-flex gap-2">
                  <i className="bi bi-file-earmark-arrow-down"></i>
                  <a href={orderById?.CustomerDetail[0]?.InvoiceBillDetails} target="blank">
                    {" "}
                    <p className={`${classes.downText}`}>Download Invoice</p>
                  </a>
                </div>
                <h6 className={`${classes.orderQty}`}>
                  {orderById?.CustomerDetail[0]?.ProdDetail?.length} items in this order
                </h6>
                <div className={`${classes.allOrders}`}>
                  {orderById?.CustomerDetail[0]?.ProdDetail?.map((product, index) => {
                    const correspondingProduct = productList.find(
                      (item) => item.PKID == product.FKProdID
                    ); 
                
                    return (
                      <div className={`d-flex ${classes.revAdd}`} key={index}>
                        <div className={`${classes.OrderSummaryBox} `}>
                          <div className="d-flex align-items-center ">
                            {correspondingProduct?.Images?.length > 0 &&
                            correspondingProduct?.Images ? (
                              <img
                                variant="top"
                                src={correspondingProduct?.Images[0]}
                                className={`${classes.OrderImage}`}
                              />
                            ) : (
                              <img
                                src={noImg}
                                className={`${classes.OrderImage}`}
                              />
                            )}
                          </div>
                          <div
                            className={`${classes.Product} ${classes.productCont} mt-2 `}
                          >
                            <h6>{product?.Product_name}</h6>
                            <p>
                              Qty :{" "}
                              <span className="fw-bold">{product?.Qty}</span>{" "}
                            </p>
                            <div>
                              <span className={`${classes.Amount}`}>
                                {product?.Rate}
                              </span>
                              <span className={`${classes.Rupee} ms-1`}>₹</span>
                              {/* <del className={`${classes.PreviousAmount} ms-2`}>
                                {product?.Rate * 2}
                              </del> */}
                            </div>
                          </div>
                        </div>
                        <div className={`${classes.ExTextBox}`}>
                           {isProductReturned(product.FKProdID)
                           ?
                           (
                             <div
                            className={`${classes.ExText}`}
                            // onClick={() => navigate(`/ReturnOrder/${product?.Product_Id}`)}
                          >
                            Return Requested
                          </div>):(
                            <div
                            className={`${classes.ExText}`}
                            onClick={() => navigate(`/ReturnOrder/${product?.Product_Id}`)}
                          >
                            Exchange/Return
                          </div>
                          )}
                          <button
                            className={`${classes.reviewBtn} `}
                            onClick={() =>
                              navigate(`/${id}/SubmitReview/${product.Product_Id}`)
                            }
                          >
                            Review Product
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={`${classes.priceSection}`}>
              <div className={`${classes.mrpRow}`}>
                <div className={`${classes.mrpRowText}`}>
                  <span>MRP</span>
                  <span>₹{orderById?.CustomerDetail[0]?.total_amount}</span>
                </div>
                <div className={`${classes.mrpRowProduct}`}>
                  <span>Product Discount</span>
                  <span>-₹0</span>
                </div>

                <div className="d-flex justify-content-between">
                  <span>Item Total</span>
                  <span>₹{orderById?.CustomerDetail[0]?.total_amount}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Handling Charge</span>
                  <span>+₹0</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Delivery Charge</span>
                  <span>+₹0</span>
                </div>
                <div className={`${classes.mrpRowBill}`}>
                  <span>Bill Total</span>
                  <span>₹{orderById?.CustomerDetail[0]?.total_amount}</span>
                </div>
              </div>
              <div className=" mt-3">
                <div
                  className={`${classes.orderShipping} ${classes.oreText} col-12`}
                >
                  <h6>Order Details</h6>
                </div>
                <div className={`${classes.orderSumDetail} mt-2`}>
                  <p>Order ID</p>
                  <h6>
                    {orderById?._id}

                    <span onClick={copyToClipboard}>
                      {
                        isCopied===true ? <i className="bi bi-clipboard-check-fill ms-2"></i> :
                      <i className="bi bi-clipboard ms-2"></i>
                      }
                    </span>{" "}
                  </h6>
                  <p>Payment Mode</p>
                  <h6>{orderById?.CustomerDetail[0]?.PaymentDetails[0]?.paymentMethod}</h6>
                  <p>Deliver to</p>
                  <h6>
                    {" "}
                    {orderById?.CustomerDetail[0]?.BillingAddress?.Street1},{" "}
                    {orderById?.CustomerDetail[0]?.BillingAddress?.City},{" "}
                    {orderById?.CustomerDetail[0]?.BillingAddress?.State}.
                  </h6>
                  <p>Order Placed</p>
                  <h6>{`Placed on ${orderById?.CustomerDetail[0]?.createdAt
                    ?.slice(0, 10)
                    .split("-")
                    .reverse()
                    .join("-")} at  ${orderById?.CustomerDetail[0]?.createdAt?.slice(
                    11,
                    19
                  )} `}</h6>
                </div>
              </div>
              <div className=" mt-3">
                <div
                  className={`${classes.orderShipping} ${classes.chatText} justify-content-center col-12`}
                >
                  <i className="bi bi-telephone me-2"></i>{" "}
                  <h6>Chat with us / Call with us (Support)</h6>
                </div>
                <div className="col-12 mt-4">
                  <div
                    className={`${classes.repeatText} `}
                    onClick={repeatOrder}
                  >
                    <h6>Repeat Order</h6>
                    <p>View summary on next step</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default OrderSummary;
