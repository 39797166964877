import { createSlice } from "@reduxjs/toolkit";

const brandSlice = createSlice({
    name: "brand",
    initialState: {
        brandList: [],

    },
    reducers: {
        setBrandListHandler(state, action) {
            state.brandList = action.payload;
        },
    }
})

export const brandActions = brandSlice.actions

export default brandSlice