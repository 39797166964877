import React, { useState, useEffect } from "react";
import "../../UI/global.css";
import classes from "./SearchProduct.module.css";
import { useNavigate,useParams } from "react-router-dom";
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../Store/Product/ProductSlice";
import Loader from "../../UI/Loader/Loader";
import {
  PostWishlistHandler,
  RemoveWishlistHandler,
  getProductByIdHandler,
  getProductHandler,
  getWishlistHandler,
} from "../../Store/Product/ProductActions";
import { categoryActions } from "../../Store/Category/CategorySlice";
import { cartActions } from "../../Store/Cart/CartSlice";
import { getCategoryHandler } from "../../Store/Category/CategoryActions";
import AddToCart from "../../UI/AddToCart/AddToCart";
import { AddCartHandler, getCartHandler } from "../../Store/Cart/CartActions";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import PageLoader from "../../UI/Loader/PageLoader";


//img path
import noImg from "../../Assets/Images/noImage.png";
import offerImg from "../../Assets/Images/offer.png";
import NoProductsImage from "../../Assets/Images/NoProductsImage.png";
import { ReactComponent as SearchSvg } from "../../Assets/SvgComponents/SearchSvg.svg";
import { ReactComponent as NotFavouriteSvg } from "../../Assets/SvgComponents/NotFavouriteSvg.svg";
import { ReactComponent as FilterSvg } from "../../Assets/SvgComponents/FilterSvg.svg";
import { ReactComponent as FavouriteSvg } from "../../Assets/SvgComponents/FavouriteSvg.svg";
import { ReactComponent as CartSvg } from "../../Assets/SvgComponents/CartSvg.svg";
import { authActions } from "../../Store/Auth/AuthSlice";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const ProductDemo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleState, setToggleState] = useState(false);

  const subCategory = useSelector((state) => state.category.subCategory);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const authToken = localStorage.getItem("authToken");
  const IsAllProduct = localStorage.getItem("IsAllProduct");

  const productList = useSelector((state) => state.product.productList);
  const query = useSelector((state) => state.product.query);
  const cartList = useSelector((state) => state.cart.cartList);
  const categoryName = useSelector((state) => state.category.categoryName);
  const mainCategory = useSelector((state) => state.category.mainCategory);
  const categoryList = useSelector((state) => state.category.categoryList);
  const wishlist = useSelector((state) => state.product.wishlist);
  const Profile = useSelector((state) => state.auth.Profile);
  const cartData = useSelector((state) => state.cart.cartData);
  const storeById = useSelector((state) => state.store.storeById);
  const [msg, setMsg] = useState("");

  const [productCatList, setProductCatList] = useState([productList]);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const withinPromotionTime = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const fromTimeParts = fromTime && fromTime.split(":");
    const toTimeParts = toTime && toTime.split(":");

    const fromHours = fromTimeParts && parseInt(fromTimeParts[0], 10);
    const fromMinutes = fromTimeParts && parseInt(fromTimeParts[1], 10);

    const toHours = toTimeParts && parseInt(toTimeParts[0], 10);
    const toMinutes = toTimeParts && parseInt(toTimeParts[1], 10);

    if (
      toHours > fromHours ||
      (toHours === fromHours && toMinutes >= fromMinutes)
    ) {
      return (
        (currentHours > fromHours ||
          (currentHours === fromHours && currentMinutes >= fromMinutes)) &&
        (currentHours < toHours ||
          (currentHours === toHours && currentMinutes <= toMinutes))
      );
    } else {
      return (
        currentHours > fromHours ||
        (currentHours === fromHours && currentMinutes >= fromMinutes) ||
        currentHours < toHours ||
        (currentHours === toHours && currentMinutes <= toMinutes)
      );
    }
  };

  const BackToHome = (category) => {
    let tempQuery = structuredClone(query);
    tempQuery.categories = "";
    tempQuery.Brand = "";

    dispatch(productActions.setQueryHandler(tempQuery));
    dispatch(categoryActions.setSubCategory(category.children));
    navigate(`/${id}/home`);
  };

  useEffect(() => {
    setLoading(true);
    let tempQuery = structuredClone(query);
    tempQuery.IsAllProduct = IsAllProduct;
    dispatch(productActions.setProductListHandler([]));
    dispatch(getProductHandler(storeById?.StoreId, tempQuery)).then((result) => {
      if (result.status === "success") {
        setLoading(false);
        dispatch(productActions.setProductListHandler(result.data));
        setProductCatList(result.data);
        return;
      }
      if (result.status === "error") {
        setLoading(false);
        setMsg("Products are not available for this store at this time.");
      }
    });
  }, [dispatch, query]);

  const addToCart = (product) => {
    const data = {
      Product_Id: product._id,
      FKProdID: product.PKID,
      // FKLotID: 10000001,
      Product_name: product.NameToDisplay,
      Product_Quantity: 1,
      Product_detail: product.Description,
      Product_Price: product.SaleRate,

      User_Numberid: Profile?.MobileNumber,
    };

    if (authToken) {
      dispatch(
        AddCartHandler(verfiyToken, authToken, data, data.User_Numberid)
      ).then((result) => {
        if (result.status === "success") {
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
        }
      });
    } else {
      dispatch(
        cartActions.pushProToCartListHandler({
          Product_Id: product._id,
          FKProdID: product.PKID,
          // FKLotID: 10000001,
          Product_name: product.NameToDisplay,
          Product_Quantity: 1,
          Product_detail: product.Description,
          Product_Price: product.SaleRate,

          User_Numberid: Profile?.MobileNumber,

          // product: product,
        })
      );
      dispatch(cartActions.calculateTotalLocalPriceHandler(product));
    }
  };

  useEffect(() => {
    dispatch(getCategoryHandler(storeById?.StoreId));
  }, [dispatch, storeById]);

  useEffect(() => {
    if (categoryList?.length > 0) {
      const mainCategory = [];

      categoryList.forEach((category) => {
        if (category.parent === null) {
          mainCategory.push(category);
        }
      });
      dispatch(categoryActions.setMainCategory(mainCategory));
    }
  }, [dispatch, categoryList]);

  const ProductDataHandler = (product) => {
    const modifiedName = product.NameToDisplay.replace(/ /g, "-")
      .replace(/'/g, "")
      .replace(/:/g, "-")
      .replace(/\//g, "-");
    const encodedName = encodeURIComponent(modifiedName);
    dispatch(getProductByIdHandler(storeById?.StoreId, product._id));
    navigate(`/${id}/productdetail/${encodedName}/${product._id}`);
  };

  const addToWishlist = (WishlistProductId) => {
    const data = {
      WishlistProductId: WishlistProductId,
      User_Number: Profile?.MobileNumber,
      appKeyToken: verfiyToken,
    };
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(PostWishlistHandler(authToken, data)).then((result) => {
            if (result.status === "success") {
              dispatch(
                getWishlistHandler(
                  authToken,
                  { appKeyToken: verfiyToken },
                  Profile?.MobileNumber
                )
              );
            } else {
              navigate("/verification");
              dispatch(authActions.setFromPage("cart"));
            }
          });
        }
      });
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("cart"));
    }
  };

  const removeWishlist = (id) => {
    dispatch(
      RemoveWishlistHandler(authToken, Profile?.MobileNumber, id, {
        appKeyToken: verfiyToken,
      })
    ).then((result) => {
      if (result.status === "success") {
        dispatch(
          getWishlistHandler(
            authToken,
            { appKeyToken: verfiyToken },
            Profile?.MobileNumber
          )
        );
      } else {
        navigate("/verification");
        dispatch(authActions.setFromPage("cart"));
      }
    });
  };

  const searchByName = (e) => {
    let tempQuery = structuredClone(query);
    tempQuery.search = e.target.value;
    dispatch(productActions.setQueryHandler(tempQuery));
  };

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
          dispatch(
            getWishlistHandler(
              authToken,
              { appKeyToken: verfiyToken },
              result.data.mobile
            )
          );
        }
      });
    }
  }, [dispatch, verfiyToken, authToken]);

  const sortedProductList = [...productList].sort((a, b) => {
    const aHasPromotion =
      a.appliedPromotions?.length > 0 ||
      a.appliedPromotionOnProductCategoryVise?.length > 0 ||
      a.appliedPromotionOnProductBrandVise?.length > 0;

    const bHasPromotion =
      b.appliedPromotions?.length > 0 ||
      b.appliedPromotionOnProductCategoryVise?.length > 0 ||
      b.appliedPromotionOnProductBrandVise?.length > 0;

    if (aHasPromotion && bHasPromotion) {
      const aPromotionRate =
        a.appliedPromotions[a?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        a.appliedPromotionOnProductCategoryVise[
          a?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        a.appliedPromotionOnProductBrandVise[
          a?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      const bPromotionRate =
        b.appliedPromotions[b?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        b.appliedPromotionOnProductCategoryVise[
          b?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        b.appliedPromotionOnProductBrandVise[
          b?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      return bPromotionRate - aPromotionRate;
    }

    return bHasPromotion - aHasPromotion;
  });

  const productsWithPromotion = sortedProductList.filter(
    (product) =>
      (product.appliedPromotions?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionFromTime,
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionToTime
        ) &&
        (!product?.appliedPromotions[product?.appliedPromotions.length - 1]
          ?.FKLocationID ||
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductCategoryVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionFromTime,
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductCategoryVise[
          product?.appliedPromotionOnProductCategoryVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductBrandVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionFromTime,
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductBrandVise[
          product?.appliedPromotionOnProductBrandVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.FKLocationID === storeById?.LocationId))
  );
  return (
    <>
      <div className={`${classes.ProductDemoPage} container-fluid`}>
        <div className={`${classes.ProductDemoNavigation}`}>
          <MainHeader />
        </div>

        <div className={`${classes.ProductDemoWrapper}`}>
          <Header
            onClick={BackToHome}
            heading={`Products On Deal`}
            jsxheading={
              showInput ? (
                <input
                  type="search"
                  className={`${classes.searchField} form-control `}
                  id="exampleInputEmail1"
                  aria-label="Search"
                  placeholder="Search"
                  onChange={searchByName}
                  value={query.search}
                />
              ) : (
                <div
                  className={`${classes.searchContainer} mb-2 `}
                  onClick={() => setShowInput(true)}
                >
                  <SearchSvg />
                </div>
              )
            }
          />
          <div className={`${classes.SearchProduct}`}>
            {loading ? (
              <div className={`${classes.setLoader}`}>
                {" "}
                {/* <Loader />{" "} */}
                <PageLoader />{" "}

              </div>
            ) : (
              <>
                {productsWithPromotion?.length > 0 ? (
                  <div className={`${classes.productRow} mb-3 `}>
                    {productsWithPromotion?.map((product, index) => {
                      const findProd = authToken
                        ? cartList?.find(
                            (cartprod) => cartprod.Product_Id === product._id
                          )
                        : cartData?.find(
                            (cartprod) => cartprod.Product_Id === product._id
                          );
                      const findFav = wishlist?.WishlistProductId?.find(
                        (wish) => wish === product?.PKID
                      );
                      return (
                        <div className={`${classes.productMain} `} key={index}>
                          <div className="d-block text-center  ">
                            <div className={`${classes.productList} `}>
                              <div className={`${classes.favourite} `}>
                                {findFav ? (
                                  <div
                                    onClick={() => removeWishlist(product.PKID)}
                                  >
                                    <FavouriteSvg />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => addToWishlist(product.PKID)}
                                  >
                                    <NotFavouriteSvg />
                                  </div>
                                )}
                              </div>
                              {product?.appliedPromotions?.length > 0 &&
                                product?.appliedPromotionOnProductCategoryVise
                                  ?.length === 0 &&
                                product?.appliedPromotionOnProductBrandVise
                                  ?.length === 0 &&
                                withinPromotionTime(
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionFromTime,
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionToTime
                                ) &&
                                (!product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.FKLocationID ||
                                  product?.appliedPromotions[
                                    product?.appliedPromotions.length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product?.appliedPromotions[
                                          product?.appliedPromotions.length - 1
                                        ]?.ProductPromotionPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotions?.length > 0 &&
                                (product?.appliedPromotionOnProductCategoryVise
                                  ?.length > 0 ||
                                  product?.appliedPromotionOnProductBrandVise
                                    ?.length > 0) &&
                                withinPromotionTime(
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionFromTime,
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionToTime
                                ) &&
                                (!product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.FKLocationID ||
                                  product?.appliedPromotions[
                                    product?.appliedPromotions.length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product?.appliedPromotions[
                                          product?.appliedPromotions.length - 1
                                        ]?.ProductPromotionPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotions?.length === 0 &&
                                product?.appliedPromotionOnProductCategoryVise
                                  ?.length > 0 &&
                                product?.appliedPromotionOnProductBrandVise
                                  ?.length > 0 &&
                                withinPromotionTime(
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionFromTime,
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionToTime
                                ) &&
                                (!product
                                  ?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.FKLocationID ||
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product
                                          ?.appliedPromotionOnProductCategoryVise[
                                          product
                                            ?.appliedPromotionOnProductCategoryVise
                                            .length - 1
                                        ]
                                          ?.ProductPromotionCategoryPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotionOnProductCategoryVise
                                ?.length > 0 &&
                                product?.appliedPromotions?.length === 0 &&
                                product?.appliedPromotionOnProductBrandVise
                                  ?.length === 0 &&
                                withinPromotionTime(
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionFromTime,
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionToTime
                                ) &&
                                (!product
                                  ?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.FKLocationID ||
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product
                                          ?.appliedPromotionOnProductCategoryVise[
                                          product
                                            ?.appliedPromotionOnProductCategoryVise
                                            .length - 1
                                        ]
                                          ?.ProductPromotionCategoryPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotionOnProductBrandVise
                                ?.length > 0 &&
                                product?.appliedPromotionOnProductCategoryVise
                                  ?.length === 0 &&
                                product?.appliedPromotions?.length === 0 &&
                                withinPromotionTime(
                                  product?.appliedPromotionOnProductBrandVise[
                                    product?.appliedPromotionOnProductBrandVise
                                      .length - 1
                                  ]?.ProductBrandPromotionFromTime,
                                  product?.appliedPromotionOnProductBrandVise[
                                    product?.appliedPromotionOnProductBrandVise
                                      .length - 1
                                  ]?.ProductBrandPromotionToTime
                                ) &&
                                (!product?.appliedPromotionOnProductBrandVise[
                                  product?.appliedPromotionOnProductBrandVise
                                    .length - 1
                                ]?.FKLocationID ||
                                  product?.appliedPromotionOnProductBrandVise[
                                    product?.appliedPromotionOnProductBrandVise
                                      .length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product
                                          ?.appliedPromotionOnProductBrandVise[
                                          product
                                            ?.appliedPromotionOnProductBrandVise
                                            .length - 1
                                        ]?.ProductPromotionBrandPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}
                              <div
                                className={`${classes.categoryImage}  position-relative `}
                                onClick={() => ProductDataHandler(product)}
                              >
                                {product?.Images?.length > 0 &&
                                product?.Images ? (
                                  <img
                                    variant="top"
                                    src={product?.Images[0]}
                                    alt="imageNotAvailable"
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    variant="top"
                                    src={noImg}
                                    alt="imageNotAvailable"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div
                                className={`${classes.categoryName} ${classes.productName}  pt-2`}
                              >
                                <h6
                                  className={`${classes.productBrand}  text-capitalize `}
                                  onClick={() => ProductDataHandler(product)}
                                >
                                  {product?.ERPBrand}{" "}
                                </h6>

                                <h6
                                  className={`${classes.productTitle}  text-capitalize `}
                                  onClick={() => ProductDataHandler(product)}
                                >
                                  {/* {product.NameToDisplay}{" "} */}
                                  {product?.Product}{" "}
                                </h6>
                                <div className={`${classes.rating} pb-1  `}>
                              {Array.from({ length: 5 }, (_, index) => {
                                return index <
                                  Math.round(product?.AverageProductRating) ? (
                                  <i
                                    key={index}
                                    className="bi bi-star-fill pe-1"
                                  ></i>
                                ) : (
                                  <i
                                    key={index}
                                    className="bi bi-star pe-1"
                                  ></i>
                                );
                              })}
                            </div>
                                <p className={`${classes.amount} mb-0`}>
                                  ₹{product.SaleRate}{" "}
                                </p>

                                <div>
                                  {findProd ? (
                                    <button className={`${classes.item}`}>
                                      <AddToCart product={findProd} />
                                    </button>
                                  ) : (
                                    <button
                                      className={`${classes.cartBtn}`}
                                      onClick={() => addToCart(product)}
                                    >
                                      <CartSvg /> ADD
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={`${classes.NoProductsImageFound} `}>
                    <img src={NoProductsImage} alt="no products" />
                  </div>
                )}
              </>
            )}
            {msg && <h4 className="text-danger">{msg}</h4>}
          </div>

          {authToken ? (
            <>
              {cartList?.length > 0 ? (
                <Footer onClick={() => navigate(`/${id}/cart`)} />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {cartData?.length > 0 ? (
                <Footer onClick={() => navigate(`/${id}/cart`)} />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default ProductDemo;
