import classes from "./Home.module.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { categoryActions } from "../../Store/Category/CategorySlice";
import { productActions } from "../../Store/Product/ProductSlice";

//img path
import noImg from "../../Assets/Images/noImage.png";
import { getProductHandler } from "../../Store/Product/ProductActions";

const Category = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
 
  const [visibleItem, setVisibleItem] = useState("7");



  const categoryList = useSelector((state) => state.category.categoryList);
  const mainCategory = useSelector((state) => state.category.mainCategory);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const productList = useSelector((state) => state.product.productList);
  const query = useSelector((state) => state.product.query);
  const storeById = useSelector((state) => state.store.storeById);
  const storeId = useSelector((state) => state.store.storeId);

  const [productCatList, setProductCatList] = useState(productList);
  const IsAllProduct = localStorage.getItem("IsAllProduct");


  const findProductByCategory = (category) => {
    let tempQuery = structuredClone(query);
    tempQuery.categories = category._id;
    tempQuery.IsAllProduct = IsAllProduct;

    dispatch(productActions.setQueryHandler(tempQuery));
    dispatch(categoryActions.setSubCategory(category.children));

    dispatch(categoryActions.setCategoryName(category.name));
    dispatch(categoryActions.setMainCategoryName(category.name));
    dispatch(productActions.setProductListHandler([]));

    dispatch(getProductHandler(storeById?.StoreId, tempQuery)).then((result) => {
      if (result.status === "success") {
        navigate(`/${storeId}/allcategory/${category._id}`);
        dispatch(productActions.setProductListHandler(result.data));
      }
    });
  };

  const withinPromotionTime = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const fromTimeParts = fromTime && fromTime.split(":");
    const toTimeParts = toTime && toTime.split(":");
    
    const fromHours = fromTimeParts && parseInt(fromTimeParts[0], 10);
    const fromMinutes = fromTimeParts && parseInt(fromTimeParts[1], 10);
    
    const toHours = toTimeParts && parseInt(toTimeParts[0], 10);
    const toMinutes = toTimeParts && parseInt(toTimeParts[1], 10);


    if (toHours > fromHours || (toHours === fromHours && toMinutes >= fromMinutes)) {
        return (currentHours > fromHours || (currentHours === fromHours && currentMinutes >= fromMinutes))
            && (currentHours < toHours || (currentHours === toHours && currentMinutes <= toMinutes));
    } else {
        return (currentHours > fromHours || (currentHours === fromHours && currentMinutes >= fromMinutes))
            || (currentHours < toHours || (currentHours === toHours && currentMinutes <= toMinutes));
    }


};

  useEffect(() => {
    if (categoryList?.length > 0) {
      const mainCategory = [];

      categoryList.forEach((category) => {
        if (category.parent === null) {
          mainCategory.push(category);
        }
      });
      dispatch(categoryActions.setMainCategory(mainCategory));
    } else {
      dispatch(categoryActions.setMainCategory([]));
    }
  }, [dispatch, categoryList]);

  const sortedProductList = [...productList].sort((a, b) => {
    const aHasPromotion =
      a.appliedPromotions?.length > 0 ||
      a.appliedPromotionOnProductCategoryVise?.length > 0 ||
      a.appliedPromotionOnProductBrandVise?.length > 0;

    const bHasPromotion =
      b.appliedPromotions?.length > 0 ||
      b.appliedPromotionOnProductCategoryVise?.length > 0 ||
      b.appliedPromotionOnProductBrandVise?.length > 0;

    if (aHasPromotion && bHasPromotion) {
      const aPromotionRate =
        a.appliedPromotions[a?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        a.appliedPromotionOnProductCategoryVise[
          a?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        a.appliedPromotionOnProductBrandVise[
          a?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      const bPromotionRate =
        b.appliedPromotions[b?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        b.appliedPromotionOnProductCategoryVise[
          b?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        b.appliedPromotionOnProductBrandVise[
          b?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      return bPromotionRate - aPromotionRate;
    }

    return bHasPromotion - aHasPromotion;
  });

  const productsWithPromotion = sortedProductList.filter(
    (product) =>
      (product.appliedPromotions?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionFromTime,
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionToTime
        ) &&
        (!product?.appliedPromotions[product?.appliedPromotions.length - 1]
          ?.FKLocationID ||
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductCategoryVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionFromTime,
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductCategoryVise[
          product?.appliedPromotionOnProductCategoryVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductBrandVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionFromTime,
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductBrandVise[
          product?.appliedPromotionOnProductBrandVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.FKLocationID === storeById?.LocationId))
  );

  const getProductMaxPromotion = (products) => {
    if (products?.length === 0) {
      return 0;
    }

    return products.reduce((maxPromotion, product) => {
      const appliedPromotion =
        product.appliedPromotions?.length > 0
          ? product.appliedPromotions[product.appliedPromotions?.length - 1]
              ?.ProductPromotionPercentageRate || 0
          : 0;

      const categoryPromotion =
        product.appliedPromotionOnProductCategoryVise?.length > 0
          ? product.appliedPromotionOnProductCategoryVise[
              product.appliedPromotionOnProductCategoryVise?.length - 1
            ]?.ProductPromotionCategoryPercentageRate || 0
          : 0;

      const brandPromotion =
        product.appliedPromotionOnProductBrandVise?.length > 0
          ? product.appliedPromotionOnProductBrandVise[
              product.appliedPromotionOnProductBrandVise?.length - 1
            ]?.ProductPromotionBrandPercentageRate || 0
          : 0;

      const productPromotion = appliedPromotion > 0 && categoryPromotion > 0
        ? appliedPromotion
        : Math.max(appliedPromotion, categoryPromotion, brandPromotion);

      return Math.max(maxPromotion, productPromotion);
    }, 0);
  };

  return (
    <div className={`${classes.categoryWrapper}  container-fluid `}>
      {mainCategory?.length > 0 ? (
        <>
          <div className="row">
            <div className="col-sm-12 col-xs-12 ">
              <div className={`${classes.headline}  overflow-hidden `}>
                <h6 className="pb-1 position-relative d-inline-block">
                  Category
                </h6>
              </div>
            </div>
          </div>
          <div div className={`${classes.categoryProducts} `} >
            {mainCategory
              ?.slice(0, visibleItem > 0 ? visibleItem : mainCategory?.length)
              ?.map((category, index) => {
                const categoryProducts = productsWithPromotion?.filter(
                  (product) => product.Category?.includes(category._id)
                );
                const maxPromotion = getProductMaxPromotion(categoryProducts);

                return (
                  <div
                  className={`${classes.categoryItem}  `}
                    key={index}
                  >
                    <div
                      className={`${classes.categoryListMain} `}
                      onClick={() => findProductByCategory(category)}
                    >
                      <div className={`${classes.categoryList} text-center `}>
                        <div
                          className={`${classes.categoryImg}  position-relative`}
                        >
                          {category?.image ? (
                            <img
                              variant="top"
                              src={category.image}
                              alt="imageNotAvailable"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              variant="top"
                              src={noImg}
                              alt="imageNotAvailable"
                              className="img-fluid"
                            />
                          )}
                        </div>

                        <div className={`${classes.categoryName}`}>
                          <h5>{category.name}</h5>
                        </div>
                        {maxPromotion > 0 && (
                          <div className={classes.slantBanner}>
                            <span className={classes.UptoText}>UPTO</span>
                            <span className={classes.discounText}>
                              {maxPromotion}% OFF
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            {mainCategory?.length >= 8 ? (
              <div className="">
                <div className="d-block text-center">
                  {visibleItem !== mainCategory?.length ? (
                    <div
                      onClick={() => setVisibleItem(mainCategory?.length)}
                      className={`${classes.categoryList} ${classes.categorySeeBox}`}
                    >
                      <span>
                        see more<i className="bi bi-chevron-down"></i>
                      </span>
                    </div>
                  ) : (
                    <div
                      onClick={() => setVisibleItem("7")}
                      className={`${classes.categoryList} ${classes.categorySeeBox}`}
                    >
                      <span>
                        see less <i className="bi bi-chevron-up"></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Category;
