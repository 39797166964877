import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categoryList: [],
    mainCategory: [],
    subCategory: [],
    categoryName: "",
    mainCategoryName: "",
    categoryById: {
      _id: "",
      adminAuthId: "6437f5000ecb28f06c1d7975",
      name: "",
      parent: null,
      children: [],
      active: true,
      __v: 0,
    },
  },
  reducers: {
    setCategoryListHandler(state, action) {
      state.categoryList = action.payload;
    },
    setMainCategory(state, action) {
      state.mainCategory = action.payload;
    },
    setSubCategory(state, action) {
      state.subCategory = action.payload;
    },
    setCategoryName(state, action) {
      state.categoryName = action.payload;
    },
    setMainCategoryName(state, action) {
      state.mainCategoryName = action.payload;
    },
  },
});

export const categoryActions = categorySlice.actions;

export default categorySlice;
