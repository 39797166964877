import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../UI/Header/Header";
import classes from "./SearchProduct.module.css";
import Product from "../Home/Product";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../UI/UISlice";
import { productActions } from "../../Store/Product/ProductSlice";
import { getProductHandler } from "../../Store/Product/ProductActions";
import Loader from "../../UI/Loader/Loader";
import Footer from "../../UI/Footer/Footer";
import Filter from "../AllCategory/Filter";
import MainFooter from "../../UI/Footer/MainFooter";
import MainHeader from "../Home/MainHeader";
import NoProductsImage from "../../Assets/Images/NoProductsImage.png";
import PageLoader from "../../UI/Loader/PageLoader";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import { getBrandHandler } from "../../Store/Brand/BrandActions";

const SearchProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef();

  const { id } = useParams();
  const { brandId } = useParams();

  const [showItem, setShowItem] = useState(false);
  const [loading, setLoading] = useState(false);

  const setState = useSelector((state) => state.ui.HomeMain.setState);
  const query = useSelector((state) => state.product.query);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const brandName = useSelector((state) => state.ui.HomeMain.brandName);
  const cartList = useSelector((state) => state.cart.cartList);
  const cartData = useSelector((state) => state.cart.cartData);
  const brandList = useSelector((state) => state.brand.brandList);
  const storeById = useSelector((state) => state.store.storeById);

  const authToken = localStorage.getItem("authToken");
  const IsAllProduct = localStorage.getItem("IsAllProduct");

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);



  const showProduct = () => {
    dispatch(uiActions.setHomeSearch({ name: "setState", value: "search" }));
  };

  const searchByName = (e) => {
    let tempQuery = structuredClone(query);
    tempQuery.search = e.target.value;
    dispatch(productActions.setQueryHandler(tempQuery));
  };

  const clearQuery = () => {
    navigate(`/${id}/home`);
    let tempQuery = structuredClone(query);
    tempQuery.search = "";
    tempQuery.Brand = "";
    tempQuery.minMRP = "";
    tempQuery.maxMRP = "";
    dispatch(productActions.setQueryHandler(tempQuery));
  };

  useEffect(() => {
    setLoading(true);
    if (brandId) {
      let tempQuery = structuredClone(query);
      tempQuery.IsAllProduct = IsAllProduct;
      tempQuery.Brand = brandId;
      dispatch(productActions.setQueryHandler(tempQuery));

      dispatch(getProductHandler(storeById?.StoreId, tempQuery)).then((result) => {
        if (result.status === "success") {
          setLoading(false);
          return;
        }
        if (result.status === "error") {
          setLoading(false);
        }
      });
    }
    if (setState === "search") {
      const timerId = setTimeout(() => {
        let tempQuery = structuredClone(query);
        tempQuery.IsAllProduct = IsAllProduct;
        dispatch(getProductHandler(storeById?.StoreId, tempQuery)).then((result) => {
          if (result.status === "success") {
            setLoading(false);
            return;
          }
          if (result.status === "error") {
            setLoading(false);
          }
        });
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [dispatch, verfiyToken]);

  useEffect(() => {
    const handleBackNavigation = () => {
      let tempQuery = structuredClone(query);
      tempQuery.search = "";
      tempQuery.Brand = "";
      dispatch(productActions.setQueryHandler(tempQuery));
      navigate(`/${id}/home`);
    };
    window.history.pushState({}, "");
    window.onpopstate = handleBackNavigation;
    return () => {
      window.onpopstate = null;
    };
  }, [dispatch, query, navigate]);

  useEffect(() => {
    if (brandId && verfiyToken) {
      dispatch(getBrandHandler(storeById?.StoreId));
    }
  }, [brandId]);

  return (
    <>
      <div className={`container-fluid ${classes.searchProductPage}`}>
        <div className={`${classes.searchProductNavigation}`}>
          <MainHeader />
        </div>

        <div className={`container-fluid ${classes.searchProductContent}`}>
          <Header
            onClick={clearQuery}
            heading={
              setState === "brand" && brandName
                ? brandName
                : brandList?.find((item) => item._id === brandId)?.name
            }
            jsxheading={
              <>
                {setState === "brand" && (    
                  <div className={`${classes.searchProducts} gap-2`}>
                    <div
                      className={`${classes.searchSvg}`}
                      onClick={showProduct}
                    >
                      {/* <SearchSvg /> */}
                      <i className="bi bi-search"></i>
                    </div>
                    {/* <Filter
                    content={
                      <div
                        className={`${classes.searchSvg}`}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <i className="bi bi-sliders2"></i>
                      </div>
                    }
                  /> */}
                  </div>
                )}
                {setState === "search" && (
                  <>
                    {" "}
                    <div className={`${classes.searchData}`}>
                      <input
                        ref={searchRef}
                        type="text"
                        className={`${classes.searchField}  form-control `}
                        id="exampleInputEmail1"
                        aria-label="Search"
                        // placeholder="Search for Products"
                        onChange={searchByName}
                        value={query.search}
                      />
                      <div className={`d-flex gap-2 ${classes.inputIcon}`}>
                        <div className={`${classes.searchSvg}`}>
                          <i className="bi bi-search"></i>
                        </div>
                        {/* <Filter
                      content={
                        <div
                          className={`${classes.searchSvg}`}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          <i className="bi bi-sliders2"></i>
                        </div>
                      }
                    /> */}
                      </div>
                    </div>
                  </>
                )}
              </>
            }
          />

          <div className={`${classes.SearchProduct}`}>
            {loading ? (
              <div className={`${classes.setLoader}`}>
                {" "}
                {/* <Loader />{" "} */}
                <PageLoader />{" "}
              </div>
            ) : (
              <>
                <Product showItem={showItem} setShowItem={setShowItem} />
              </>
            )}
          </div>
          {authToken ? (
            <>
              {cartList?.length > 0 ? (
                <Footer onClick={() => navigate(`/${id}/cart`)} />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {cartData?.length > 0 ? (
                <Footer onClick={() => navigate(`/${id}/cart`)} />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default SearchProduct;
