import React, { useEffect } from "react";
import MainHeader from "../../Pages/Home/MainHeader";
import MainFooter from "./MainFooter";
import Header from "../Header/Header";
import classes from "./DetailPage.module.css";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import { useDispatch } from "react-redux";

const DetailPage = () => {
  const navigate = useNavigate();
  const {id}= useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const location = useLocation();
  const { title, content } = location.state || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className={`${classes.DetailPageNavigation}`}>
        <MainHeader />
      </div>

      <div className={`${classes.DetailPage}`}>
        <Header heading={title} onClick= {handleHeaderClick} />
        <div className={classes.DetailPageContent}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default DetailPage;
