import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    clientRegId: "",
    verfiyToken: "",
    Phone: "",
    Profile: {},
    ProfileFormat: {
      Email: "",
      Username: "",
    },
    Customer:{      
    },
    fromPage:{
    }
  },
  reducers: {
    setTokenHandler(state, action) {
      state.verfiyToken = action.payload;
    },
    setClientRegIdHandler(state, action) {
      state.clientRegId = action.payload;
    },
    setPhoneHandler(state, action) {
      state.Phone = action.payload;
    },

    setProfileFieldHandler(state, action) {
      var name = action.payload.name;
      var value = action.payload.value;
      state.Profile[name] = value     
    },
    setProfileHandler(state, action) {
      state.Profile = action.payload;
    },
    setProfileFormatter(state, action) {
        state.Profile = { ...state.ProfileFormat }
    },
    setFromPage(state, action) {
        state.fromPage = action.payload
    },
    setCustomerHandler(state,action){
      state.Customer = action.payload
    }
  },
});

export const authActions = authSlice.actions;

export default authSlice;

