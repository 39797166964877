import { productActions } from "./ProductSlice";
import {
  CustomerInstance,
  NodeInstance,
  ProductInstance,
} from "../../Router/AxiosInstance";
import { cartActions } from "../Cart/CartSlice";
import { orderActions } from "../Order/OrderSlice";



export const getProductHandler = (id, query) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };
    // console.log("Verify1", verfiyToken);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/product/getWithAtlas?page=${query.page}&limit=${query.limit}&brand=${query.Brand}&categories=${query.categories}&minMRP=${query.minMRP}&maxMRP=${query.maxMRP}&sortBy=${query.sortBy}&search=${query.search}&locationId=&minStock=0&maxStock=&IsAllProduct=${query.IsAllProduct}&ClientRegID=${id}`,
      headers: {
        // Authorization: "Bearer " + verfiyToken,
        "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,
      },
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(response.data.data.products)
          result.data = response.data.data;
          dispatch(productActions.setProductListHandler(response.data.data));
        } else {
          dispatch(productActions.setProductListHandler([]));
        }
      })
      .catch(function (error) {
        console.log(error);
        result.status = "error";

        dispatch(productActions.setProductListHandler([]));
        dispatch(productActions.setPromoProductHandler([]));
      });
    return result;
  };
};

// export const searchProductHandler = (verfiyToken, query) => {
//   return async (dispatch) => {
//     let result = {
//       status: "",
//       msg: "",
//       data: [],
//     };
//     // console.log("Verify1", verfiyToken);
//     let config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: `/customer/product/SearchProduct?page=${query.page}&limit=10&search=${query.search}`,
//       headers: {
//         Authorization: "Bearer " + verfiyToken,
//       },
//     };

//     await ProductInstance(config)
//       .then(function (response) {
//         if (response.data.status === "success") {
//           result.status = "success";

//           result.data = response.data.data;
//           dispatch(
//             productActions.setProductListHandler(response.data.data)
//           );

//         } else {
//           dispatch(productActions.setProductListHandler([]));
//         }

//       })
//       .catch(function (error) {
//         console.log(error);
//         result.status = "error";
//         dispatch(
//           productActions.setProductListHandler()
//         );

//       });
//     return result;
//   };
// };

export const getAllPromotionHandler = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/product/getPromotionLocationwise?StoreId=${id}`,

      headers: {
       // Authorization: "Bearer " + verfiyToken,
       "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,


      },
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getProductByIdHandler = (storeId, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: `/api/admin/product/${id}`,
      url: `/customer/product/getSingleProductDetail/${id}?StoreId=${storeId}`,

      headers: {
        //Authorization: "Bearer " + verfiyToken,
        "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,
      },
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log("product....", response.data.data);
          dispatch(productActions.setProductHandler(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getVariantIdHandler = (StoreId,locId, id) => {
  return async () => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/variant/getVariantdetail?StoreId=${StoreId}&LocationId=${locId}&product_PKID=${id}`,

      headers: {
        // Authorization: "Bearer " + verfiyToken,
        "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,

      },
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data
          console.log("product....", response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};


export const getInvoicePromoHandler = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,

      url: `/customer/promotion/getAllInvoicePromotion?StoreId=${id}`,

      headers: {
        // Authorization: "Bearer " + verfiyToken,
        "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,

      },
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          dispatch(
            productActions.setPromoInvoiceHandler(
              response.data.data[0]?.invoice
            )
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

// export const getPromoProductHandler = (verfiyToken) => {
//   return async (dispatch) => {
//     let result = {
//       status: "",
//       msg: "",
//       data:[]
//     };

//     let config = {
//       method: "get",
//       maxBodyLength: Infinity,

//       url: `/customer/product/promotionviseproduct `,

//       headers: {
//         Authorization: "Bearer " + verfiyToken,
//       },
//     };

//     await ProductInstance(config)
//       .then(function (response) {
//         if (response.data.status === "success") {
//           result.status = "success";
//          result.data = response.data.data
//         dispatch(productActions.setPromoProductHandler(response.data.data))
//         }
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//     return result;
//   };
// };

export const getWishlistHandler = (authToken, data, num) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/getWishlistbyUser_Number/${num}`,

      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data.data.Wishlist));
          dispatch(productActions.setWishlistHandler(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getReviewHandler = (storeId, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/reviews/getProductReviews/${id}?StoreId=${storeId}`,

      headers: {
  //      Authorization: "Bearer " + verfiyToken,
    "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,

      },
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";

          dispatch(productActions.setReviewHandler(response.data.data[0]));
          dispatch(productActions.setAllReviewHandler(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const PostWishlistHandler = (verfiyToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/addToWishlist`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const PostReviewHandler = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/reviews?StoreId=${id}`,

      headers: {
        // Authorization: "Bearer " + verfiyToken,
        "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,

      },
      data: data,
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const PostLikeHandler = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/reviews/like-dislike?StoreId=${id}`,

      headers: {
   //     Authorization: "Bearer " + verfiyToken,
   "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,

      },
      data: data,
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const PromotionHandling = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/promotion/applyPromotionCoupon?StoreId=${id}`,

      headers: {
       // Authorization: "Bearer " + verfiyToken,
       "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,

      },
      data: data,
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.success === true) {
          result.status = "success";
          result.data = response.data.data;

          dispatch(orderActions?.setPromoDetail(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const RemoveWishlistHandler = (verfiyToken, num, id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/customer/DeleteWishlist/${num}/${id}`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};
