import React from 'react';
import classes from "./BackToTop.module.css";

const BackToTop = ({handleScrollUp}) => {
  

  return (
    <div 
      className={classes.backToTopContainer} 
      onClick={handleScrollUp}
    >
      <button className={classes.goTop}>
        <i className="bi bi-arrow-up" /> 
      </button>
    </div>
  );
};

export default BackToTop;
